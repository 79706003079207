.box {
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    margin-bottom: 10px;
    transition: box-shadow .1s linear;
    background: #fff;
    font: 400 15px/20px Source Sans Pro,sans-serif;
    color: var(--primary-color);
    z-index: 800;
}

.boxWithoutCollapse {
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    margin-bottom: 24px;
    transition: box-shadow .1s linear;
    background: #fff;
    font: 400 15px/20px Source Sans Pro,sans-serif;
    color: var(--primary-color);
    padding: 20px;
    z-index: 800;
}

.box .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.box .titleBox {
    font-weight: 600;
    font-size: 16px;
    color: #4c575e;
}

.box .collapseIconBox {
    transition: transform 0.3s ease;
}

.box .contentBox {
    max-height: 2000px;
    width: 100%;
    /* overflow: hidden; */
    transition: max-height 0.3s ease, padding 0.3s ease;
}
.box .spacingToContentBox {
    padding: 20px;
}

.box .contentBox.collapsed {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
}