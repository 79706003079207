.containerDetailsEnvelope .fadeElementDetailsEnvelope {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.containerDetailsEnvelope .fadeInDetailsEnvelope {
  opacity: 1;
}

.containerDetailsEnvelope .fadeOutDetailsEnvelope {
  opacity: 0;
}

.containerDetailsEnvelope .skeletonLoaderDetailsEnvelope {
    opacity: 0.6;
    pointer-events: none;
}

.containerDetailsEnvelope .skeletonBoxDetailsEnvelope {
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite linear;
    border-radius: 4px;
}

@keyframes skeleton-loading {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}

/* .containerNoAllowedEnvelopeDetailsEnvelope {
    padding: 18px;
    min-height: 118px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
} */

.containerModalValidationCodeDetialsEnvelope .modernFieldInputGeneral .fieldInputGeneral {
    width: 100% !important;
    box-shadow: inset 0 1px 2px #eee !important;
    box-sizing: border-box !important;
    display: block !important;
    font: inherit !important;
    color: #26292c !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    background: none !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}

.containerNoAllowedEnvelopeDetailsEnvelope .headerNoAllowedEnvelopeDetailsEnvelope {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.containerNoAllowedEnvelopeDetailsEnvelope .alertHeadingDetailsEnvelope i {
    margin-right: 15px;
}

.containerNoAllowedEnvelopeDetailsEnvelope .headerNoAllowedEnvelopeDetailsEnvelope i {
    background-color: var(--color-warning);
    color: #fff;
    border-radius: 50%;
    height: 45px;
    margin-right: 15px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerRelationshipsDetailsEnvelope  .buttonBondsDetailsEnvelope {
    margin-right: 6px;
    height: 46px;
    font-size: 15px;
    font-weight: 400;
    width: 180px;
    border-radius: 5px;
}

.containerRelationshipsDetailsEnvelope  .witnessTableDetailsEnvelope {
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

.containerRelationshipsDetailsEnvelope .table td {
    vertical-align: middle;
}

.containerRelationshipsDetailsEnvelope  .witnessTableHeaderDetailsEnvelope {
    background-color: #f0f0f0;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #ddd;
}

.containerRelationshipsDetailsEnvelope .buttonValidationWitnessDetailsEnvelope{
    background: #fff;
    border: 1px solid #747678;
    color: #747678;
    min-width: 30px;
    max-width: 200px;
    display: flex;
    border-radius: 5px;
    font-size: 12px;
    justify-content: space-around;
    flex-direction: row-reverse;
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope {
    background: #f9f9f9;
    border: 2px solid #e0e0e0;
    margin: 0 !important;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope .witnessTableNameWizard {
    flex: 2;
    text-align: left;
    font-weight: bold;
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope .witnessTableDetailsWizard {
    display: flex;
    justify-content: space-between;
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope .witnessTableDetailsWizard .witnessTableStatusWizard {
    font-weight: bold;
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope .witnessTableDetailsWizard .witnessTableTimestampWizard {
    font-size: 0.9em;
    color: #555;
}

.containerRelationshipsDetailsEnvelope .buttonRemoveWitnessDetailsEnvelope{
    background: var(--color-error);
    min-width: 30px;
    max-width: 200px;
    border-radius: 5px;
    font-size: 12px;
}

.containerRelationshipsDetailsEnvelope .accordion-button {
    align-items: center;
    background-color: #fff;
    border: 1px solid #c7c7c7 !important;
    border-radius: 5px;
    display: flex;
    font-size: 1rem;
    overflow-anchor: none;
    padding: 1rem 1.25rem;
    position: relative;
    text-align: left;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    width: 100%;
}

.containerRelationshipsDetailsEnvelope .accordion-button:after {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 16px;
    width: 16px;
    height: 16px;
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
}

.containerRelationshipsDetailsEnvelope .accordion-button:not(.collapsed):after {
    transform: rotate(180deg);
}

.containerRelationshipsDetailsEnvelope .buttonValidationWitnessWizard {
    background: #fff;
    border: 1px solid #747678;
    color: #747678;
    width: 30px;
    border-radius: 5px;
    font-size: 12px;
}


.containerRelationshipsDetailsEnvelope .table {
    border-radius: 5px;
    overflow: hidden;
    border-collapse: collapse;
    background: #f8f9fa;
}

.containerRelationshipsDetailsEnvelope .table thead {
    background: var(--color-focus);
    color: white;
}

.containerRelationshipsDetailsEnvelope .table th, .containerRelationshipsDetailsEnvelope .table td {
    padding: 12px;
    text-align: center;
}

.containerRelationshipsDetailsEnvelope .table tbody tr {
    transition: background 0.3s ease;
}

.containerRelationshipsDetailsEnvelope .table tbody tr:hover {
    background: #e9f5ff;
}

.containerRelationshipsDetailsEnvelope .table a {
    color: var(--color-focus);
    font-weight: 500;
    text-decoration: none;
}

.containerRelationshipsDetailsEnvelope .table a:hover {
    text-decoration: underline;
}

.containerDetailsEnvelope .buttonDetailsEnvelope, .containerModalValidationCodeDetialsEnvelope .buttonDetailsEnvelope {
    margin-right: 6px;
    height: 46px;
    font-weight: 400;
    font-size: 15px;
    width: 180px;
    border-radius: 5px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope p {
   margin: 0;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .noticeDetailsEnvelope {
   margin-bottom: 10px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .listMessagesDetailsEnvelope {
    display: flex;
    gap: 15px; /* Espaço entre os itens */
    padding-left: 0;
    margin: 0;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .listMessagesDetailsEnvelope li{
    margin-left: 20px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .alertDetailsEnvelope {
    display: flex;
    margin-bottom: 8px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .alertDetailsEnvelope .alertHeadingDetailsEnvelope i {
    margin-right: 5px;
    font-size: 18px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .alertDetailsEnvelope .alertHeadingDetailsEnvelope {
    margin: 0;
    margin-right: 5px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .alertDetailsEnvelope .alertAllowHeadingDetailsEnvelope {
    margin-right: 10px !important;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .alertDetailsEnvelope .alertCategoryDetailsEnvelope {
    font-size: 12px;
    font-style: italic;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    min-height: 118px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope.marginContract2DetailsEnvelope {
    margin-top: 40px;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope.containerEnvelopeCategory1DetailsEnvelope {
    border-left: 5px solid #005aff;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope.containerEnvelopeCategory2DetailsEnvelope {
    border-left: 5px solid #1dd99c;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope{
    display: flex;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .buttonDetailsEnvelope.buttonAttachmentDetailsEnvelope {
    background-color: var(--color-focus);
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .buttonDetailsEnvelope.buttonSignaturesDetailsEnvelope {
    background-color: var(--color-warning);
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .buttonDetailsEnvelope.disableButtonCreateEnvelope {
    background-color: var(--color-inactive);
    cursor: no-drop;
    min-width: fit-content;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .buttonDetailsEnvelope.buttonDeleteDetailsEnvelope {
    background-color: var(--color-error);
    min-width: 30px;
    border-radius: 5px;
    font-size: 12px;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .timerDetailsEnvelope {
    border-radius: 5px;
    margin-right: 6px;
    width: 100px;
    color: #fff;
    height: 45px;
    background: #3b2070;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .timerDetailsEnvelope i {
    margin-right: 5px;
}
.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .timerDetailsEnvelope span {
    font-weight: 400;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope b {
    font-weight: 600;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope h4 {
    margin: 0;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope .labelPrincipalServicesDetailsEnvelope {
    color: var(--color-success);
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope .labelSecundaryServicesDetailsEnvelope {
    color: var(--color-error);
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope .dateDetailsEnvelope {
    font-size: 14px !important;
    font-weight: 400;
    margin: 0;
    font-style: italic;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope .signCompletedDetailsEnvelope {
    font-size: 14px !important;
    font-weight: 400;
    margin: 0;
    color: var(--color-success);
    font-style: italic;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope .unsignCompletedDetailsEnvelope {
    font-size: 14px !important;
    font-weight: 400;
    margin: 0;
    color: var(--color-error);
    font-style: italic;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope.borderContainerAttachments1DetailsEnvelope {
    border-left: 5px solid #005aff;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope.borderContainerAttachments2DetailsEnvelope {
    border-left: 5px solid #1dd99c;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope h4 {
    display: flex;
    align-items: center;
    color: var(--color-focus);
    margin-bottom: 20px;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .headerTableAttachments {
    display: flex;
    justify-content: space-between;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .headerTableAttachments .buttonRefreshAttachmentsDetailsEnvelope {
    height: 32px;
    width: 20px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope h4 i {
    margin-right: 5px;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .fa-envelope-open-text {
    font-size: 1.5rem;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table {
    border-radius: 5px;
    overflow: hidden;
    border-collapse: collapse;
    background: #f8f9fa;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table thead {
    background: var(--color-focus);
    color: white;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table th, .containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table td {
    padding: 12px;
    text-align: center;
    min-width: 165px;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table tbody tr {
    transition: background 0.3s ease;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table tbody tr:hover {
    background: #e9f5ff;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table a {
    color: var(--color-focus);
    font-weight: 500;
    text-decoration: none;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table a:hover {
    text-decoration: underline;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .boxButtonSendAttachmentEnvelopeDetailsEnvelope {
    height: 73px;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .buttonSendAttachmentEnvelopeDetailsEnvelope {
    width: 100%;
    background-color: var(--color-focus);
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope {
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .buttonSignaturesLinkDetailsEnvelope {
    background: var(--color-focus);
    color: var(--white-light);
    padding: 5px;
    width: 4em;
    border: 0;
    text-align: center;
    border-radius: 5px;
}
.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .spanInfoSignedDetailsEnvelope {
    padding: 5px;
    border-radius: 5px;
    display: block;
    width: 120px;
    background: #ccc;
    font-weight: 400;
    color: #fff;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .buttonSignaturesLinkDetailsEnvelope.buttonWhatsappSignaturesDetailsEnvelope {
    background: #28a745;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .spanInfoSignedDetailsEnvelope.classStatusAssNoSign {
    background: #dc3545;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .spanInfoSignedDetailsEnvelope.classStatusAssLinkOpened {
    background: #ef9602;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .spanInfoSignedDetailsEnvelope.classStatusAssSign {
    background: #28a745;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope h4 {
    display: flex;
    align-items: center;
    color: var(--color-focus);
    margin-bottom: 20px;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope h4 i {
    margin-right: 5px;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .fa-file-signature {
    font-size: 1.5rem;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table {
    border-radius: 5px;
    overflow: hidden;
    border-collapse: collapse;
    background: #f8f9fa;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table thead {
    background: var(--color-focus);
    color: white;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table th, .containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table td {
    padding: 12px;
    text-align: center;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .statusSignDetailsEnvelope{
    display: flex;
    justify-content: center;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table tbody tr {
    transition: background 0.3s ease;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table tbody tr:hover {
    background: #e9f5ff;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table a {
    color: var(--color-focus);
    font-weight: 500;
    text-decoration: none;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table a:hover {
    text-decoration: underline;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .boxButtonSendAttachmentEnvelopeDetailsEnvelope {
    height: 73px;
}
