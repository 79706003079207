@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap);
:root {

    /* start colors */
    /* start colors */
    /* start colors */
    --primary-color: #26292c;
    
    --dark-dark: #353535;
    --dark-light: #949494;
    --dark-dark-two: #202225;
    --dark-dark-three: #696969;
    
    --white-light: #fff;
    --white-dark: #f7f7f7;
    --white-dark-two: #dcdddd;
    --white-dark-three: #f8f8f8;
    
    --color-error: #dc3545;
    --color-success: #28a745;
    --color-warning: #ef9602;
    --color-information: #007bff;
    --color-focus: #317ae2;
    --color-inactive: #787878;
    --color-purple: purple;
    --color-orange: #ff7400;
    --color-limon: #a6b916;
    --color-strawberry: #ff8f8f;
    --color-banana: #ffce00;
    --color-watermelon: #ff6666;
    /* ends colors */
    /* ends colors */
    /* ends colors */
    
    /* start dimensions */
    /* start dimensions */
    /* start dimensions */
    --whatsapp-width-chatlist: 430px; /*default is 430px*/
    --whatsapp-height-chatlist-header: 59px; /*default is 59px*/
    --whatsapp-width-point-audio-recording: 4px; /*default is 4px*/
    --whatsapp-margin-point-audio-recording: 2px; /*default is 2px*/
    --whatsapp-width-more-details-profile: 450px;
    --whatsapp-width-point-audio-recording-triple: calc(var(--whatsapp-width-point-audio-recording) * 3 + var(--whatsapp-margin-point-audio-recording)); /*DANGER: DON'T CHANGE NOTHING HERE!!!*/
    /* ends dimensions */
    /* ends dimensions */
    /* ends dimensions */

}

*{
    padding: 0;
    margin: 0;
    scrollbar-width: none;
}

body {  
    background-color: #f7f7f7;  
    background-color: var(--white-dark);
    font-family: 'Source Sans Pro', sans-serif;
}

::-webkit-scrollbar {
    display: none;
}

button:focus-visible, button:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

select { /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}
.menusCRM {
    padding: 0;
    margin: 0;
    background-color: #404346;
    width: 56px;
    height: 100%;
}

.menusCRM .anchorMenu {
    text-decoration: none;    
    position: relative;
    display: flex;
    align-items: center;
    vertical-align: middle;
    text-align: left;
    cursor: pointer;
}

.menusCRM .anchorMenu .amountNotificationsMenusCRM {
    position: absolute;
    right: 0;
    background-color: var(--color-error);
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    color: var(--white-dark);
    border-radius: 50%;
    font-size: 14px;
}

.menusCRM .anchorMenu .squareMenu {
    width: 56px;
    height: 56px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    color: var(--white-dark-two);
    font-size: 24px;
}

.menusCRM .anchorMenu .squareMenu.brandMenu {
    fill: red;
    color: red;
}

.menusCRM .anchorMenu .squareMenu:hover {
    background-color: rgba(255, 255, 255, 0.16);
    transition: background-color 0.2s;
}

.menusCRM .anchorMenu .squareMenu.active {
    background-color: var(--dark-dark-two);
}

.menusCRM .anchorMenu .flagMenu.active {
    content: "";
    width: 4px;
    border-radius: 0px 4px 4px 0px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0px;
}

.menusCRM .anchorMenu .moreInformationMenu {
    position: absolute;
    padding: 1px 8px;
    background-color: var(--dark-dark-two);
    color: white;
    font-size: 14px;
    position: absolute;
    border-radius: 4px;
    margin-left: 58px;
    align-items: center;
    vertical-align: middle;
    display: none;
    z-index: 900;
    width: -webkit-max-content;
    width: max-content;
}

.menusCRM .anchorMenu:hover .moreInformationMenu {
    display: flex;
}

.menusCRM .anchorMenu .moreInformationMenu .numberInformationMenu{
    margin-left: 8px;
    display: inline-flex;
    padding: 0px 4px;
    height: 16px;
    min-width: 16px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    color: #ffffffa3;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
}

.menusCRM .dividerMenu {
    margin: 8px 12px;
    border: 1px solid rgba(38, 41, 44, 0.64);
    border-radius: 1px;
}

.menusCRM .btnLogout {
    position: absolute;
    bottom: 0;
    background-color: #404346;
}
.screensCRM {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
}
.leads {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.leads .warningLeads {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.leads .containerNavigationHeaderLeads {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads:hover {
    background-color: var(--white-dark);
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.leads .containerNavigationHeaderLeads .buttonPlusNavigationHeaderLeads {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.leads .containerNavigationHeaderLeads .buttonTrashNavigationHeaderLeads {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .iconFilterFontsHeaderLeads {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .selectFontHeaderLeads {
    cursor: pointer;
    width: 100%;
    border: none;
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .selectFontHeaderLeads .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .selectFontHeaderLeads .fieldSelectMulti {
    border: none;
}

.leads .containerListHeaderLeads {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderLeads */
}
.header {  
    background-color: var(--white-light);
    z-index: 20;
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 1px 1px, rgb(0 0 0 / 6%) 0px 1px 2px -2px, rgb(0 0 0 / 7%) 0px 1px 3px 0px;
    color: var(--primary-color);
    min-height: 56px;
}

.header .colHeader{  
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.header .colHeader .title{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-align: left;
    font-weight: 600;
    padding: 16px 0px 16px 20px;
}

.header .colHeader .buttonMoreHeader {
    position: relative;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #08a742;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.1s ease 0s;
    color: var(--white-light);
}

.header .colHeader .buttonMoreHeader:hover {
    background-color: #08a742e0;
    color: var(--white-light);
}

.header .colHeader .buttonProfileHeader {
    border: 4px solid transparent;
    margin-right: 10px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
    color: #1372f4;
    font-size: 32px;
}

.header .colHeader .buttonProfileHeader:hover {
    border: 4px solid #e5e5e5;    
    transition: border .2s;
}

.header .colHeader .nameProfileHeader {
    color: var(--primary-color);
    font-weight: 600;
    margin-right: 20px;
}

.header .colHeader .areaAvatarHeader {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    border: none;
    margin-right: 10px;
    cursor: pointer;
}

.header .colHeader .areaAvatarHeader .avatarHeader {
    height: 100%;
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    justify-content: flex-start;
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader .icon{
    margin-right: 15px;
    margin-left: 10px;
    font-size: 18px;
    color: var(--dark-dark-three);
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader:hover .icon{
    color: var(--primary-color);
    transition: color  1s;
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader:hover {
    background-color: #f2f2f2;
    transition:  background-color 1s;
}

.header .colHeader .dropProfileHeader {
    z-index: 900;
}

.header .colHeader .dropProfileHeader .anchorProfileHeader {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    justify-content: flex-start;
    margin: 0;
}

.header .colHeader .dropProfileHeader .anchorProfileHeader .icon {
    margin-right: 15px;
    margin-left: 20px;
    font-size: 18px;
    color: var(--dark-dark-three);
}

.header .colHeader .dropProfileHeader .anchorProfileHeader:hover .icon {
    color: var(--primary-color);
    transition: color  1s;
}

.header .colHeader .dropProfileHeader .anchorProfileHeader:hover {
    background-color: #f2f2f2;
    transition:  background-color 1s;   
}

.header .colHeader .dropProfileHeader .titleProfileHeader {
    color: var(--dark-dark-three);
    margin-top: 14px;
    margin-bottom: 8px;
    margin-left: 30px;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
}

.header .colHeader .dropProfileHeader .divider {
    height: 1px;
    width: 100%;
    content: " ";
    display: block;
    background-color: var(--white-dark-two);
    margin-top: 10px;
    margin-bottom: 10px;
}

.header .colHeader .anchorProfileSubmenuHeader {
    position: relative;
}

.header .colHeader .dropProfileHeader .dropProfileSubmenuHeader {
    left: 20px;
    top: 40px;
    position: absolute;
}

.header .colHeader .dropProfileSubmenuHeader .buttonProfileSubmenuHeader {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    justify-content: flex-start;
    margin: 0;
    padding-left: 0px;
}

.header .colHeader .dropProfileSubmenuHeader .buttonProfileSubmenuHeader:hover {
    background-color: #f2f2f2;
    transition:  background-color 1s;   
}
.groupInputGeneral {  
    width: 100%;
}

.groupInputGeneral .labelInputGeneral {  
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.groupInputGeneral .inputInputGeneral {
    position: relative;
    display: flex;
    align-items: center;
}

.groupInputGeneral .inputInputGeneral .buttonClearInputGeneral {
    background-color: transparent;
    width: inherit;
    height: inherit;
    color: #000;
    font-size: 15px;
    position: absolute;
    right: 10px;
}
.groupInputGeneral .inputInputGeneral .fieldInputGeneral {  
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    box-sizing: border-box;
    display: block;
    padding: 4px 32px 4px 8px;
    height: 32px;
    font: inherit;
    color: #26292c;
    transition: padding 75ms ease-in-out;
}

.groupInputGeneral .inputInputGeneral .fieldInputGeneral.error { 
    border: 1px solid var(--color-error);
}

.groupInputGeneral .inputInputGeneral .fieldInputGeneral:focus {
    border: 1px solid #317ae2;
    box-shadow: inset 0 1px 2px rgb(49 122 226 / 8%);
    outline: none;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral {
    width: 32px;
    height: 32px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 6px;
    bottom: 0;
    right: 0;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .buttonInformationInputGeneral { 
    background-color: transparent;
    color: var(--color-warning);
    height: 100%;
    z-index: 600;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral { 
    background-color: var(--white-light);
    min-width: 280px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 34px;
    z-index: 600;
    flex-direction: column;
    display: flex;
    box-shadow: 0px 1px 5px var(--dark-light);
    border: 1px solid #cbcccd;
    border-radius: 2px;
    overflow: scroll;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .headerContentWarningInputGeneral { 
    display: flex;
    padding: 12px 16px;
    height: 48px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .headerContentWarningInputGeneral .title { 
    font-size: 18px;
    color: #26292c;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .headerContentWarningInputGeneral .buttonCloseContentWarningInputGeneal { 
    background-color: transparent;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral {
    height: 100%;
    flex-direction: column;
    display: flex;
    padding: 10px;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice {
    border-bottom: 1px solid var(--white-dark-two);
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    vertical-align: middle;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice:hover {
    background-color: var(--white-dark);
    font-weight: 600;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice .label {
    font-weight: 300;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice .input {
    color: var(--color-focus);
}

.buttonIcon {
    width: 40px;
    height: 40px;
    outline: none;
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    text-align: center;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: var(--white-light);
    font-size: 18px;
}

.buttonIcon:hover { 
    color: var(--primary-color);
    transition: color .3s;
    background-color: var(--dark-light);
    transition: background-color .3s;
}

.ownerForm {
    width: 100%;
}

.ownerForm .lineOwnerForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.ownerForm .lineOwnerForm .columnOwnerForm {
    height: 100%;
}

.ownerForm .lineOwnerForm .columnOwnerForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.ownerForm .lineFooterOwnerForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.optionSelect {
    background-color: var(--white-light);
    color: var(--primary-color);
    text-align: left;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
    min-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.optionSelect.active {
    font-weight: 600;
}

.optionSelect:hover {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.optionSelect .labelOptionSelect {
}

.optionSelect .labelOptionSelect.lead {
    color: var(--white-light);
    font-size: 11px;
    font-weight: 600;
    padding: 1px 6px;
    border-radius: 2px;

}

.optionSelect .labelOptionSelect.error {
    background-color: var(--color-error);
}

.optionSelect .labelOptionSelect.warning {
    background-color: var(--color-warning);
    color: #000;
}

.optionSelect .labelOptionSelect.focus {
    background-color: var(--color-focus);
}

.optionSelect .iconLabelSelectedOptionSelect {
    color: var(--color-focus);

}

.optionSelect:hover .iconLabelSelectedOptionSelect {
    color: var(--white-light);
}
.select { 
    position: relative;
    min-width: 100px;
}

.select .labelSelect { 
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.select .disabledSelect { 
    pointer-events: none; 
}

.select .fieldSelect .lead {
    color: var(--white-light);
    font-size: 11px;
    font-weight: 600;
    padding: 1px 6px;
    border-radius: 2px;

}

.select .fieldSelect .lead.error {
    background-color: var(--color-error);
}

.select .fieldSelect .lead.warning {
    background-color: var(--color-warning);
    color: #000;
}

.select .fieldSelect .lead.focus {
    background-color: var(--color-focus);
}

.select .fieldSelect {
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    padding: 4px 8px;
    height: 32px;
    color: #26292c;
    transition: padding 75ms ease-in-out;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
}
.select .fieldSelect div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select .fieldSelect.error { 
    border: 1px solid var(--color-error);
}


.select .fieldSelect .iconShowSelect{
    position: absolute;
    font-size: 14px;
    right: 10px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    margin: 0;
}

.select .optionsSelect {
    z-index: 700;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    width: 100%;
    min-height: 100px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 16%), 0 3px 4px 0 rgb(0 0 0 / 5%), 0 3px 3px -2px rgb(0 0 0 / 6%), 0 1px 8px 0 rgb(0 0 0 / 10%);
    overflow: scroll;
    max-height: 250px;
}
.button {
    box-shadow: none;
    border: none;
    outline: none;
    background-color: green;
    padding: 20px;
    color: #fff;
    background: #08A742;
    font-weight: 700;
    width: 100%;
    padding: 11px 20px;
    text-align: center;
    outline: none;
    white-space: nowrap;
    font-size: 24px;
    line-height: 1.4em;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.button:hover {
    color: #fff;
    background: #26B259;
    transition: background-color 0.3s;
}
.selectMulti { 
    position: relative;
    min-width: 100px;
}

.selectMulti .labelSelectMulti { 
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.selectMulti .fieldSelectMulti .lead {
    color: var(--white-light);
    font-size: 11px;
    font-weight: 600;
    padding: 1px 6px;
    border-radius: 2px;

}

.selectMulti .fieldSelectMulti .lead.error {
    background-color: var(--color-error);
}

.selectMulti .fieldSelectMulti .lead.warning {
    background-color: var(--color-warning);
    color: #000;
}

.selectMulti .fieldSelectMulti .lead.focus {
    background-color: var(--color-focus);
}

.selectMulti .fieldSelectMulti {
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    padding: 4px 8px;
    height: 32px;
    color: #26292c;
    transition: padding 75ms ease-in-out;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.selectMulti .fieldSelectMulti div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectMulti .fieldSelectMulti .iconShowSelectMulti{
    position: absolute;
    font-size: 14px;
    right: 10px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    margin: 0;
}

.selectMulti .optionsSelectMulti {
    z-index: 700;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    width: 100%;
    min-height: 100px;
    max-height: 200px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: scroll;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 16%), 0 3px 4px 0 rgb(0 0 0 / 5%), 0 3px 3px -2px rgb(0 0 0 / 6%), 0 1px 8px 0 rgb(0 0 0 / 10%);

}

.selectMulti .optionsSelectMulti .active {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.selectMulti .optionsSelectMulti .iconLabelSelectedOptionSelect {
    color: var(--white-light);    
}
.personForm {
    width: 100%;
}

.personForm .linePersonForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.personForm .linePersonForm .columnPersonForm {
    height: 100%;
}

.personForm .linePersonForm .columnPersonForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.personForm .lineFooterPersonForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.personForm .lineFooterPersonForm .buttonPersonForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.personForm .lineFooterPersonForm .buttonPersonForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.personForm .lineFooterPersonForm .buttonPersonForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.personForm .lineFooterPersonForm .buttonPersonForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.anchor {
    padding: 10px;
    margin: 5px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none!important;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
.businessForm {
    width: 100%;
}

.businessForm .lineBusinessForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.businessForm .lineBusinessForm .columnBusinessForm {
    height: 100%;
}

.businessForm .lineBusinessForm .columnBusinessForm .progressLineBusinessForm .stageContentProgressLine {
    font-size: 0;
    color: transparent;
}

.businessForm .lineBusinessForm .columnBusinessForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.businessForm .lineBusinessForm .columnBusinessForm .selectStampsBusinessForm {
}

.businessForm .lineBusinessForm .columnBusinessForm .selectStampsBusinessForm .labelOptionSelect {
}

.businessForm .lineFooterBusinessForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.businessForm .lineFooterBusinessForm .buttonBusinessForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.businessForm .lineFooterBusinessForm .buttonBusinessForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.businessForm .lineFooterBusinessForm .buttonBusinessForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.businessForm .lineFooterBusinessForm .buttonBusinessForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.businessForm .lineBusinessForm .columnBusinessForm .stepBusinessForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}
.progressLine {
    width: 100%;
}

.progressLine .lineProgressLine {
    height: 30px; /*DANGER: must be the same height*/
    border-radius: 4px;
}

.progressLine .lineProgressLine .stageContentProgressLine {
    width: 20%;
    height: 100%;
    position: relative;
    background-color: #dedede;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    color: var(--white-light);
}

.progressLine .lineProgressLine .stageContentProgressLine .infoStageContentProgressLine {
    display: none;
}

.progressLine .lineProgressLine .stageContentProgressLine:hover .infoStageContentProgressLine {
    background-color: black;
    color: white;
    padding: 2px 4px;
    border-radius: 5px;
    position: absolute;
    bottom: -28px;
    min-width: 120px;
    z-index: 600;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: block;
}

.progressLine .lineProgressLine .stageContentProgressLine.active {
    background: #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine .stageArrowProgressLine {
    width: 6px;
    height: 30px; /*DANGER: must be the same height*/
    position: absolute;
    top: 0px;
    left: 0;
    overflow: hidden;
    background-color: #dedede;
    /* color: var(--white-light);
    text-align: center; */
}

.progressLine .lineProgressLine .stageContentProgressLine .stageArrowProgressLine.active {
    background: #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine .stageArrowProgressLine:after {
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    background-color: #dedede;
    width: 24px;
    height: 30px; /*DANGER: must be the same height*/
    position: absolute;
    right: -6px;
    top: 2px;
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    -webkit-transform: scaleX(.3) rotate(45deg);
            transform: scaleX(.3) rotate(45deg);
    content: " ";
    cursor: pointer;
}

.progressLine .lineProgressLine .stageContentProgressLine.first .stageArrowProgressLine:after {
    border-top: 4px solid #08a742;
    border-right: 4px solid #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine.active .stageArrowProgressLine.active:after {
    background: #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine.last .stageArrowProgressLine:after {
    background: #08a742;
}

.progressLine .lineDetailsProgressLine {
    display: inline-block;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.progressLine .lineDetailsProgressLine span {
    color: var(--primary0color);
    font-size: 12px;
    font-weight: 300;
}

.progressLine .lineDetailsProgressLine .iconProgressLine {
    font-size: 12px;
    color: var(--dark-light);
    margin: 0 5px;
}
.activityForm { 
    padding: 0;
}
.activityForm .button { 
    border-radius: 2px;
}

.activityForm .activityFormLoading {
    transition: all ease-in .5s;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.activityForm .bodyActivityForm { 
    padding: 16px 36px;
}

.activityForm .bodyActivityForm .labelSuccessServiceActivityForm {
    color: var(--color-success);
    font-weight: 400;
    display: inline-block;
    width: 100%;
    font-size: 20px;
}

.activityForm .bodyActivityForm .mandatoryInformationActivityForm {
    font-size: 12px;
    color: #747678;
}

.activityForm .bodyActivityForm .labelErrorServiceActivityForm {
    color: var(--color-error);
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    display: inline-block;
}

.activityForm .bodyActivityForm .labeDetailsServiceActivityForm {
    color: var(--color-warning);
    font-weight: 400;
    font-size: 14px;
    
}

.activityForm .bodyActivityForm .valueAuthorizationSuccessAcitivityForm input {
    color: #000;
}

.activityForm .bodyActivityForm .valueAuthorizationWarningAcitivityForm input {
    background-color: #ffc1075e;
}

.activityForm .bodyActivityForm .valueAuthorizationErrorAcitivityForm input {
    background-color: #dc35465b;
}

.activityForm .bodyActivityForm .subtitleGeneralActivityForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.activityForm .bodyActivityForm .subtitleSuccessActivityForm,
.activityForm .bodyActivityForm .subtitleWarningActivityForm,
.activityForm .bodyActivityForm .subtitleErrorActivityForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    width: 100%;
}

.activityForm .bodyActivityForm .subtitleSuccessActivityForm:before,
.activityForm .bodyActivityForm .subtitleWarningActivityForm:before,
.activityForm .bodyActivityForm .subtitleErrorActivityForm:before {
    display: block;
    content: " ";
    width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 1px;
    margin-right: 5px;
}

.activityForm .bodyActivityForm .subtitleSuccessActivityForm:before {
    background-color: #28a74678;
}

.activityForm .bodyActivityForm .subtitleWarningActivityForm:before {
    background-color: #ffc1075e;
}

.activityForm .bodyActivityForm .subtitleErrorActivityForm:before {
    background-color: #dc35465b;
}

.activityForm .bodyActivityForm .lineActivityForm {
    margin-top: 10px;
    margin-bottom: 10px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .table td{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .containerServiceInfoActivityForm .labelServiceInfoActivityForm {
    color: #747678;
    font: 400 15px / 20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .containerServiceInfoActivityForm .serviceInfoActivityForm {
    color: var(--primary-color);
    padding-left: 6px;
    font-size: 16px;
    height: 32px;
    display: flex;
    border-radius: 2px;
    border: 1px solid #cbcccd;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .containerServiceInfoActivityForm .ownersInfoActivityForm {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonAnalysisActivityForm {
    height: 32px;
    font-weight: 400;
    font-size: 14px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonUrlLinkActivityForm {
    background: var(--color-focus);
    color: var(--white-light);
    padding: 5px;
    width: 35px;
    height: 30px;
    border: 0;
    display: flex;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonUrlLinkWarningActivityForm {
    background: var(--color-warning);
    color: var(--white-light);
    padding: 5px;
    width: 35px;
    height: 30px;
    border: 0;
    display: flex;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .nameActivityForm input {
    height: 40px!important;
    margin: 0;
    font-size: 22px!important;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm {
    border: none;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button {
    height: 32px;
    font-size: 14px;
    border-left: 1px solid var(--white-dark-two);
    border-top: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    color: var(--primary-color);    
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button:last-of-type {
    border-right: 1px solid var(--white-dark-two);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button.active {
    border: 1px solid var(--color-focus);
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm {
    position: relative;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .dropdownTimeActivityForm {
    position: absolute;
    top: 0;
    left: 0;
    top: inherit;
    flex-direction: column;
    width: 100%;
    z-index: 900;
    max-height: 200px;
    overflow: scroll;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .timeActivityForm {
    padding: 5px 15px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonClearTimeActivityForm {
    position: absolute;
    top: 0;
    width: 32px;
    height: 32px;
    right: 10px;
    background-color: transparent;
    color: var(--primary-color);
    font-size: 10px;

}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .timeActivityForm:hover,
.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .timeActivityForm.active {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .annotationsActivityForm textarea{
    height: 150px!important;
    background: rgb(255, 252, 220)!important;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonFileActivityForm {
    background-color: transparent;
    color: var(--dark-dark-three);    
    margin-right: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm.file {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonFileActivityForm::after {
    content: "Arquivo";
    display: block;
    position: absolute;
    right: -70px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonFileActivityForm:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-light);
    border-radius: 50%;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .nameFileActivityForm {
    color: var(--dark-dark-three);    
    font-weight: 300;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonDeleteDiscountActivityForm {
    background-color: var(--color-error);
    /* width: 30px; */
    height: 32px;
    font-weight: 400;
    font-size: 14px;
}

.activityForm .bodyActivityForm .lineActivityForm .installmentDetailsActivityForm {
    border-radius: 5px;
    font-size: 24px;
}

.activityForm .bodyActivityForm .lineActivityForm .installmentDetailsActivityForm .activityFormInstallmentInputActivityForm input {
    text-align: right;
    padding-right: 10px;
    height: 50px;
    width: 150px;
}

.activityForm .bodyActivityForm .lineActivityForm .activityFormSelect .fieldSelect,
.activityForm .bodyActivityForm .lineActivityForm .valueAuthorizationSuccessAcitivityForm .fieldInputGeneral {
    height: 50px;
    font-size: 20px;
}

.activityForm .bodyActivityForm .lineActivityForm .investimentActivityForm .labelInputGeneral {
    font-size: 17px;
    font-weight: 600;
}

.activityForm .bodyActivityForm .lineActivityForm .activityFormTextResultAnalysis {
    font-size: 24px;
}

.activityForm .footerActivityForm .lineActivityForm {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 36px;
    padding-right: 36px;
    background: rgb(247, 247, 247);
    border-top: 1px solid rgb(203, 204, 205);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.activityForm .footerActivityForm .lineActivityForm .buttonEditServiceActivityForm {
    background: var(--color-warning);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 32px; 
    font-weight: 400;
    font-size: 14px;
}

.activityForm .footerActivityForm .lineActivityForm .buttonAddServiceActivityForm {
    background: var(--color-warning);
    height: 50px;
    font-weight: 400; 
    font-size: 18px;
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm {
    width: initial;
    margin-right: 15px;
    border-radius: 2px!important;
    color: var(--primary-color);
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm .squareCheck {
    border-radius: 4px;
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm.active .squareCheck {
    background-color: var(--color-focus);
    border-color: var(--color-focus);
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm:hover .squareCheck {
    border-color: var(--color-focus);
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.activityForm .lineActivityForm .buttonShortcutActivityForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 2px 12px;
    width: auto;
    font-weight: 600;
    font-size: 14px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    display: inline-block;
    text-align: left;
}

.activityForm .lineActivityForm .buttonShortcutActivityForm i {
    font-size: 16px;
    margin-right: 10px;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.activityForm .bodyActivityForm .lineActivityForm .buttonActivityForm {
    background-color: var(--color-focus);
}

.activityForm .bodyActivityForm .lineActivityForm .buttonIconSpacingActivityForm {
    margin-right: .5rem !important;
}
.activityForm .bodyActivityForm .lineActivityForm .buttonRefreshAnalysisActivityForm {
    height: 32px;
    width: 20px;
    font-weight: 400;
    font-size: 14px;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
    margin-left: 10px;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.dropDown { 
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    min-width: 100px;
    min-height: 120px;
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;    
}
.menus {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid var(--white-dark-two);
}

.menus .buttonMenus {
    width: auto;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    color: var(--dark-dark-three);
    padding: 10px 13px;
    height: 47px;
    
}

.menus .buttonMenus:hover {
    background-color: var(--white-dark);
    color: var(--primary-color);
}

.menus .buttonMenus.active {
    color: var(--color-focus);
    border-bottom: 2px solid var(--color-focus);
    background-color: #eff5fd;
}

.menus .buttonMenus:hover.active {
    background: #d3e4fa;
}

.menus .buttonMenus .icon {
    margin-right: 10px;
}
.groupInputArea {  
    width: 100%;
}

.groupInputArea .labelInputArea {  
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.groupInputArea .inputInputArea {  
    position: relative;
}

.groupInputArea .inputInputArea .fieldInputArea {  
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    box-sizing: border-box;
    display: block;
    padding: 4px 32px 4px 8px;
    height: 32px;
    font: inherit;
    color: #26292c;
    transition: padding 75ms ease-in-out;
    
}

.groupInputArea .inputInputArea .fieldInputArea.error { 
    border: 1px solid var(--color-error);
}

.groupInputArea .inputInputArea .fieldInputArea:focus {
    border: 1px solid #317ae2;
    box-shadow: inset 0 1px 2px rgb(49 122 226 / 8%);
    outline: none;
}
.message {
    background-color: rgba(0, 0, 0, 0.342);
}

.modal-content { /* bootstrap class */
    background-color: transparent;
    border: none;
}

.message .content {
    border-radius: 20px;
    background-color: var(--white-light);
    overflow: hidden;
}

/* Start styling header */
/* Start styling header */
/* Start styling header */
.message .content .header {
    border-bottom: 2px solid var(--dark-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 30px;
}

.message .content .header .status{
    font-weight: 600;
    font-size: 16px;
}

.message .content .header .status.success{ color: #28a745 }

.message .content .header .status.error{ color: #dc3545 }

.message .content .header .status.warning{ color: #ffc107 }

.message .content .header .status.information{ color: #007bff }

.message .content .header .status .iconStatus{
    margin-right: 10px;
    font-size: 22x;
}

.message .content .header .close{
    border: 1px solid var(--dark-dark);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent!important;
}
.message .content .header .button .iconButton{
    color: var(--dark-dark);
}
/* Ends styling header */
/* Ends styling header */
/* Ends styling header */


/* Start styling body */
/* Start styling body */
/* Start styling body */
.message .content .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: justify;
    padding: 30px;
}

.message .content .body p {
   font-size: 18px;
   font-weight: 400;
   color: var(--dark-dark);
}
/* Ends styling body */
/* Ends styling body */
/* Ends styling body */
.messageConfirmation {
    background-color: rgba(0, 0, 0, 0.342);
}

.modal-content { /* bootstrap class */
    background-color: transparent;
    border: none;
}

.messageConfirmation .content {
    border-radius: 20px;
    background-color: var(--white-light);
    overflow: hidden;
}

/* Start styling header */
/* Start styling header */
/* Start styling header */
.messageConfirmation .content .header {
    border-bottom: 2px solid var(--dark-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 30px;
}

.messageConfirmation .content .header .status{
    font-weight: 600;
    font-size: 16px;
}

.messageConfirmation .content .header .status.success{ color: #28a745 }

.messageConfirmation .content .header .status.error{ color: #dc3545 }

.messageConfirmation .content .header .status.warning{ color: #ffc107 }

.messageConfirmation .content .header .status.information{ color: #007bff }

.messageConfirmation .content .header .status .iconStatus{
    margin-right: 10px;
    font-size: 22x;
}

.messageConfirmation .content .header .close{
    border: 1px solid var(--dark-dark);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent!important;
}
.messageConfirmation .content .header .button .iconButton{
    color: var(--dark-dark);
}
/* Ends styling header */
/* Ends styling header */
/* Ends styling header */

/* Start styling body */
/* Start styling body */
/* Start styling body */
.messageConfirmation .content .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: justify;
    padding: 30px;
    flex-direction: column;
}

.messageConfirmation .content .body p {
    font-size: 18px;
    font-weight: 400;
    color: var(--dark-dark);
}

.messageConfirmation .content .body .lineBodyMessageConfirmation {
    width: 100%;
}

.messageConfirmation .content .body .lineBodyMessageConfirmation .columnBodyMessageConfirmation .buttonMessageConfirmation {
    width: 100px;
    font-weight: 400;
    height: 40px;
    font-size: 14px;
    margin: 0;
    background-color: var(--color-error);
}

.messageConfirmation .content .body .lineBodyMessageConfirmation .columnBodyMessageConfirmation .buttonMessageConfirmation.positive {
    background-color: var(--color-success);
}
/* Ends styling body */
/* Ends styling body */
/* Ends styling body */
#info-tooltip .tooltip-inner {
    text-align: left;
    background: var(--color-warning);
    color: #fff;
}
#info-tooltip .tooltip-inner i {
    margin-bottom: 1px;
}
.tooltip .tooltip-arrow {
    content: '';
    border-width: 4px;
    border-style: solid;
    border-color: transparent transparent var(--color-warning) transparent;
}

.large-modal {
    background-color: rgba(0, 0, 0, 0.342);
}
modal-
.content { /* bootstrap class */
    background-color: transparent;
    border: none;
}

.modal-fullscreen { 
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
}

.modal-fullscreen .modal-content { 
    border: 0;
    border-radius: 0 !important;
    height: 100%;
    background-clip: padding-box;
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%;
}

.modal-fullscreen .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
}

.modal-fullscreen .footer, .modal-fullscreen .header {
    border-radius: 0;
}

.modal-fullscreen .body {
    overflow-y: auto;
}

.large-modal .modal-content {
    border-radius: 20px;
    background-color: var(--white-light);
    overflow: hidden;
}

/* .modal-fullscreen .modal-content .modal-content { 
    border-radius: 0;
} */

/* Start styling header */
/* Start styling header */
/* Start styling header */
.large-modal .modal-content .header {
    border-bottom: 0.5px solid #e0e0e0;
    display: flex;
    box-shadow: none;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 30px;
}

.large-modal .modal-content .header .status {
    font-weight: 600;
    font-size: 16px;
}

.large-modal .modal-content .header .titleService, .large-modal .modal-content .header .title {
    font-size: 20px;
    font-weight: 300;
}

.large-modal .modal-content .header .containerInfoAndCloser {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
}

.large-modal .modal-content .header .containerInfoAndCloser .informationLargeModal {
    border: 1px solid var(--color-information);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent !important;
}

.large-modal .modal-content .header .informationLargeModal .iconInfo {
    font-size: 14px;
    color: var(--color-information);
}

.large-modal .modal-content .header .status.success{ color: #28a745 }

.large-modal .modal-content .header .status.error{ color: #dc3545 }

.large-modal .modal-content .header .status.warning{ color: #ffc107 }

.large-modal .modal-content .header .status.information{ color: #007bff }

.large-modal .modal-content .header .status .iconStatus{
    margin-right: 10px;
    font-size: 22x;
}

.large-modal .modal-content .header .containerInfoAndCloser .close{
    margin-left: 10px;
    border: 1px solid var(--dark-dark);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent!important;
}
.large-modal .modal-content .header .button .iconButton{
    color: var(--dark-dark);
}
/* Ends styling header */
/* Ends styling header */
/* Ends styling header */

/* Start styling body */
/* Start styling body */
/* Start styling body */
.large-modal .modal-content .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: justify;
    padding: 30px;
    flex-direction: column;
}

.large-modal .modal-content .body p {
    font-size: 18px;
    font-weight: 400;
    color: var(--dark-dark);
}

.large-modal .modal-content .body .lineBodyLargeModal {
    width: 100%;
}

.large-modal .modal-content .body .lineBodyLargeModal .columnBodyMessageConfirmation .buttonMessageConfirmation {
    width: 100px;
    font-weight: 400;
    height: 40px;
    font-size: 14px;
    margin: 0;
    background-color: var(--color-error);
}

.large-modal .modal-content .body .lineBodyLargeModal .columnBodyMessageConfirmation .buttonMessageConfirmation.positive {
    background-color: var(--color-success);
}
/* Ends styling body */
/* Ends styling body */
/* Ends styling body */
.subtitleAnimation.anchor {
    position: relative;
    min-height: 30px; /*DANGER: don't change this*/
    max-height: 90px; /*DANGER: don't change this*/
    min-width: 10px;
    max-width: 300px;
    background-color: transparent;
}

.subtitleAnimation {
    border: none;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: black;
    margin: 5px;
    color: var(--white-light);
    padding: 4px 6px;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    cursor: default;
    -webkit-user-select: none;
            user-select: none;
    position: absolute;
    top: 10px;
    height: 100%;
    -webkit-animation-name: animation;
            animation-name: animation;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.subtitleAnimation.container.warning {
    background-color: var(--color-warning);
}

.subtitleAnimation.container.success {
    background-color: var(--color-success);
}

.subtitleAnimation.container.information {
    background-color: var(--color-information);
}

.subtitleAnimation.container.focus {
    background-color: var(--color-focus);
}

.subtitleAnimation.container.inactive {
    background-color: var(--color-inactive);
}

.subtitleAnimation.container.error {
    background-color: var(--color-error);
}

.subtitleAnimation.container.inactive {
    background-color: var(--color-inactive);
}

.subtitleAnimation.container .contentSubtitleAnimation {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.subtitleAnimation.container::before {
    content: " ";
    display: block;
    right: -6px;
    position: absolute;
    border-top: 15px solid transparent;
    border-left: 14px solid black;
    -webkit-transform: rotate(-137deg);
            transform: rotate(-137deg);
    top: 7px;
    border-radius: 4px;
}

.subtitleAnimation.lines2.container::before {
    top: 14px;
}

.subtitleAnimation.lines3.container::before {
    top: 24px;
}

.subtitleAnimation.lines4.container::before {
    top: 30px;
}

.subtitleAnimation.container.warning::before {
    border-left: 14px solid var(--color-warning);
}

.subtitleAnimation.container.success::before {
    border-left: 14px solid var(--color-success);
}

.subtitleAnimation.container.information::before {
    border-left: 14px solid var(--color-information);
}

.subtitleAnimation.container.focus::before {
    border-left: 14px solid var(--color-focus);
}

.subtitleAnimation.container.inactive::before {
    border-left: 14px solid var(--color-inactive);
}

.subtitleAnimation.container.error::before {
    border-left: 14px solid var(--color-error);
}

@-webkit-keyframes animation
{
    0%   {top: 3px;}
    50%  {top: -3px;}
    100%  {top: 3px;}
}

@keyframes animation
{
    0%   {top: 3px;}
    50%  {top: -3px;}
    100%  {top: 3px;}
}
.loading {
    text-align: center;
    padding: 10px;
    margin: 10px;
    top: 0;
    background-color: var(--white-light);
    border-radius: 5px;
}
.containerCustomCarousel .carousel-control-prev,
.containerCustomCarousel .carousel-control-next {
  background-color: #ffffff1c;
  border: none;
  border-radius: 50%;
  margin: 0 10px;
  color: #959191;
  width: 40px;
  font-size: 25px;
  height: 40px;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.containerCustomCarousel .carousel-control-prev-icon,
.containerCustomCarousel .carousel-control-next-icon {
  background: none;
  font-size: 1.5rem;
  color: #ffffff;
  transition: color 0.3s ease;
}

.containerCustomCarousel .carousel-control-prev:hover,
.containerCustomCarousel .carousel-control-next:hover {
  color: var(--color-focus);
}

.containerCustomCarousel .carousel-control-prev .visually-hidden,
.containerCustomCarousel .carousel-control-next .visually-hidden {
  display: none !important;
}

.containerCustomCarousel .carousel-control-prev span,
.containerCustomCarousel .carousel-control-next span {
  display: none !important;
}

.containerCustomCarousel iframe {
  pointer-events: none;
}

.containerCustomCarousel .carousel-caption {
  position: absolute;
  bottom: 10%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: 15px 25px;
  max-width: 80%;
  text-align: center;
}

.containerCustomCarousel .carousel-caption h3 {
  font-size: 1.8rem;
  font-weight: bold;
}

.containerCustomCarousel .carousel-caption p {
  font-size: 1rem;
  margin-top: 5px;
}

.containerCustomCarousel .carousel-item img {
  max-height: 700px;
  object-fit: cover;
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
  transition: -webkit-filter 0.3s ease;
  transition: filter 0.3s ease;
  transition: filter 0.3s ease, -webkit-filter 0.3s ease;
}

.containerCustomCarousel .carousel-item img:hover {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.containerCustomCarousel .carousel-indicators [data-bs-target] {
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  border: 1px solid #a7a7a7;
  margin: 0 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.containerCustomCarousel .carousel-indicators [data-bs-target].active {
  background-color: var(--color-focus);
}

.modalEdit {
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 5px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    position: relative;
    /* overflow: hidden; */
}

.modalEdit .headerModalEdit {
    padding: 15px;
}

.modalEdit .headerModalEdit .titleModalEdit {
    color: var(--dark-light);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.modalEdit .bodyModalEdit {
    padding: 15px;
    padding-top: 0;
}

.modalEdit .footerModalEdit {
    border-top: 1px solid var(--white-dark-two);
    background-color: var( --white-dark);
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 5px 15px;
}

.modalEdit .footerModalEdit .buttonModalEdit {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 80px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    /* width: 50px; */
}

.modalEdit .footerModalEdit .buttonModalEdit:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.modalEdit .footerModalEdit .buttonModalEdit.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.modalEdit .footerModalEdit .buttonModalEdit.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.campaignForm {
    width: 100%;
}

.campaignForm .lineCampaignForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.campaignForm .lineCampaignForm .columnCampaignForm {
    height: 100%;
}

.campaignForm .lineCampaignForm .columnCampaignForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.campaignForm .lineFooterCampaignForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.campaignForm .lineFooterCampaignForm .buttonCampaignForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.campaignForm .lineFooterCampaignForm .buttonCampaignForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.campaignForm .lineFooterCampaignForm .buttonCampaignForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.campaignForm .lineFooterCampaignForm .buttonCampaignForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.unitCompanyForm {
    width: 100%;
}

.unitCompanyForm .lineUnitCompanyForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.unitCompanyForm .lineUnitCompanyForm .columnUnitCompanyForm {
    height: 100%;
}

.unitCompanyForm .lineUnitCompanyForm .columnUnitCompanyForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.unitCompanyForm .lineFooterUnitCompanyForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.unitCompanyForm .lineFooterUnitCompanyForm .buttonUnitCompanyForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.unitCompanyForm .lineFooterUnitCompanyForm .buttonUnitCompanyForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.unitCompanyForm .lineFooterUnitCompanyForm .buttonUnitCompanyForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.unitCompanyForm .lineFooterUnitCompanyForm .buttonUnitCompanyForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.itemHistoric {
    width: 100%;
    padding: 5px 10px;
    background-color: transparent;
    /* min-height: 60px; */
    cursor: pointer;
}

.itemHistoric:hover {
    background-color: var(--white-dark-two);
    transition: background-color 1s;
}

.itemHistoric .colItemHistoric { 
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.itemHistoric .colItemHistoric.detailsItemHistoric {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;    
    overflow: scroll;
}

.itemHistoric .colItemHistoric .iconItemHistoric { 
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.itemHistoric .colItemHistoric .nameItemHistoric {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.itemHistoric .colItemHistoric .nameItemHistoric:hover {
    text-decoration: underline;
}

.itemHistoric .colItemHistoric .nameItemHistoric:hover .cardDetailsItemHistoric{
    visibility: visible;
    transition: visibility;
    transition-delay: 1s;
}

.itemHistoric .colItemHistoric .descriptionItemHistoric {
    color: var(--dark-dark);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 4.2;
    width: 100%;
}

.itemHistoric .colItemHistoric .iconItemHistoric.initialsName { 
    color: var(--color-information);
    font-weight: 600;
    font-size: 10px;
    background-color: #c2dfff;
}
.cardDetailsBusiness {
    position: fixed;
    width: 400px;
    height: 250px;
    z-index: 500;
    color: var(--dark-dark);
    display: flex;
    flex-direction: column;
    top: 120px;
    display: none;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding: 30px 0px;
    flex-direction: column;
    display: flex;
    vertical-align: middle;
    background-color: var(--white-light);
    visibility: hidden;
    padding: 10px;
    
}

.cardDetailsBusiness span { 
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    width: 100%;
} 

.cardDetailsBusiness .lineCardDetailsBusiness {
    width: 100%;
    height: 100%;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    height: 100%;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .progressLineDetailsBusiness .stageContentProgressLine {
    width: 19%;
}   

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .progressLineDetailsBusiness .stageContentProgressLine {
   font-size: 0;
   color: transparent;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness.columnIconCardDetailsBusiness {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .anchorNameCardDetailsBusiness {
    font-size: 15px;
    font-weight: 400;
    width: initial;
    margin-left: 0;
    padding-left: 0;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .anchorNameCardDetailsBusiness:hover {
    color: var(--color-focus);
    text-decoration: underline!important;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .detailItem{    
    font-size: 15px;
    font-weight: 400;
    cursor: default;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .iconCardDetailsBusiness {
    font-size: 14px;
    color: var(--dark-light);
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .iconCardDetailsBusiness.big {
    font-size: 24px;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .moreCardDetailsBusiness {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .moreCardDetailsBusiness .anchorCardDetailsBusiness {
    margin-left: 0;
    padding-left: 0;
    margin-top: 0;
    padding-bottom: 0;
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: flex-start;
    display: flex;
    width: 100%;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .moreCardDetailsBusiness .anchorCardDetailsBusiness:hover {
    color: var(--color-focus);
    text-decoration: underline!important;
}

.cardDetailsContact {
    position: fixed;
    width: 400px;
    min-height: 40px;
    z-index: 500;
    color: var(--dark-dark);
    display: flex;
    flex-direction: column;
    top: 120px;
    display: none;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding: 30px 0px;
    flex-direction: column;
    display: flex;
    vertical-align: middle;
    background-color: var(--white-light);
    visibility: hidden;
    padding: 10px;
}

.cardDetailsContact .lineCardDetailsContact {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.cardDetailsContact .lineCardDetailsContact .columnIconDetailsContact {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.cardDetailsContact .lineCardDetailsContact .columnIconDetailsContact .initialsNameDetailsContact {
    color: var(--color-information);
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    background-color: #c2dfff;
    border-radius: 50%;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact {
    display: flex;
    align-items: flex-start;
    vertical-align: middle;
    padding-left: 5px;
    overflow: hidden;
    flex-direction: column;
}

.cardDetailsContact .lineCardDetailsContact .columnIconDetailsContact .iconDetailsContact{
    color: var(--dark-dark-three);
    font-size: 12px;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact span{
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 400;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact .anchorCardDetailsContact {
    font-size: 20px;
    padding-left: 0;
    margin-left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: flex-start;
    display: flex;
    width: 100%;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact .anchorCardDetailsContact:hover {
    color: var(--color-focus);
    text-decoration: underline!important;
}
.inputSearch { 
    width: 100%;
    height: 40px;
    flex-direction: row;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.inputSearch .icon {
    color: var(--primary-color);
    position: absolute;
    left: 14px;
    transition: color .3s;
}

.inputSearch .icon.yesFocus {
    color: green;
    color: var(--color-focus);
}

.inputSearch .icon.noFocus {
    color: var(--primary-color);
}

.inputSearch .field {
    width: 100%;
    height: 40px;
    padding: 5px 45px 6px 36px;
    background-color: var(--white-light);
    border: 2px solid #26292c29;
    border-radius: 40px;
    box-sizing: border-box;
    outline: none!important;
    box-shadow: none;
    color: var(--primary-color);
    transition: border .3s;
}

.inputSearch .field.yesFocus {
    border: 2px solid var(--color-focus);
}

.inputSearch .field.noFocus {
    border: 2px solid #26292c29;
}

.inputSearch .buttonIcon {
    position: absolute;
    right: 20px;
    font-size: 16px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    color: #404346;
}

.inputSearch .buttonIcon:hover {
    background: #26292c0d;
    transition: background-color .1s;
}

.inputSearch .historicSearch {
    position: absolute;
    top: 44px;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    overflow: scroll;
    padding: 30px 0px;
    flex-direction: column;
    display: flex;
    vertical-align: middle;
    width: 400px;
    max-height: 350px;
    background-color: var(--white-light);
    z-index: 900;
}

.inputSearch .historicSearch .labelLastHistoricSearch{  
    font-size: 16px;
    font-weight: 500;
    margin-left: 22px;
    margin-right: 20px;
    margin-bottom: 5px;
    color: var(--dark-dark);
}

@media (max-width: 767px){
    .inputSearch .historicSearch {
        display: none;
    }
}
.modalGeneral { 
    background-color: rgba(0, 0, 0, 0.294);
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    vertical-align: top;
}

.modalGeneral .contentModalGeneral { 
    background-color: var(--white-light);
    min-width: 300px;
    /* max-width: 800px; */
    border-radius: 5px;
    position: relative;
}

.modalGeneral .contentModalGeneral .lineHeaderModalGeneral {
    display: flex;
    padding: 12px 16px;
    height: 48px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.modalGeneral .contentModalGeneral .lineHeaderModalGeneral .buttonCloseModalGeneral {
    background-color: transparent;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}

.modalGeneral .contentModalGeneral .lineHeaderModalGeneral .title {
    font-size: 18px;
    color: #26292c;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.modalGeneral .contentModalGeneral .lineBodyModalGeneral { 
    padding: 13px 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.modalGeneral .contentModalGeneral .lineFooterModalGeneral {
    display: flex;
    height: 48px;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
    width: 100%;
    align-items: flex-end;
    padding: 12px 48px 12px 16px;

}

.modalGeneral .contentModalGeneral .lineFooterModalGeneral .buttonModalGeneral { 
    /* margin: 0;
    min-width: 60px;
    max-width: 120px;
    height: 40px;
    font-size: 16px; */
}
.commissionRuleForm {
    width: 100%;
}

.commissionRuleForm .lineCommissionRuleForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.commissionRuleForm .lineCommissionRuleForm .columnCommissionRuleForm {
    height: 100%;
}

.commissionRuleForm .lineCommissionRuleForm .columnCommissionRuleForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.commissionRuleForm .lineFooterCommissionRuleForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.commissionRuleForm .lineFooterCommissionRuleForm .buttonCommissionRuleForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.commissionRuleForm .lineFooterCommissionRuleForm .buttonCommissionRuleForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.commissionRuleForm .lineFooterCommissionRuleForm .buttonCommissionRuleForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.commissionRuleForm .lineFooterCommissionRuleForm .buttonCommissionRuleForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.permissionGroupForm {
    width: 100%;
}

.permissionGroupForm .linePermissionGroupForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.permissionGroupForm .linePermissionGroupForm .columnPermissionGroupForm {
    height: 100%;
}

.permissionGroupForm .linePermissionGroupForm .columnPermissionGroupForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.permissionGroupForm .lineFooterPermissionGroupForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.permissionGroupForm .lineFooterPermissionGroupForm .buttonPermissionGroupForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.permissionGroupForm .lineFooterPermissionGroupForm .buttonPermissionGroupForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.permissionGroupForm .lineFooterPermissionGroupForm .buttonPermissionGroupForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.permissionGroupForm .lineFooterPermissionGroupForm .buttonPermissionGroupForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.businessByClientForm {
    width: 100%;
}

.businessByClientForm .lineBusinessByClientForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.businessByClientForm .lineBusinessByClientForm .columnBusinessByClientForm {
    height: 100%;
}

.businessByClientForm .lineBusinessByClientForm .columnBusinessByClientForm .progressLineBusinessByClientForm .stageContentProgressLine {
    font-size: 0;
    color: transparent;
}

.businessByClientForm .lineBusinessByClientForm .columnBusinessByClientForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.businessByClientForm .lineBusinessByClientForm .columnBusinessByClientForm .selectStampsBusinessByClientForm {
}

.businessByClientForm .lineBusinessByClientForm .columnBusinessByClientForm .selectStampsBusinessByClientForm .labelOptionSelect {
}

.businessByClientForm .lineFooterBusinessByClientForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.businessByClientForm .lineFooterBusinessByClientForm .buttonBusinessByClientForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.businessByClientForm .lineFooterBusinessByClientForm .buttonBusinessByClientForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.businessByClientForm .lineFooterBusinessByClientForm .buttonBusinessByClientForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.businessByClientForm .lineFooterBusinessByClientForm .buttonBusinessByClientForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.businessByClientForm .lineBusinessByClientForm .columnBusinessByClientForm .stepBusinessByClientForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}
.commissionReportForm {
    width: 100%;
}

.commissionReportForm .lineCommissionReportForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.commissionReportForm .lineCommissionReportForm .columnCommissionReportForm {
    height: 100%;
}

.commissionReportForm .lineCommissionReportForm .columnCommissionReportForm .progressLineCommissionReportForm .stageContentProgressLine {
    font-size: 0;
    color: transparent;
}

.commissionReportForm .lineCommissionReportForm .columnCommissionReportForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.commissionReportForm .lineFooterCommissionReportForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.commissionReportForm .lineFooterCommissionReportForm .buttonCommissionReportForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.commissionReportForm .lineFooterCommissionReportForm .buttonCommissionReportForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.commissionReportForm .lineFooterCommissionReportForm .buttonCommissionReportForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.commissionReportForm .lineFooterCommissionReportForm .buttonCommissionReportForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.commissionReportForm .lineCommissionReportForm .columnCommissionReportForm .stepCommissionReportForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}
.absenceAttendantsForm {
    width: 100%;
}

.absenceAttendantsForm .lineAbsenceAttendantsForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.absenceAttendantsForm .lineAbsenceAttendantsForm .columnAbsenceAttendantsForm {
    height: 100%;
}

.absenceAttendantsForm .lineAbsenceAttendantsForm .columnAbsenceAttendantsForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.absenceAttendantsForm .lineFooterAbsenceAttendantsForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.absenceAttendantsForm .lineFooterAbsenceAttendantsForm .buttonAbsenceAttendantsForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.absenceAttendantsForm .lineFooterAbsenceAttendantsForm .buttonAbsenceAttendantsForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.absenceAttendantsForm .lineFooterAbsenceAttendantsForm .buttonAbsenceAttendantsForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.absenceAttendantsForm .lineFooterAbsenceAttendantsForm .buttonAbsenceAttendantsForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.commissionProspectionDetailsForm {
    width: 450px;
}

.commissionProspectionDetailsForm .lineCommissionProspectionDetailsForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.commissionProspectionDetailsForm .lineCommissionProspectionDetailsForm .columnCommissionProspectionDetailsForm {
    height: 100%;
}

.commissionProspectionDetailsForm .lineCommissionProspectionDetailsForm .columnCommissionProspectionDetailsForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.commissionProspectionDetailsForm .lineFooterCommissionProspectionDetailsForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.commissionProspectionDetailsForm .lineFooterCommissionProspectionDetailsForm .buttonCommissionProspectionDetailsForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.commissionProspectionDetailsForm .lineFooterCommissionProspectionDetailsForm .buttonCommissionProspectionDetailsForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.commissionProspectionDetailsForm .lineFooterCommissionProspectionDetailsForm .buttonCommissionProspectionDetailsForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.commissionProspectionDetailsForm .lineFooterCommissionProspectionDetailsForm .buttonCommissionProspectionDetailsForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.commissionIntervalsDetailsForm {
    width: 450px;
}

.commissionIntervalsDetailsForm .lineCommissionIntervalsDetailsForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.commissionIntervalsDetailsForm .lineCommissionIntervalsDetailsForm .columnCommissionIntervalsDetailsForm {
    height: 100%;
}

.commissionIntervalsDetailsForm .lineCommissionIntervalsDetailsForm .columnCommissionIntervalsDetailsForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.commissionIntervalsDetailsForm .lineFooterCommissionIntervalsDetailsForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.commissionIntervalsDetailsForm .lineFooterCommissionIntervalsDetailsForm .buttonCommissionIntervalsDetailsForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.commissionIntervalsDetailsForm .lineFooterCommissionIntervalsDetailsForm .buttonCommissionIntervalsDetailsForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.commissionIntervalsDetailsForm .lineFooterCommissionIntervalsDetailsForm .buttonCommissionIntervalsDetailsForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.commissionIntervalsDetailsForm .lineFooterCommissionIntervalsDetailsForm .buttonCommissionIntervalsDetailsForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.commissionReportIntervalsForm {
    width: 100%;
}

.commissionReportIntervalsForm .lineCommissionReportIntervalsForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.commissionReportIntervalsForm .lineCommissionReportIntervalsForm .columnCommissionReportIntervalsForm {
    height: 100%;
}

.commissionReportIntervalsForm .lineCommissionReportIntervalsForm .columnCommissionReportIntervalsForm .progressLineCommissionReportIntervalsForm .stageContentProgressLine {
    font-size: 0;
    color: transparent;
}

.commissionReportIntervalsForm .lineCommissionReportIntervalsForm .columnCommissionReportIntervalsForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.commissionReportIntervalsForm .lineFooterCommissionReportIntervalsForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.commissionReportIntervalsForm .lineFooterCommissionReportIntervalsForm .buttonCommissionReportIntervalsForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.commissionReportIntervalsForm .lineFooterCommissionReportIntervalsForm .buttonCommissionReportIntervalsForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.commissionReportIntervalsForm .lineFooterCommissionReportIntervalsForm .buttonCommissionReportIntervalsForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.commissionReportIntervalsForm .lineFooterCommissionReportIntervalsForm .buttonCommissionReportIntervalsForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.commissionReportIntervalsForm .lineCommissionReportIntervalsForm .columnCommissionReportIntervalsForm .stepCommissionReportIntervalsForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}
.fieldEdit {
    border: 1px solid #eee;
    /* border-left: 1px solid transparent; */ /*optional*/
    /* border-bottom: 1px solid transparent; */ /*optional*/
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    padding-left: 10px;
    align-items: center;
}

.fieldEdit .iconFieldEdit {
    color: var(--primary-color);
    padding-right: 10px;
}

.fieldEdit:hover {
    background-color: var(--white-light);
}

.fieldEdit .buttonFieldEdit {
    height: 28px;
    width: 28px;
    background-color: var(--white-light);
    font-size: 12px;
    border: 1px solid var(--dark-light);
    right: 2px;
    top: 1px;
    float: right;
    color: var(--primary-color);
    position: absolute;
    opacity: 0;
}

.fieldEdit:hover .buttonFieldEdit {
    opacity: 1;
}

.fieldEdit .modalFieldEdit {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: var(--white-light);
    z-index: 700;
    /* max-width: 300px; */
    /* max-height: 130px; */
}
.tableEditLeads {
    background-color: var(--white-light);    
    height: 100%;
    overflow: scroll;
}

.tableEditLeads .helpTableEditLeads {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditLeads table, tr, th, td {
    margin: 0;
}

.tableEditLeads table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditLeads table .buttonCheckTableEditLeads {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditLeads table .buttonCheckTableEditLeads .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditLeads table .buttonCheckTableEditLeads.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditLeads table .buttonCheckTableEditLeads.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditLeads table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditLeads table tr {
    height: 32px;
}

.tableEditLeads .theadTableEditLeads td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditLeads .theadTableEditLeads td:last-of-type {
    border-right: none;
}

.tableEditLeads td.checkTableEditLeads {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditLeads .tbodyTableEditLeads:hover {
    background-color: var(--white-dark);
}

.tableEditLeads .tbodyTableEditLeads:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditLeads .tbodyTableEditLeads td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditLeads .table .tbodyTableEditLeads td.checkTableEditLeads {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditLeads .tbodyTableEditLeads td:hover .buttonEditTableEditLeads {
    opacity: 1;
}

.tableEditLeads .tbodyTableEditLeads td:hover .editTableEditLeads {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditLeads .tbodyTableEditLeads td .editTableEditLeads.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditLeads .tbodyTableEditLeads td .stampEditLeads {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditLeads .tbodyTableEditLeads td .editTableEditLeads .modalFieldEdit {
    z-index: 1000;
}
.check {
    position: relative;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    text-align: left;
    color: #26292ca3;
    font-size: 15px;
    line-height: 20px;    
}

.check .squareCheck {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    margin-right: 10px;
    border: 1px solid black;
    padding: 8px;
    width: 16px;
    height: 16px;
    border: 2px solid #b9babb;
}

.check .checked {
    border: 2px solid green;
    background: green;
}

.check .iconCheck{
    color: #fff;
    font-size: 10px;
}
.business {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.business .warningBusiness {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.selectAttendantToTransferBusiness .optionsSelect { /*INFORMATION: component inside modal confirmation*/
    position: relative;
}

.lineMessagesWhatsAppBusiness { /* WARNING: Don't have reference with .Business, because is modal*/
    justify-content: space-between;
    display: flex;
}

.lineMessagesWhatsAppBusiness .buttonWhatsAppBusiness { /* WARNING: Don't have reference with .Business, because is modal*/
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    min-width: 32px;
    max-width: 100px;
}

.lineMessagesWhatsAppBusiness .buttonWhatsAppBusiness.information { /* WARNING: Don't have reference with .Business, because is modal*/
    max-width: 140px;
    background-color: var(--color-focus);
}

textarea.fieldMessageWhatsAppDetailsBusiness { /* WARNING: Don't have reference with .detailsBusiness, because is modal*/
    width: 100%;
    height: 198px;
}

.business .containerDraggableAreaHeaderBusiness {
    display: flex;
    flex-direction: row;
    height: 60px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines {  
    position: relative;
    width: 20%;
    background-color: var(--white-dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    text-align: left;
    padding-left: 15px;
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines .titleColumnBusiness { 
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines .informationColumnBusiness { 
    color:  var(--dark-light);
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines .arrow{
    width: 9px;
    height: 55px;
    position: absolute;
    right: -4px;
    fill: var(--white-light);
    z-index: 600;
}

.business .containerExtraDraggableBusiness {
    position: relative;
}

.business .containerDraggableAreBusiness { 
    background-color: var(--white-dark);
    position: relative;
    flex-direction: row;
    display: flex;
    height: calc(100vh - 116px - 65px); /*56px by header component + 60px by containerDraggableAreaHeaderBusiness + 65px containerNavigationHeaderBusiness*/
    overflow: scroll;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness { 
    width: 20%;
    height: 100%;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness.active {
    background-color: var(--white-dark-two);
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .buttonWhatsappDraggableBusiness {
    color: var(--color-success);
    background-color: transparent;
    height: initial;
    position: absolute;
    bottom: 6px;
    right: 6px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .draggableBusiness { 
    width: 500px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .buttonPlusDealDraggableBusiness {
    opacity: 0; 
    background-color: transparent;
    margin: 5px 10px;
    height: 30px;
    border-radius: 5px;
    font-size: 18px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness:hover .buttonPlusDealDraggableBusiness {
    opacity: 1;
    color: var(--dark-light);
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .buttonPlusDealDraggableBusiness:hover {
    background: rgba(38,41,44,.05);
    color: var(--primary-color);
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness { 
    height: 100%;
    width: 100%;
    padding: 6px 10px;
    display: flex;
    flex-direction: column;
    line-height: 1.3;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .containerStampDraggableBusiness {
    background-color: black;
    width: 32px!important;
    border-radius: 10px!important;
    height: 4px!important;
    overflow: hidden;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .containerStampDraggableBusiness .stampDraggableBusiness {
    display: block;
    content: " ";
    width: 100%;
    height: 100%;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .titleDraggableBusiness {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    width: 100%;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .nameDraggableBusiness {
    color: var(--dark-light);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .lineDraggablesBusiness {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .lineDraggablesBusiness .moneyDraggableBusiness {
    color: var(--dark-light);
    font-size: 14px;
    font-weight: 600;
}

.business .containerDraggableAreaFooterBusiness {
    border: 1px solid var(--white-dark-two);
    background-color: var(--white-dark);
    height: 65px;
    position: absolute;
    bottom: -65px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    z-index: 400;
    transition: bottom 1.1s;
}

.business .containerDraggableAreaFooterBusiness.active {
    bottom: 0px;
    transition: bottom 1.1s;
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness {
    width: 25%;
    padding: 5px;
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness {
    height: 100%;
    width: 100%;
    font-size: 16px;
    background-color: transparent;
    font-weight: 600;
    color: var(--dark-light);
    text-transform: uppercase;
    border: 1px dashed var(--dark-light);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness:hover {
    border: none;
    background-color: var(--white-dark-two);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.error {
    color: var(--color-error);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.error:hover {
    border: none;
    color: var(--white-light);
    background-color: var(--color-error);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.success {
    color: var(--color-success);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.success:hover {
    border: none;
    color: var(--white-light);
    background-color: var(--color-success);
}

.business .containerNavigationHeaderBusiness {
    height: 65px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness:hover {
    background-color: var(--white-dark);
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.business .containerNavigationHeaderBusiness .buttonPlusNavigationHeaderBusiness {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.business .containerNavigationHeaderBusiness .buttonTrashNavigationHeaderBusiness {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .iconfilterStatusHeaderBusiness {
    font-size: 14px;
    color: var(--dark-dark);
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness {
    cursor: pointer;
    width: 100%;
    border: none;
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness .textShowSelect,
.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness .fieldSelect,
.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness .fieldSelectMulti
{
    border: none;
}

.business .containerNavigationHeaderBusiness .filterStatusHeaderBusiness .selectStatusHeaderBusiness.selectAttendantsHeaderBusiness .optionsSelectMulti {
    width: auto;
}

.business .containerListHeaderBusiness {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderBusiness */
}

.business .modalMoreFiltersBusiness {
    position: absolute;
    max-width: 100vw;
    min-width: 550px;
    height: 340px;
    top: 35px;
    right: 120px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    z-index: 900;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;
}

.business .modalMoreFiltersBusiness .lineBodyModalMoreFiltersBusiness {
    padding: 5px 13px;
}

.business .modalMoreFiltersBusiness .lineBodyModalMoreFiltersBusiness.featured {
    background-color: var(--white-dark);
}

.business .modalMoreFiltersBusiness .lineFooterModalMoreFiltersBusiness {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
}

.business .buttonFilterDateBusiness {
    color: var(--primary-color);
    width: 100px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    height: 32px;
    padding: 0 10px;
}

.business .buttonFilterDateBusiness:nth-child(1) {
    border-right: 1px solid #26292c32;
    width: 120px;
}

.business .buttonFilterDateBusiness.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.business .modalMoreFiltersBusiness .lineFooterModalMoreFiltersBusiness .buttonModalMoreFiltersBusiness {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.business .modalMoreFiltersBusiness .lineFooterModalMoreFiltersBusiness .buttonModalMoreFiltersBusiness:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.business .modalMoreFiltersBusiness .lineFooterModalMoreFiltersBusiness .buttonModalMoreFiltersBusiness.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.business .modalMoreFiltersBusiness .lineFooterModalMoreFiltersBusiness .buttonModalMoreFiltersBusiness.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.draggable {  
	cursor: pointer;
	border: none;
	min-width: 50px;
	min-height: 80px;
	background-color: var(--white-light);
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	color: var(--primary-color);
	margin: 5px 10px;
	border: 1px solid var(--white-dark-two);
}

.draggable:hover {
	box-shadow: rgb(0 0 0 / 16%) 0px 0px 1px 0px, rgb(0 0 0 / 5%) 0px 2px 2px 0px, rgb(0 0 0 / 6%) 0px 3px 1px -2px, rgb(0 0 0 / 10%) 0px 1px 5px 0px;
	transition: box-shadow 1s;
}
.tableEditBusiness {
    background-color: var(--white-light);        
    height: 100%;
    overflow: scroll;
}

.tableEditBusiness .helpTableEditBusiness {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditBusiness table, tr, th, td {
    margin: 0;
}

.tableEditBusiness table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditBusiness table .buttonCheckTableEditBusiness {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}


.tableEditBusiness table .buttonCheckTableEditBusiness .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditBusiness table .buttonCheckTableEditBusiness.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditBusiness table .buttonCheckTableEditBusiness.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditBusiness table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditBusiness table tr {
    height: 32px;
}

.tableEditBusiness .theadTableEditBusiness td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditBusiness .theadTableEditBusiness td:last-of-type {
    border-right: none;
}

.tableEditBusiness td.checkTableEditBusiness {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditBusiness .tbodyTableEditBusiness:hover {
    background-color: var(--white-dark);
}

.tableEditBusiness .tbodyTableEditBusiness:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditBusiness .tbodyTableEditBusiness td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
}

.tableEditBusiness .table .tbodyTableEditBusiness td.checkTableEditBusiness {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditBusiness .tbodyTableEditBusiness td:hover .buttonEditTableEditBusiness {
    opacity: 1;
}

.tableEditBusiness .tbodyTableEditBusiness td:hover .editTableEditBusiness {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditBusiness .tbodyTableEditBusiness td .editTableEditBusiness.noEdit .buttonFieldEdit {
    display: none;
}
.detailsBusiness {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.detailsBusiness .warningDetailsBusiness {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.detailsBusiness .headerNavigationDetailsBusiness {
}

.select.servicesConfirmationDetailsBusiness .optionsSelect { /* WARNING: Don't have reference with .detailsBusiness, because is modal*/
    position: relative;
}

textarea.fieldMessageWhatsAppDetailsBusiness { /* WARNING: Don't have reference with .detailsBusiness, because is modal*/
    width: 100%;
    height: 198px;
}

.lineMessagesWhatsAppDetailsBusiness { /* WARNING: Don't have reference with .detailsBusiness, because is modal*/
    justify-content: space-between;
    display: flex;
}

.lineMessagesWhatsAppDetailsBusiness .buttonWhatsAppDetailsBusiness { /* WARNING: Don't have reference with .detailsBusiness, because is modal*/
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    min-width: 32px;
    max-width: 100px;
}

.lineMessagesWhatsAppDetailsBusiness .buttonWhatsAppDetailsBusiness.information { /* WARNING: Don't have reference with .detailsBusiness, because is modal*/
    max-width: 140px;
    background-color: var(--color-focus);
}

.detailsBusiness .scrollDetailsBusiness {
    overflow: scroll;
    height: 100%;
}

/* start styles header */
/* start styles header */
/* start styles header */
.detailsBusiness .headerDetailsBusiness {
    background-color: var(--white-light);
    padding: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e5e5e5;
    margin-top: 2px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness {
    margin-bottom: 16px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness {
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalEditTitleDetailsBusiness {
    position: absolute;
    top: 30px;
    width: 300px;
    height: 140px;
    z-index: 800;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness {
    position: absolute;
    top: 50px;
    z-index: 800;
    width: 250px;
    min-height: 120px;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0.1px solid var(--white-dark-two);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness::after {
    width: 18px;
    height: 18px;
    background-color: var(--white-light);
    display: block;
    content: " ";
    position: absolute;
    top: -9px;
    right: 10px;
    z-index: 700;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    border: none;
    border-left: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    /* box-shadow: 0px 0px 7px 1px var(--white-dark-two); */
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness .buttonMoreActionsDetailsBusiness {
    border: none;
    font-size: 15px;
    background-color: transparent;
    color: var(--dark-dark);
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    font-weight: 400;
    padding: 8px 20px;
    width: 100%;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness .buttonMoreActionsDetailsBusiness:hover {
    padding: 0;
    padding: 8px 20px;
    background-color: var(--color-focus);
    color: var(--white-light);
    transition: none;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalEditMoneyDetailsBusiness {
    position: absolute;
    top: 80px;
    width: 300px;
    height: 140px;
    z-index: 800;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .titleHeaderInfoDetailsBusiness {
    color: var(--color-focus);
    font-size: 27px;
    font-weight: 400;
    display: initial;
    cursor: pointer;
    margin: 0;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .titleHeaderInfoDetailsBusiness:hover {
    background-color: #fffcdc;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .nameStampHeaderDetailsBusiness { 
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .nameStampHeaderDetailsBusiness.warning {
    color: black;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .modalEditStampDetailsBusiness { 
    position: absolute;
    top: 30px;
    width: 300px;
    min-height: 140px;
    z-index: 800;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .notificationStampDetailsBusiness{
    display: none;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness:hover .notificationStampDetailsBusiness{
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    top: -25px;
    min-width: 60px;
    z-index: 600;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: block;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .buttonStampHeaderDetailsBusiness {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .buttonStampHeaderDetailsBusiness:hover {
    background-color: var(--white-dark);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness {
    margin-top: 12px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: flex-start;
    line-height: 30px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .moneyInfoDetailsBusiness {
    line-height: 24px;
    font-weight: 400;
    font-size: 23px;
    color: var(--primary-color);
    cursor: pointer;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .moneyInfoDetailsBusiness:hover {
    background-color: #fffcdc;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .serviceInfoDetailsBusiness {
    color: var(--primary-color);
    margin-left: 6px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness::before {
    content: " ";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 1px;
    min-height: 25px;
    margin-left: 16px;
    margin-right: 16px;
    background-color: var(--white-dark-two);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness .iconUserMoreInfoDetailsBusiness {
    font-size: 18px;
    color: #404346;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness .nameUserMoreInfoDetailsBusiness {
    color: var(--primary-color);
    margin-left: 6px;
    font-size: 16px;
    font-weight: 400;
    
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness .buttonPlusBusinessMoreInformationDetailsBusiness.positive {
    min-width: 50px;
    /* max-width: 120px; */
    margin: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    color: var(--white-light);
    border: 0;
    border: none;
    border-radius: 2px;
    margin-top: 0;
    padding: 0 10px;
    margin-bottom: 0px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonIdServiceDetailsBusiness {
    min-width: 32px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    background-color: var(--color-focus);
    margin: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    border: none;
    border-radius: 2px;
    margin-top: 0;
    padding: 0 15px;
    margin-bottom: 0px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness {
    min-width: 32px;
    max-width: 100px;
    margin: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    background: var(--white-light);
    color: var(--white-light);
    border: 0;
    border: none;
    border-radius: 2px;
    margin-top: 0;
    padding: 0 4px;
    margin-bottom: 0px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness:hover {
    opacity: 0.8;
    transition: opacity 0.8s;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness.positive {
    background-color: var(--color-success);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness.negative {
    background-color: var(--color-error);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness.primary {
    background-color: var(--color-focus);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness.warning {
    background-color: var(--color-warning);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness.default {
    background-color: var(--white-light);
    color: var(--dark-dark);
    border: 1px solid var(--dark-light);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonMoreActionsHeaderInfoDetailsBusiness {
    height: 32px;
    width: 32px;
    background: #fff;
    border: 0;
    border-radius: 2px;
    margin: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    color: var(--primary-color);
    border: 1px solid #cbcccd;

}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonMoreActionsHeaderInfoDetailsBusiness:hover {
    background-color: var(--white-dark);
    transition: background-color .12s;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .dividerMoreActionsDetailsBusiness {
    width: 100%;
    height: 1px;
    background-color: var(--white-dark-two);
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .avatarOwnerHeaderDetailsBusiness {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--white-dark-two);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness {
    padding: 0;
    margin: 0 5px 0 10px;
    line-height: 18px;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .notificationOwnerDetailsBusiness {
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    top: -25px;
    min-width: 60px;
    z-index: 600;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: none;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness:hover .notificationOwnerDetailsBusiness {
    display: block;    
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .modalEditOwnerDetailsBusiness{
    position: absolute;
    top: 40px;
    left: 0;
    width: 300px;
    height: 140px;
    z-index: 800;
    text-align: left;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .anchorNameOwnerHeaderDetailsBusiness {
    padding: 0;
    margin: 0;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .anchorNameOwnerHeaderDetailsBusiness:hover {
    text-decoration: underline!important;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .levelNameOwnerHeaderDetailsBusiness {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-light);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .buttonEditOwnerHeaderDetailsBusiness {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    margin-right: 10px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .buttonEditOwnerHeaderDetailsBusiness:hover {
    background-color: var(--white-dark);
}

/* ends styles header */
/* ends styles header */
/* ends styles header */


/* start styles body */
/* start styles body */
/* start styles body */
.detailsBusiness .bodyDetailsBusiness {
    height: 100%;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness {
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness {
    /* border-bottom: 1px solid #e5e5e5; */
    display: inline-flex;
    justify-content: flex-end;
    vertical-align: middle;
    align-items: center;
    padding-bottom: 16px;
    width: 100%;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness .buttonRefreshAnalysisDetailsBusiness {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    color: var(--primary-color);
    border: 1px solid var(--white-dark-two);
    padding: 4px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness .buttonEditPersonOthersDetailsBusiness {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    color: var(--primary-color);
    border: 1px solid var(--white-dark-two);
    padding: 4px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness .buttonEditPersonOthersDetailsBusiness:hover {
    background-color: var(--white-dark);
}


.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness .subtitleOthersDetailsBusiness {
    font-size: 13px;
    color: #721ea9;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    display: block;
    
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness {
    margin: 8px 0;
}


.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .containerInfoServiceDetailsBusiness {
    display: flex;
}
.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .containerInfoServiceDetailsBusiness.containerInfoServiceOnOverviewDetailsBusiness {
    margin-top: 30px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .hrOtherDetailsBusiness {
    border-color: var(--color-focus);
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .containerInfoServiceDetailsBusiness .badgeStatusEnvelopeDetailsBusiness {
    background-color: #747678;
    min-width: calc(50% - 20px);
    height: 25px;
    padding: 0 10px;
    display: flex;
    margin-top: 10px;
    max-width: 50%;
    -webkit-user-select: none;
    user-select: none;
    margin-right: 20px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    justify-content: flex-start;
    align-items: center;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .containerInfoServiceDetailsBusiness .analysisBadgeStatusEnvelopeDetailsBusiness {
    background-color: var(--color-limon);
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .containerInfoServiceDetailsBusiness .primaryBadgeStatusEnvelopeDetailsBusiness {
    background-color: var(--color-information);
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .containerInfoServiceDetailsBusiness .warningBadgeStatusEnvelopeDetailsBusiness {
    background-color: var(--color-warning);
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .containerInfoServiceDetailsBusiness .successBadgeStatusEnvelopeDetailsBusiness {
    background-color: var(--color-success);
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .containerInfoServiceDetailsBusiness .labelServiceIdDetailsBusiness {
    width: 135px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .containerInfoServiceDetailsBusiness .labelServiceIdDetailsBusiness .idServiceDetailsBusiness, .detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .labelServiceIdDetailsBusiness .idServiceDetailsBusiness {
    font-weight: 600;
    color: #364855;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colOne {
    padding-right: 10px;
    display: flex;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colOne .avatarClientOthersDetailsBusiness {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--color-focus);
    fill: var(--color-focus);
    border: 2px solid rgba(38,41,44,.64);
    box-sizing: border-box;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colOne span {
    font: 400 13px/16px Source Sans Pro,sans-serif;
    font-weight: 600;
    color: rgba(38,41,44,.64);
    text-align: right;
    word-wrap: break-word;
    margin: 2px 0;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo {
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo span {
    overflow-wrap: anywhere;
    color: var(--primary-color);
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
    margin-left: 10px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo span.nameClientBodyOthersDetailsBusiness{
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo span.phoneClientBodyOthersDetailsBusiness {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo .buttonWhatsappBodyOthersDetailsBusiness {
    color: var(--color-success);
    background-color: transparent;
    height: initial;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness span.minumus {
    color: #747678;
    font-size: 13px;
    line-height: 9.2px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness.general {
    margin-top: 15px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .progressOthersDetailsBusiness {
    width: 100%;
    display: block;
    content:  " ";
    background-color: var(--color-success);
    border-radius: 120px;
    height: 16px;
    margin-bottom: 10px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .progressOthersDetailsBusiness:hover {
    -webkit-transform: scaleX(1.2);
            transform: scaleX(1.2);
    -webkit-transform: scaleY(1.3);
            transform: scaleY(1.3);
}

.detailsBusiness .bodyDetailsBusiness .boxActivitiesBodyDetailsBusiness {
    padding: 0;
}

.detailsBusiness span.businessStatus {
    height: 32px;
    font-weight: 700;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 20px;
    color: var(--white-light);
}

.detailsBusiness span.businessStatus.danger {
    background-color: var(--color-error);
}

.detailsBusiness span.businessStatus.success {
    background-color: var(--color-success);
}

.detailsBusiness .bodyDetailsBusiness .menusActivitiesDetailsBusiness {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}

.detailsBusiness .bodyDetailsBusiness .boxActivitiesHistoricBodyDetailsBusiness {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    /* margin-bottom: 200px;
    min-height: 1000px; */
}

.detailsBusiness .bodyDetailsBusiness .boxActivitiesHistoricBodyDetailsBusiness .statusActivitiesDetailsBusiness {
    background-color: #747678;
    border-radius: 180px;
    color: white;
    padding: 1px 20px;
    margin: 10px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
}


.detailsBusiness .buttonLinkDetailsBusiness {
    background-color: #FFF;
    color: #317AE2;
    font-weight: 400;
    padding: 0;
    font-size: 16px;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: flow;
}

.detailsBusiness .buttonActivitiesBusinessRecovered {
    font-size: 16px;
    background-color: var(--color-focus);
}

.detailsBusiness .titleActivitiesBusinessRecovered {
    font-size: 13px;
    color: #721ea9;
    font-weight: 600;
    text-transform: uppercase;
}

/* ends styles body */
/* ends styles body */
/* ends styles body */


@media (min-width: 767px) and (max-width: 1024px)
{
    .detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .modalEditStampDetailsBusiness {
        position: fixed;
        top: 30px;
        left: 60px;
    }
}

@media (max-width: 425px)
{
    .detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness {
        flex-direction: column;        
    }

    .detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness::before {
        display: none;
    }
}
.itemHistoricActivity {
    background-color: transparent;
    width: 100%;
    min-height: 110px;
}

.itemHistoricActivity.fileActivity {
    height: auto;
    min-height: auto;
}

.itemHistoricActivity.fileActivity .fileActivityColumn + .fileActivityColumn {
    border-top: 1px solid var(--white-dark-two) !important;
}

/* start all style in progress */
/* start all style in progress */
/* start all style in progress */
.itemHistoricActivity .lineProgressItemHistoricActivity {
    height: 100%;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
    padding-top: 5px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity .iconProgressTypeItemHistoricActivity {
    color: var(--dark-light);
    width: 36px;
    height: 36px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: absolute;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity .lineProgressTypeItemHistoricActivity {
    width: 2px;
    height: 100%;
    padding-top: 5px;
    content: " ";
    display: block;
    border: none;
    border: 2px solid transparent;
    border-left: 2px solid var(--white-dark-two);
    border-style: dashed;
    margin-left: 16px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity {
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: flex-start;
    justify-content: center;
    padding-left: 15px;
    border-radius: 5px;
    border: 1px solid var(--white-dark-two);
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 10px;
    position: relative;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .buttonMoreActionsItemHistoricActivity {
    position: absolute;
    right: 15px;
    top: 0;
    
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .buttonMoreActionsItemHistoricActivity:hover {
    background-color: var(--white-dark);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity {
    position: absolute;
    top: 40px;
    right: 5px;
    z-index: 800;
    width: 250px;
    min-height: 140px;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0.1px solid var(--white-dark-two);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity::after {
    width: 18px;
    height: 18px;
    background-color: var(--white-light);
    display: block;
    content: " ";
    position: absolute;
    top: -9px;
    right: 10px;
    z-index: 700;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    border: none;
    border-left: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    /* box-shadow: 0px 0px 7px 1px var(--white-dark-two); */
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity .buttonMoreActionsEditActivity {
    border: none;
    font-size: 15px;
    background-color: transparent;
    color: var(--dark-dark);
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    font-weight: 400;
    padding: 8px 20px;
    width: 100%;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity .buttonMoreActionsEditActivity:hover {
    padding: 0;
    padding: 8px 20px;
    background-color: var(--color-focus);
    color: var(--white-light);
    transition: none;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity::before {
    display: block;
    width: 16px;
    height: 16px;
    content: " ";
    background-color: var(--white-light);
    position: absolute;
    top: 10px;
    left: -8px;
    border: 1px solid var(--white-dark-two);
    border-right: none;
    border-top: none;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .checkFinishedItemHistoricActivity {
    width: initial;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ckeckFinishedItemHistoricActivity {
    position: relative;
    
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ckeckFinishedItemHistoricActivity:hover .helpCheckFinishedItemHistoricActivity {
    display: block;

}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ckeckFinishedItemHistoricActivity .helpCheckFinishedItemHistoricActivity {
    position: absolute;
    top: -40px;
    left: 0;
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    min-width: 140px;
    z-index: 600;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: none;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .checkFinishedItemHistoricActivity .squareCheck {
    border-radius: 50%;
    
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .checkFinishedItemHistoricActivity .squareCheck:hover {
    border-color: var(--color-success);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .dateStartTaskItemHistoricActivity {
    color: var(--dark-dark-three);
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ownerTaskItemHistoricActivity {
    margin-right: 10px;
    color: var(--dark-dark-three);
    
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity {
    color: var(--primary-color);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-weight: 300;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity .iconNameTaskItemHistoricActivity {
    font-size: 10px;
    margin-right: 5px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .buttonArchivedFileItemHistoricActivity {
    font-size: 20px;
    color: var(--dark-light);
    background-color: transparent;
    position: absolute;
    bottom: 0px;
    right: 10px;
}

/* ends all style in progress */
/* ends all style in progress */
/* ends all style in progress */


/* start all styles finished */
/* start all styles finished */
/* start all styles finished */

.itemHistoricActivity .lineFinishedItemHistoricActivity {
    height: 100%;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity .iconFinishedTypeItemHistoricActivity {
    color: var(--dark-light);
    width: 24px;
    height: 24px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: absolute;
    margin-left: 5px;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity .lineFinishedTypeItemHistoricActivity {
    width: 2px;
    height: 100%;
    content: " ";
    display: block;
    border: none;
    border: 2px solid transparent;
    border-left: 2px solid var(--white-dark-two);
    margin-left: 16px;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity .lineFinishedTypeItemHistoricActivity.disabled {
    border: none;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity {
    background-color: transparent;
    flex-direction: column;
    border-radius: 5px;
    position: relative;
}

.itemHistoricActivity .columnDetailsItemHistoricActivity .fileIcon {
    font-size: 20px;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .dateStartTaskItemHistoricActivity {
    color: var(--dark-dark-three);
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .ownerTaskItemHistoricActivity {
    margin-right: 10px;
    color: var(--dark-dark-three);
    
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity {
    color: var(--primary-color);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-weight: 300;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity .iconNameTaskItemHistoricActivity {
    font-size: 10px;
    margin-right: 5px;
}

.itemHistoricActivity .activityAnnotations {
    display: flex;
    width: calc(100% + 15px);
    background-color: #FFFCDC;
    padding: 10px 0;
    border-top: 1px solid var(--white-dark-two);
    margin-left: -15px;
    padding-left: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: 5px;
}

.itemHistoricActivity .iconCreatorSeparator {
    font-size: 3px;
    margin: 0 10px;
}

.itemHistoricActivityLoss .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity{
    border-color: var(--color-error);
}

.itemHistoricActivityLoss .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity .iconProgressTypeItemHistoricActivity{
    background-color: var(--color-error);
    color: var(--white-light);
    border-color: var(--white-light);
}

.itemHistoricActivityLoss .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity::before {
    border-color: var(--color-error);
}

.itemHistoricActivityGain .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity{
    border-color: var(--color-success);
}

.itemHistoricActivityGain .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity .iconProgressTypeItemHistoricActivity{
    background-color: var(--color-success);
    color: var(--white-light);
    border-color: var(--white-light);
}

.itemHistoricActivityGain .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity::before {
    border-color: var(--color-success);
}

/* ends all styles finished */
/* ends all styles finished */
/* ends all styles finished */
.modalEditStamp {
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    position: relative;
    /* overflow: hidden; */
}

.modalEditStamp .headerModalEditStamp {
    padding: 15px;
    border-bottom: 1px solid var(--white-dark-two);
}

.modalEditStamp .headerModalEditStamp .titleModalEditStamp {
    color: var(--dark-light);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.modalEditStamp .bodyModalEditStamp {
    padding: 5px 0px;
}

.modalEditStamp .bodyModalEditStamp.active {
    padding: 15px;
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp {
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
    padding: 5px;
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp .buttonChooseColorModalEditStamp {
    border-radius: 4px;
    width: 25px;
    height: 25px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp .buttonChooseColorModalEditStamp:hover {
    opacity: 0.8;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp {
    padding: 0;
    margin: 0;
    margin-top: 8px;
    padding: 8px 20px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .buttonEditStampModalEditStamp {
    color: var(--white-light);
    font-size: 12px;
    cursor: pointer;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .iconCheckedStampModalEditStamp {
    color: var(--color-focus);
    font-size: 12px;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp:hover {
    background-color: var(--color-focus);
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp {
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--dark-dark-three);
    font-weight: 600;
    text-transform: uppercase;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.none {
    border: 1px solid var(--dark-dark-three);
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp:hover .stampModelEditStamp.none {
    border-color: var(--white-light);
    color: var(--white-light);
}   

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.warning {
    color: black;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.success,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.purple,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.inactive,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.focus,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.error,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.orange,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.limon,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.strawberry,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.banana,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.watermelon {
    color: var(--white-light);
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp .buttonChooseColorModalEditStamp .iconCheckedColorModalEditStamp {
    font-size: 10px;
    color: black;
}

.modalEditStamp .footerModalEditStamp { 
    border-top: 1px solid var(--white-dark-two);
    background-color: var(--white-light);
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.modalEditStamp .footerModalEditStamp.active { 
    padding: 8px 15px;
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 80px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.modalEditStamp .footerModalEditStamp .buttonPlusModalEditStamp {
    color: var(--color-focus);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: transparent;
    border-radius: 2px;
    border: none;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: left;
}

.modalEditStamp .footerModalEditStamp .buttonPlusModalEditStamp:hover {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.serviceForm {
    position: relative;
    top: -23px;
    right: 32px;
}

.serviceForm .buttonContinueRegistrationServiceForm {
    background: var(--color-warning);
    font-size: 18px;
    border-radius: 2px;
    margin: 0;
    height: 50px;
    min-width: 285px;
    max-width: 285px;
    padding: 4px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
    border-radius: 2px;
}

.serviceForm .buttonIconSpacingServiceForm {
    margin-right: .5rem !important;
}
.inputGroupComponent {
    width: 100%;
}

.inputGroupComponent .labelInputGroup {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.inputGroupComponent .inputInputGroup {
    position: relative;
    display: flex;
    align-items: center;
}

.inputGroupComponent .inputInputGroup .buttonClearInputGroup {
    background-color: transparent;
    width: inherit;
    height: inherit;
    color: #000;
    font-size: 15px;
    position: absolute;
    right: 10px;
}

.inputGroupComponent .inputInputGroup .form-control {
    width: 100%;
    border-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    box-sizing: border-box;
    display: block;
    padding: 4px 32px 4px 8px;
    height: 32px;
    font: inherit;
    color: #26292c;
    transition: padding 75ms ease-in-out;
}

.inputGroupComponent .inputInputGroup .buttonInputGroup {
    display: flex !important;
    height: 32px !important;
    max-height: 32px !important;
    justify-content: center !important;
    align-items: center !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #cbcccd;
}

.inputGroupComponent .inputInputGroup .form-control.error {
    border: 1px solid var(--color-error);
}

.inputGroupComponent .inputInputGroup .form-control:focus {
    border: 1px solid #317ae2;
    box-shadow: inset 0 1px 2px rgb(49 122 226 / 8%);
    outline: none;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup {
    width: 32px;
    height: 32px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 6px;
    bottom: 0;
    right: 0;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup .buttonInformationInputGroup {
    background-color: transparent;
    color: var(--color-warning);
    height: 100%;
    z-index: 600;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup .contentWarningSearchInputGroup {
    background-color: var(--white-light);
    min-width: 280px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 34px;
    z-index: 600;
    flex-direction: column;
    display: flex;
    box-shadow: 0px 1px 5px var(--dark-light);
    border: 1px solid #cbcccd;
    border-radius: 2px;
    overflow: scroll;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup .contentWarningSearchInputGroup .headerContentWarningInputGroup {
    display: flex;
    padding: 12px 16px;
    height: 48px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup .contentWarningSearchInputGroup .headerContentWarningInputGroup .title {
    font-size: 18px;
    color: #26292c;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup .contentWarningSearchInputGroup .headerContentWarningInputGroup .buttonCloseContentWarningInputGroup {
    background-color: transparent;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup .contentWarningSearchInputGroup .bodyContentWarningInputGroup {
    height: 100%;
    flex-direction: column;
    display: flex;
    padding: 10px;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup .contentWarningSearchInputGroup .bodyContentWarningInputGroup .notice {
    border-bottom: 1px solid var(--white-dark-two);
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    vertical-align: middle;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup .contentWarningSearchInputGroup .bodyContentWarningInputGroup .notice:hover {
    background-color: var(--white-dark);
    font-weight: 600;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup .contentWarningSearchInputGroup .bodyContentWarningInputGroup .notice .label {
    font-weight: 300;
}

.inputGroupComponent .inputInputGroup .moreInformationSearchInputGroup .contentWarningSearchInputGroup .bodyContentWarningInputGroup .notice .input {
    color: var(--color-focus);
}
.messageAttention {
    background-color: rgba(0, 0, 0, 0.342);
}

@-webkit-keyframes shake {
    0%, 100% { -webkit-transform: translateX(0); transform: translateX(0); }
    25% { -webkit-transform: translateX(-5px); transform: translateX(-5px); }
    50% { -webkit-transform: translateX(5px); transform: translateX(5px); }
    75% { -webkit-transform: translateX(-5px); transform: translateX(-5px); }
}

@keyframes shake {
    0%, 100% { -webkit-transform: translateX(0); transform: translateX(0); }
    25% { -webkit-transform: translateX(-5px); transform: translateX(-5px); }
    50% { -webkit-transform: translateX(5px); transform: translateX(5px); }
    75% { -webkit-transform: translateX(-5px); transform: translateX(-5px); }
}

.messageAttention.animate .content {
    -webkit-animation: shake 0.5s ease-in-out;
            animation: shake 0.5s ease-in-out;
    /* box-shadow: 0px 0px 8px 5px #ffc107;
    border: 2px solid #ffc107; */
}

.messageAttention .buttonMessageAttention {
    width: 100px;
    font-weight: 400;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
    margin: 0;
}

.messageAttention .sendMessageAttention{
    max-height: 32px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: var(--color-warning);
}

.messageAttention .includeMessageAttention{
    min-height: 60px;
    width: 200px;
    font-size: 20px;
    margin-bottom: 15px;
    background-color: var(--color-success);
}

.messageAttention .onConfirm {
    width: 100px;
    font-weight: 400;
    height: 40px;
    font-size: 14px;
    margin: 0;
    background-color: var(--color-success);
}


.modal-content { /* bootstrap class */
    background-color: transparent;
    border: none;
}

.messageAttention .content {
    border-radius: 20px;
    background-color: var(--white-light);
    overflow: hidden;
}

/* Start styling header */
/* Start styling header */
/* Start styling header */
.messageAttention .content .header {
    border-bottom: 0.5px solid #e0e0e0;
    display: flex;
    box-shadow: none;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 24px;
}

.messageAttention .content .header .status{
    font-weight: 600;
    font-size: 16px;
}

.messageAttention .content .header .status.success{ color: #28a745 }

.messageAttention .content .header .status.error{ color: #dc3545 }

.messageAttention .content .header .status.warning{ color: #ffc107 }

.messageAttention .content .header .status.information{ color: #007bff }

.messageAttention .content .header .status .iconStatus{
    margin-right: 10px;
    font-size: 22x;
}

.messageAttention .content .header .close{
    border: 1px solid var(--dark-dark);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent!important;
}
.messageAttention .content .header .button .iconButton{
    color: var(--dark-dark);
}
/* Ends styling header */
/* Ends styling header */
/* Ends styling header */


/* Start styling body */
/* Start styling body */
/* Start styling body */
.messageAttention .content .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 24px 0;
}

.messageAttention .content .footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 14px 24px;
}

.messageAttention .content .body p {
   font-size: 16px;
   font-weight: 400;
   color: var(--dark-dark);
}
/* Ends styling body */
/* Ends styling body */
/* Ends styling body */
@-webkit-keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.75; }
    100% { opacity: 1; }
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.75; }
    100% { opacity: 1; }
}

@-webkit-keyframes pulseGreen {
    0% {
        box-shadow: 0 0 5px 2px rgba(0, 197, 99, 0.7);
    }
    50% {
        box-shadow: 0 0 10px 6px rgba(0, 197, 99, 0.3);
    }
    100% {
        box-shadow: 0 0 15px 7px rgba(0, 197, 99, 0.3);
    }
    100% {
        box-shadow: 0 0 5px 2px rgba(0, 197, 99, 0.7);
    }
}

@keyframes pulseGreen {
    0% {
        box-shadow: 0 0 5px 2px rgba(0, 197, 99, 0.7);
    }
    50% {
        box-shadow: 0 0 10px 6px rgba(0, 197, 99, 0.3);
    }
    100% {
        box-shadow: 0 0 15px 7px rgba(0, 197, 99, 0.3);
    }
    100% {
        box-shadow: 0 0 5px 2px rgba(0, 197, 99, 0.7);
    }
}


@-webkit-keyframes pulseYellow {
    0% {
        box-shadow: 0 0 5px 2px rgba(255, 193, 7, 0.7);
    }
    50% {
        box-shadow: 0 0 10px 6px rgba(255, 193, 7, 0.3);
    }
    100% {
        box-shadow: 0 0 15px 7px rgba(255, 193, 7, 0.3);
    }
    100% {
        box-shadow: 0 0 5px 2px rgba(255, 193, 7, 0.7);
    }
}


@keyframes pulseYellow {
    0% {
        box-shadow: 0 0 5px 2px rgba(255, 193, 7, 0.7);
    }
    50% {
        box-shadow: 0 0 10px 6px rgba(255, 193, 7, 0.3);
    }
    100% {
        box-shadow: 0 0 15px 7px rgba(255, 193, 7, 0.3);
    }
    100% {
        box-shadow: 0 0 5px 2px rgba(255, 193, 7, 0.7);
    }
}

.containerWizard {
	/* max-width: 600px; */
	margin: 0 auto;
	/* border: 2px solid red; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.containerWizard .titleWizard {
	color: #000;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #a8a8a8;
    padding: 12.5px 12.5px;
    margin: 15px 0;

}

.containerWizard .progressBarWizard {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 95%;
}

.containerWizard .progressStepWizard {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #e0e0e0;
    font-size: 14px;
    color: #fff;
}

.containerWizard .progressStepTitleWizard {
    margin-top: 10px;
}

.containerWizard .progressStepWizard .visited{
    background-color: var(--color-focus);
}

.visited .progressStepWizard {
    background-color: var(--color-focus);
}

.containerWizard .containerCurrentPageWizard {
    padding-bottom: 100px;
    width: 100%;
}

.containerWizard .containerCurrentPageWizard .buttonFileWizard {
    background-color: transparent;
    color: var(--dark-dark-three);    
    margin-right: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

/* .containerWizard .containerCurrentPageWizard .alertHasAnotherServiceWithEnvelope {
    width: 40.5%;
} */

.containerWizard .containerCurrentPageWizard .attachServicesButton {
    background: var(--color-focus);
	/* width: 180px; */
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerWizard .containerCurrentPageWizard .buttonFileWizard::after {
    content: "Arquivo";
    display: block;
    position: absolute;
    right: -70px;
}

.containerWizard .containerCurrentPageWizard .buttonFileWizard:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-light);
    border-radius: 50%;
}


.containerWizard .containerCurrentPageWizard .nameFileWizard {
    color: var(--dark-dark-three);    
    font-weight: 300;
}

.containerWizard .mandatoryInformationWizard{
    font-size: 12px;
    color: #747678;
    width: 100%;
    margin-bottom: 5px;
}

.containerWizard .boxStepWizard {
    display: flex;
    width: 165px;
    flex-direction: column;
    align-items: center;
}

.containerWizard .progressStepWizard.active {
    background-color: #4caf50;
}

.containerWizard .progressConnectorWizard {
    flex: 1 1;
    height: 2px;
    background-color: #e0e0e0;
    margin: 0 10px;
}

.containerWizard .progressConnectorWizard.active {
    background-color: #4caf50;
}

.containerWizard .stepWizard {
  	margin: 20px 0;
}

.containerWizard .navigationWizard {
    border-top: 1px solid #e5e5e5;
    margin-top: 1em;
    padding-top: 1em;
  	display: flex;
  	justify-content: space-between;

    position: fixed;
    bottom: 0;
    left: 0;
    max-width: inherit;
    width: 100%;
    background-color: #fff;
    padding: 20px 10px 10px 10px;
}

.containerWizard .navigationWizard .buttonWizard {
	background: var(--color-success);
	width: 180px;
	/* height: 46px; */
	font-size: 12px;
}

.containerWizard .navigationWizard .buttonWizard {
	background: var(--color-focus);
	width: 180px;
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerWizard .navigationWizard .buttonDisabledWizard {
	background: var(--dark-light);
}

.containerWizard .containerClientWizard .clientRegisteredWizard {
    background: #b2ddc2;
    width: 100%;
    padding: 1em;
    border-radius: 5px;
    color: #000;
    display: block;
}

.containerWizard .containerClientWizard .boxCheckDataClientServiceFormWizard {
    width: 100%;
    box-sizing: border-box;
    font: inherit;
    display: flex;
    height: 46px;
    margin-top: 1px;
    color: #26292c;
    border: solid 1.5px #9e9e9e;
    border-radius: 5px;
    background: none;
    padding: 1rem;
    font-size: 1rem;
    align-items: center;
}

.containerWizard .modernFieldInputBondsWizard .fieldInputGeneral {
    box-shadow: none !important;
    width: 100% !important;
    box-sizing: border-box !important;
    display: block !important;
    font: inherit !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}

.containerWizard .modernFieldInputGeneral .fieldInputGeneral {
    width: 100% !important;
    box-shadow: inset 0 1px 2px #eee !important;
    box-sizing: border-box !important;
    display: block !important;
    font: inherit !important;
    color: #26292c !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    background: none !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}

.containerWizard .inputGroupComponent .inputInputGroup .buttonInputGroup {
    display: flex !important;
    height: 46px !important;
    max-height: 46px !important;
    margin-left: -1px;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: solid 1.5px #9e9e9e !important;
}

.containerWizard .inputGroupComponent .inputInputGroup .form-control {
    width: 100%;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: solid 1.5px #9e9e9e !important;
    box-shadow: inset 0 1px 2px #eee;
    box-sizing: border-box;
    display: block;
    padding: 1rem;
    height: 46px;
    font: inherit;
    color: #26292c;
    transition: padding 75ms ease-in-out;
}

.containerWizard .modernSelectFieldGeneral .fieldSelect {
    width: 100% !important;
    box-shadow: inset 0 1px 2px #eee !important;
    box-sizing: border-box !important;
    font: inherit !important;
    color: #26292c !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    background: none !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}


.containerWizard .containerInstallmentsWizard .installmentDetailsWizard {
    box-shadow: #b7d5b212 0px 1px 1px 1px;
    border: solid 1px #b7d5b2;
    border-radius: 5px;
    padding: 0.75em;
    -webkit-user-select: none;
            user-select: none;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .statusShipmentWizard{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard img.statusShipmentWizard{
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .labelNumberInstallmentWizard {
    font-size: 16px;
    font-weight: 600;
    color: #6c757d;
    text-align: center;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .containerStatusPaymentWizard {
    height: 35px;
    min-width: 30px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .containerStatusPaymentWizard .statusPaymentWizard {
    font-size: 13px;
    font-weight: 600;
    margin-left: 10px;
    display: flex;
    min-width: 100px;
    padding: 4px 10px;
    border-radius: 6px;
    color: #fff;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    transition: all 0.3s ease-in-out;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .containerStatusPaymentWizard .statusPaymentWizard.paid {
    background-color: #00c563;
    -webkit-animation: blink 2.6s infinite alternate;
            animation: blink 2.6s infinite alternate;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .containerStatusPaymentWizard .statusPaymentWizard.pending {
    background-color: #FFC107;
    -webkit-animation: blink 2.6s infinite alternate;
            animation: blink 2.6s infinite alternate;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .containerStatusPaymentWizard .statusBallPaymentWizard {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    transition: box-shadow 0.3s ease-in-out;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .containerStatusPaymentWizard .statusBallPaymentWizard.paid {
    background-color: #00c563;
    -webkit-animation: pulseGreen 1.5s infinite;
            animation: pulseGreen 1.5s infinite;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .containerStatusPaymentWizard .statusBallPaymentWizard.pending {
    background-color: #FFC107;
    -webkit-animation: pulseYellow 1.5s infinite;
            animation: pulseYellow 1.5s infinite;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .containerInfoInstallmentsDetailsWizard
{
    min-width: 160px;
}

.containerWizard .containerInstallmentsWizard .installmentDetailsWizard .containerImageStatusShipimentWizard{
    width: 500px;
}

.containerWizard .buttonAddContactWizard {
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerWizard .containerSaveClientWizard {
    display: flex;
    justify-content: flex-end;
}

.containerWizard .buttonSaveClientWizard {
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerWizard .containerWitnessesWizard .buttonBondsWizard {
    margin-right: 6px;
    height: 46px;
    font-size: 15px;
    font-weight: 400;
    width: 180px;
    border-radius: 5px;
}

.containerWizard .containerWitnessesWizard .witnessTableWizard {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.containerWizard .containerWitnessesWizard .witnessTableHeaderWizard {
    background-color: #f0f0f0;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #ddd;
}

.containerWizard .containerWitnessesWizard .witnessWizard {
    background: #f9f9f9;
    border: 2px solid #e0e0e0;
    margin: 0 !important;
    cursor: pointer;
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: transform 0.2s, box-shadow 0.2s;
    transition: transform 0.2s, box-shadow 0.2s, -webkit-transform 0.2s;
}

.containerWizard .containerWitnessesWizard .witnessWizard:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.containerWizard .containerWitnessesWizard .witnessWizard .witnessTableNameWizard {
    flex: 2 1;
    text-align: left;
    font-weight: bold;
}

.containerWizard .containerWitnessesWizard .witnessWizard .witnessTableDetailsWizard {
    display: flex;
    justify-content: space-between;
}

.containerWizard .containerWitnessesWizard .witnessWizard .witnessTableDetailsWizard .witnessTableStatusWizard {
    font-weight: bold;
}

.containerWizard .containerWitnessesWizard .witnessWizard .witnessTableDetailsWizard .witnessTableTimestampWizard {
    font-size: 0.9em;
    color: #555;
}

.containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard {
    border-radius: 5px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: 553px;
}

.containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard  p, .containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard  b, .containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard  span{
    font-size: 16.5px !important;
}

.containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard  .labelsEnvelopeWizard {
    font-weight: 300;
}

.containerWizard .containerWitnessesWizard .containerEnvelopeLabelsWizard  .labelsDateEnvelopeWizard {
    font-size: 14px !important;
    font-weight: 400;
    font-style: italic;
}

.containerWizard .containerWitnessesWizard .buttonEnvelopeWizard {
    margin-right: 6px;
    height: 46px;
    font-weight: 400;
    font-size: 15px;
    width: 180px;
    border-radius: 5px;
}

.containerWizard .containerWitnessesWizard .buttonSendAttachmentEnvelopeWizard {
    width: 100%;
    background-color: var(--color-focus);
}

.containerWizard .containerWitnessesWizard .buttonRemoveWitness {
    background: var(--color-error);
    width: 30px;
    border-radius: 5px;
    font-size: 12px;
}

.containerWizard .containerWitnessesWizard .buttonValidationWitnessWizard {
    background: #fff;
    border: 1px solid #747678;
    color: #747678;
    width: 30px;
    border-radius: 5px;
    font-size: 12px;
}

.containerWizard .containerWitnessesWizard .containerDetailsEnvelopeWizard {
    border: 1px solid #ccc;
    padding: 1em;
    border-radius: 5px;
    margin-bottom: 1em;
}

.containerWizard .containerWitnessesWizard .containerDetailsEnvelopeWizard .titleDetailsEnvelopeWizard {
    /* background: #ccc; */
    padding: 4px;
    border-radius: 5px;
}

.containerWizard .containerWitnessesWizard .containerDetailsEnvelopeWizard .buttonUrlLinkSignaturesWizard {
    background: var(--color-focus);
    color: var(--white-light);
    padding: 5px;
    width: 4em;
    border: 0;
    text-align: center;
    border-radius: 5px;
}

.containerWizard .containerWitnessesWizard .containerDetailsEnvelopeWizard table td, .containerWizard .containerWitnessesWizard .containerDetailsEnvelopeWizard table th {
    text-align: -webkit-center;
    vertical-align: middle;
    font-weight: 400;
}

.containerWizard .containerWitnessesWizard .buttonRoundIconEnvelopeWizard {
    width: 2em;
    border-radius: 25px;
    text-align: center;
    background: #464646;
}

.containerWizard .containerWitnessesWizard .buttonTimerEnvelopeWizard {
    width: 90%;
    border-radius: 25px;
    text-align: center;
    background: #5328a7;
}
.containerWizard .containerWitnessesWizard .spanInfoSignedWizard {
    padding: 5px;
    border-radius: 5px;
    display: block;
    width: 120px;
    background: #ccc;
    font-weight: 400;
    color: #fff;
}

.containerWizard .containerWitnessesWizard .buttonTimerEnvelopeWizard i{
    margin-right: 5px;
}

.containerWizard .containerWitnessesWizard .timerEnvelopeWizard{
    border-radius: 5px;
    margin-right: 6px;
    width: 100px;
    color: #fff;
    background: #3b2070;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerWizard .containerWitnessesWizard .timerEnvelopeWizard i{
    margin-right: 5px;
}
.containerWizard .containerWitnessesWizard .timerEnvelopeWizard span{
    height: 21px;
    font-weight: 400;
}

.containerWizard .containerWitnessesWizard .buttonDeleteEnvelopeWizard {
    background: var(--color-error);
    width: 50px !important;
}

.containerWizard .containerWitnessesWizard .buttonErrorAlertEnvelopeWizard {
    background: var(--color-error) !important;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.containerWizard .containerWitnessesWizard .buttonStatusSignatoriesWizard {
    background: var(--color-warning);
}
.containerWizard .containerWitnessesWizard .buttonDetailsEnvelopeWizard {
    background: var(--color-focus);
}
.containerWizard .containerWitnessesWizard .buttonCreateEnvelopeWizard, .containerWizard .containerWitnessesWizard .buttonSendSignWizard {
    background: var(--color-success);
}

.containerWizard .containerWitnessesWizard .colorTextErrorDisableEnvelope {
    color: var(--color-error);
}

.containerWizard .containerWitnessesWizard .accordion-button {
    align-items: center;
    background-color: #fff;
    border: 1px solid #c7c7c7 !important;
    border-radius: 5px;
    display: flex;
    font-size: 1rem;
    overflow-anchor: none;
    padding: 1rem 1.25rem;
    position: relative;
    text-align: left;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    width: 100%;
}

.containerWizard .containerWitnessesWizard .accordion-button:after {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 16px;
    width: 16px;
    height: 16px;
    margin-left: auto;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.containerWizard .containerWitnessesWizard .accordion-button:not(.collapsed):after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.containerWizard .containerWitnessesWizard .badgeStatusDetailsEnvelopeSign {
    font-size: 16px;
    color: #fff;
    width: 100px;
    display: flex;
    font-weight: 400;
    width: 100%;
    height: 46px;
    align-items: center;
    justify-content: center;
}

.containerWizard .containerWitnessesWizard .buttonOnDetaisEnvelopeTitle {
    width: 160px;
}

.containerWizard .containerWitnessesWizard .buttonOnDetaisEnvelope {
    width: 160px;
    border-radius: 5px;
}

.containerWizard .containerWitnessesWizard .buttonOnDetailsEnvelopeSignatures {
    background-color: var(--color-warning);
}

.containerWizard .containerWitnessesWizard .buttonOnDetailsEnvelopeAttachments {
    background-color: var(--color-inactive);
}

.containerWizard .containerWitnessesWizard .badgeStatusDetailsEnvelopeSignNot {
    background-color: var(--color-warning);
}

.containerWizard .containerWitnessesWizard .badgeStatusDetailsEnvelopeSignYes {
    background-color: var(--color-success);
}

.containerWizard .containerDetailsFinanceWizard .buttonDebtsWizard {
    height: 46px;
    font-size: 15px;
    font-weight: 400;
    width: 180px;
    border-radius: 5px;
}

.containerWizard .containerDetailsFinanceWizard .buttonCancelDebtsWizard {
    background: var(--color-warning);
}

.containerWizard .containerDetailsFinanceWizard .buttonSaveDebtsWizard {
    background: var(--color-success);
}

.containerWizard .containerDetailsFinanceWizard .titleInstallmentsWizard {
    font-size: 20px;
    font-weight: 400;
    color: #4caf50;
}
.containerWizard .containerDetailsFinanceWizard .buttonPrintCarne {
	background: var(--color-focus);
	/* width: 180px; */
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerWizard .modernSelectFieldGeneral .fieldSelect {
    width: 100% !important;
    box-shadow: inset 0 1px 2px #eee !important;
    box-sizing: border-box !important;
    font: inherit !important;
    color: #26292c !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    background: none !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}

.containerWizard .modernSelectFieldGeneral .fieldSelectMulti {
    width: 100% !important;
    box-shadow: inset 0 1px 2px #eee !important;
    box-sizing: border-box !important;
    font: inherit !important;
    color: #26292c !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    background: none !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}
.containerDetailsEnvelope .fadeElementDetailsEnvelope {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.containerDetailsEnvelope .fadeInDetailsEnvelope {
  opacity: 1;
}

.containerDetailsEnvelope .fadeOutDetailsEnvelope {
  opacity: 0;
}

.containerDetailsEnvelope .skeletonLoaderDetailsEnvelope {
    opacity: 0.6;
    pointer-events: none;
}

.containerDetailsEnvelope .skeletonBoxDetailsEnvelope {
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    -webkit-animation: skeleton-loading 1.5s infinite linear;
            animation: skeleton-loading 1.5s infinite linear;
    border-radius: 4px;
}

@-webkit-keyframes skeleton-loading {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}

@keyframes skeleton-loading {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}

/* .containerNoAllowedEnvelopeDetailsEnvelope {
    padding: 18px;
    min-height: 118px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
} */

.containerModalValidationCodeDetialsEnvelope .modernFieldInputGeneral .fieldInputGeneral {
    width: 100% !important;
    box-shadow: inset 0 1px 2px #eee !important;
    box-sizing: border-box !important;
    display: block !important;
    font: inherit !important;
    color: #26292c !important;
    border: solid 1.5px #9e9e9e !important;
    border-radius: 5px !important;
    background: none !important;
    height: 46px !important;
    padding: 1rem !important;
    font-size: 1rem !important;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1) !important;
}

.containerNoAllowedEnvelopeDetailsEnvelope .headerNoAllowedEnvelopeDetailsEnvelope {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.containerNoAllowedEnvelopeDetailsEnvelope .alertHeadingDetailsEnvelope i {
    margin-right: 15px;
}

.containerNoAllowedEnvelopeDetailsEnvelope .headerNoAllowedEnvelopeDetailsEnvelope i {
    background-color: var(--color-warning);
    color: #fff;
    border-radius: 50%;
    height: 45px;
    margin-right: 15px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerRelationshipsDetailsEnvelope  .buttonBondsDetailsEnvelope {
    margin-right: 6px;
    height: 46px;
    font-size: 15px;
    font-weight: 400;
    width: 180px;
    border-radius: 5px;
}

.containerRelationshipsDetailsEnvelope  .witnessTableDetailsEnvelope {
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

.containerRelationshipsDetailsEnvelope .table td {
    vertical-align: middle;
}

.containerRelationshipsDetailsEnvelope  .witnessTableHeaderDetailsEnvelope {
    background-color: #f0f0f0;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #ddd;
}

.containerRelationshipsDetailsEnvelope .buttonValidationWitnessDetailsEnvelope{
    background: #fff;
    border: 1px solid #747678;
    color: #747678;
    min-width: 30px;
    max-width: 200px;
    display: flex;
    border-radius: 5px;
    font-size: 12px;
    justify-content: space-around;
    flex-direction: row-reverse;
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope {
    background: #f9f9f9;
    border: 2px solid #e0e0e0;
    margin: 0 !important;
    cursor: pointer;
    transition: box-shadow 0.2s, -webkit-transform 0.2s;
    transition: transform 0.2s, box-shadow 0.2s;
    transition: transform 0.2s, box-shadow 0.2s, -webkit-transform 0.2s;
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope .witnessTableNameWizard {
    flex: 2 1;
    text-align: left;
    font-weight: bold;
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope .witnessTableDetailsWizard {
    display: flex;
    justify-content: space-between;
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope .witnessTableDetailsWizard .witnessTableStatusWizard {
    font-weight: bold;
}

.containerRelationshipsDetailsEnvelope  .witnessDetailsEnvelope .witnessTableDetailsWizard .witnessTableTimestampWizard {
    font-size: 0.9em;
    color: #555;
}

.containerRelationshipsDetailsEnvelope .buttonRemoveWitnessDetailsEnvelope{
    background: var(--color-error);
    min-width: 30px;
    max-width: 200px;
    border-radius: 5px;
    font-size: 12px;
}

.containerRelationshipsDetailsEnvelope .accordion-button {
    align-items: center;
    background-color: #fff;
    border: 1px solid #c7c7c7 !important;
    border-radius: 5px;
    display: flex;
    font-size: 1rem;
    overflow-anchor: none;
    padding: 1rem 1.25rem;
    position: relative;
    text-align: left;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    width: 100%;
}

.containerRelationshipsDetailsEnvelope .accordion-button:after {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 16px;
    width: 16px;
    height: 16px;
    margin-left: auto;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.containerRelationshipsDetailsEnvelope .accordion-button:not(.collapsed):after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.containerRelationshipsDetailsEnvelope .buttonValidationWitnessWizard {
    background: #fff;
    border: 1px solid #747678;
    color: #747678;
    width: 30px;
    border-radius: 5px;
    font-size: 12px;
}


.containerRelationshipsDetailsEnvelope .table {
    border-radius: 5px;
    overflow: hidden;
    border-collapse: collapse;
    background: #f8f9fa;
}

.containerRelationshipsDetailsEnvelope .table thead {
    background: var(--color-focus);
    color: white;
}

.containerRelationshipsDetailsEnvelope .table th, .containerRelationshipsDetailsEnvelope .table td {
    padding: 12px;
    text-align: center;
}

.containerRelationshipsDetailsEnvelope .table tbody tr {
    transition: background 0.3s ease;
}

.containerRelationshipsDetailsEnvelope .table tbody tr:hover {
    background: #e9f5ff;
}

.containerRelationshipsDetailsEnvelope .table a {
    color: var(--color-focus);
    font-weight: 500;
    text-decoration: none;
}

.containerRelationshipsDetailsEnvelope .table a:hover {
    text-decoration: underline;
}

.containerDetailsEnvelope .buttonDetailsEnvelope, .containerModalValidationCodeDetialsEnvelope .buttonDetailsEnvelope {
    margin-right: 6px;
    height: 46px;
    font-weight: 400;
    font-size: 15px;
    width: 180px;
    border-radius: 5px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope p {
   margin: 0;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .noticeDetailsEnvelope {
   margin-bottom: 10px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .listMessagesDetailsEnvelope {
    display: flex;
    grid-gap: 15px;
    gap: 15px; /* Espaço entre os itens */
    padding-left: 0;
    margin: 0;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .listMessagesDetailsEnvelope li{
    margin-left: 20px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .alertDetailsEnvelope {
    display: flex;
    margin-bottom: 8px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .alertDetailsEnvelope .alertHeadingDetailsEnvelope i {
    margin-right: 5px;
    font-size: 18px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .alertDetailsEnvelope .alertHeadingDetailsEnvelope {
    margin: 0;
    margin-right: 5px;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .alertDetailsEnvelope .alertAllowHeadingDetailsEnvelope {
    margin-right: 10px !important;
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope .alertDetailsEnvelope .alertCategoryDetailsEnvelope {
    font-size: 12px;
    font-style: italic;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    min-height: 118px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.containerDetailsEnvelope .containerNoticeDetailsEnvelope.marginContract2DetailsEnvelope {
    margin-top: 40px;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope.containerEnvelopeCategory1DetailsEnvelope {
    border-left: 5px solid #005aff;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope.containerEnvelopeCategory2DetailsEnvelope {
    border-left: 5px solid #1dd99c;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope{
    display: flex;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .buttonDetailsEnvelope.buttonAttachmentDetailsEnvelope {
    background-color: var(--color-focus);
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .buttonDetailsEnvelope.buttonSignaturesDetailsEnvelope {
    background-color: var(--color-warning);
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .buttonDetailsEnvelope.disableButtonCreateEnvelope {
    background-color: var(--color-inactive);
    cursor: no-drop;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .buttonDetailsEnvelope.buttonDeleteDetailsEnvelope {
    background-color: var(--color-error);
    min-width: 30px;
    border-radius: 5px;
    font-size: 12px;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .timerDetailsEnvelope {
    border-radius: 5px;
    margin-right: 6px;
    width: 100px;
    color: #fff;
    height: 45px;
    background: #3b2070;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .timerDetailsEnvelope i {
    margin-right: 5px;
}
.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerButtonsDetailsEnvelope .timerDetailsEnvelope span {
    font-weight: 400;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope b {
    font-weight: 600;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope h4 {
    margin: 0;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope .labelPrincipalServicesDetailsEnvelope {
    color: var(--color-success);
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope .labelSecundaryServicesDetailsEnvelope {
    color: var(--color-error);
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope .dateDetailsEnvelope {
    font-size: 14px !important;
    font-weight: 400;
    margin: 0;
    font-style: italic;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope .signCompletedDetailsEnvelope {
    font-size: 14px !important;
    font-weight: 400;
    margin: 0;
    color: var(--color-success);
    font-style: italic;
}

.containerDetailsEnvelope .containerEnvelopeDetailsEnvelope .containerLabelsDetailsEnvelope .unsignCompletedDetailsEnvelope {
    font-size: 14px !important;
    font-weight: 400;
    margin: 0;
    color: var(--color-error);
    font-style: italic;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope.borderContainerAttachments1DetailsEnvelope {
    border-left: 5px solid #005aff;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope.borderContainerAttachments2DetailsEnvelope {
    border-left: 5px solid #1dd99c;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope h4 {
    display: flex;
    align-items: center;
    color: var(--color-focus);
    margin-bottom: 20px;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .headerTableAttachments {
    display: flex;
    justify-content: space-between;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .headerTableAttachments .buttonRefreshAttachmentsDetailsEnvelope {
    height: 32px;
    width: 20px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope h4 i {
    margin-right: 5px;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .fa-envelope-open-text {
    font-size: 1.5rem;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table {
    border-radius: 5px;
    overflow: hidden;
    border-collapse: collapse;
    background: #f8f9fa;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table thead {
    background: var(--color-focus);
    color: white;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table th, .containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table td {
    padding: 12px;
    text-align: center;
    min-width: 165px;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table tbody tr {
    transition: background 0.3s ease;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table tbody tr:hover {
    background: #e9f5ff;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table a {
    color: var(--color-focus);
    font-weight: 500;
    text-decoration: none;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .table a:hover {
    text-decoration: underline;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .boxButtonSendAttachmentEnvelopeDetailsEnvelope {
    height: 73px;
}

.containerDetailsEnvelope .containerAttachmentsDetailsEnvelope .buttonSendAttachmentEnvelopeDetailsEnvelope {
    width: 100%;
    background-color: var(--color-focus);
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope {
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .buttonSignaturesLinkDetailsEnvelope {
    background: var(--color-focus);
    color: var(--white-light);
    padding: 5px;
    width: 4em;
    border: 0;
    text-align: center;
    border-radius: 5px;
}
.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .spanInfoSignedDetailsEnvelope {
    padding: 5px;
    border-radius: 5px;
    display: block;
    width: 120px;
    background: #ccc;
    font-weight: 400;
    color: #fff;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .buttonSignaturesLinkDetailsEnvelope.buttonWhatsappSignaturesDetailsEnvelope {
    background: #28a745;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .spanInfoSignedDetailsEnvelope.classStatusAssNoSign {
    background: #dc3545;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .spanInfoSignedDetailsEnvelope.classStatusAssLinkOpened {
    background: #ef9602;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .spanInfoSignedDetailsEnvelope.classStatusAssSign {
    background: #28a745;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope h4 {
    display: flex;
    align-items: center;
    color: var(--color-focus);
    margin-bottom: 20px;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope h4 i {
    margin-right: 5px;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .fa-file-signature {
    font-size: 1.5rem;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table {
    border-radius: 5px;
    overflow: hidden;
    border-collapse: collapse;
    background: #f8f9fa;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table thead {
    background: var(--color-focus);
    color: white;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table th, .containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table td {
    padding: 12px;
    text-align: center;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .statusSignDetailsEnvelope{
    display: flex;
    justify-content: center;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table tbody tr {
    transition: background 0.3s ease;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table tbody tr:hover {
    background: #e9f5ff;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table a {
    color: var(--color-focus);
    font-weight: 500;
    text-decoration: none;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .table a:hover {
    text-decoration: underline;
}

.containerDetailsEnvelope .containerSignaturesDetailsEnvelope .boxButtonSendAttachmentEnvelopeDetailsEnvelope {
    height: 73px;
}

.containerFileUpload {
    /* width: fit-content; */
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
}

.headerFileUpload {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 1rem;
}

.fileUploadLogoCircle {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #e9e5ff;
  fill: #1cc972;
}

.fileUploadLogoCircle i{
  color: var(--color-success);
  font-size: 26px;
}

.fileUploadCloseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.fileUploadModalBody {
  padding: 1rem 1.5rem;
}

.fileUploadTitle {
  font-weight: 700 !important;
}

.fileUploadModalDescription {
  color: #6a6b76;
}

.fileUploadArea {
  margin-top: 1.25rem;
  background-color: transparent;
  padding: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dashed #6a6b76;
  border-radius: 5px;
}

.fileUploadArea .fileInfo p {
    font-size: 13px !important;
}

.fileUploadArea.dragOver {
    border: 2px solid #1cc972;
    background-color: rgba(28, 201, 114, 0.1);
  }
  
  .fileUploadArea:hover,
  .fileUploadArea:focus {
    cursor: pointer;
    border: 1px solid #6a6b76;
  }
  

.fileUploadArea:hover,
.fileUploadArea:focus {
  cursor: pointer;
  border: 1px solid #6a6b76;
}

.fileUploadAreaIcon {
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  fill: #1cc972;
}

.fileUploadAreaIcon i{
  color: var(--color-success);
  font-size: 36px;
}

.fileUploadAreaTitle {
  margin-top: 1rem;
  display: block;
  font-weight: 700;
  color: #0d0f21;
}

.fileUploadAreaDescription {
  display: block;
  color: #6a6b76;
}

.fileUploadAreaDescription strong {
  color: #1cc972;
  font-weight: 700;
}

.fileUploadModalFooter {
  padding: 1rem 1.5rem 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.containerFooterFileUpload {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
}

.containerFooterFileUpload .buttonFileUpload {
    width: 180px;
    border-radius: 5px;
    letter-spacing: 0.05rem;
    padding: 1.5em;
    font-size: 15px;
    margin: 0;
    height: 32px;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro, sans-serif;
}

.containerFooterFileUpload .buttonCancelFileUpload {
    background-color: var(--color-warning);
}

.box {
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    margin-bottom: 10px;
    transition: box-shadow .1s linear;
    background: #fff;
    font: 400 15px/20px Source Sans Pro,sans-serif;
    color: var(--primary-color);
    z-index: 800;
}

.boxWithoutCollapse {
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    margin-bottom: 24px;
    transition: box-shadow .1s linear;
    background: #fff;
    font: 400 15px/20px Source Sans Pro,sans-serif;
    color: var(--primary-color);
    padding: 20px;
    z-index: 800;
}

.box .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.box .titleBox {
    font-weight: 600;
    font-size: 16px;
    color: #4c575e;
}

.box .collapseIconBox {
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.box .contentBox {
    max-height: 2000px;
    width: 100%;
    /* overflow: hidden; */
    transition: max-height 0.3s ease, padding 0.3s ease;
}
.box .spacingToContentBox {
    padding: 20px;
}

.box .contentBox.collapsed {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
}
.advancedBusiness {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.advancedBusiness .warningAdvancedBusiness {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.advancedBusiness .containerHeaderAdvancedBusiness {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.advancedBusiness .containerHeaderAdvancedBusiness .buttonNavigationHeaderAdvancedBusiness {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;
    min-width: 47px;
    padding: 4px;
    vertical-align: top;
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.advancedBusiness .containerHeaderAdvancedBusiness .buttonNavigationHeaderAdvancedBusiness:hover {
    background-color: var(--white-dark);
}

.advancedBusiness .containerHeaderAdvancedBusiness .buttonNavigationHeaderAdvancedBusiness:first-of-type {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.advancedBusiness .containerHeaderAdvancedBusiness .buttonNavigationHeaderAdvancedBusiness.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.advancedBusiness .containerListAdvancedBusiness {
    height: calc(100vh - 56px - 65px);
}

.advancedBusiness .containerHeaderAdvancedBusiness .buttonHeaderAdvancedBusiness {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.advancedBusiness .buttonApplyFiltersAdvancedBusiness {
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
}

.advancedBusiness .advancedBusinessLoading {
    transition: all ease-in .5s;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.menuRight {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
    display: block;
    background: #fff;
    z-index: 999;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);

    padding: 0 28px;
    margin-right: -392px;

    transition: margin ease .5s;
}

.menuRight.open {
    margin-right: 0;
}

.menuRight .menuRightHeader {
    height: 64px;
}

.menuRight .menuRightHeader h4 {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.menuRight .buttonCloseMenuRight {
    color: var(--color-error);
    background: transparent;
    border: none;
    font-size: 24px;
}

.menuRight .buttonToggleMenuRight {
    position: absolute;
    left: -14px;
    top: 50%;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    font-size: 12px;
    background: #fff;
    box-shadow: -4px 0px 12px -5px var(--white-dark-two);
    border: none;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    z-index: 9999;
}

.menuRight .menuRightContent {
    height: calc(100vh - 64px);
    overflow: scroll;
    padding-bottom: 24px;
}
.tableEditAdvancedBusiness {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditAdvancedBusiness .helpTableEditAdvancedBusiness {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditAdvancedBusiness table, tr, th, td {
    margin: 0;
}

.tableEditAdvancedBusiness table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditAdvancedBusiness table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditAdvancedBusiness table td.center {
    text-align: center;
}

.tableEditAdvancedBusiness table td.left {
    text-align: left;
}

.tableEditAdvancedBusiness table td.right {
    text-align: right;
}

.tableEditAdvancedBusiness table tr {
    height: 32px;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
    padding-right: 10px;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td.minimus {
    width: 90px !important;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td.minimus45 {
    width: 45px !important;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td.minimus60 {
    width: 60px !important;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td.minimus75 {
    width: 75px !important;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td.minimus80 {
    width: 80px !important;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td.minimus100 {
    width: 100px !important;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td.minimus120 {
    width: 120px !important;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td.minimus140 {
    width: 140px !important;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td.minimus160 {
    width: 160px !important;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td.minimus180 {
    width: 180px !important;
}

.tableEditAdvancedBusiness .theadTableEditAdvancedBusiness td:last-of-type {
    border-right: none;
}

.tableEditAdvancedBusiness .tbodyTableEditAdvancedBusiness:hover {
    background-color: var(--white-dark);
}

.tableEditAdvancedBusiness .tbodyTableEditAdvancedBusiness:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditAdvancedBusiness .tbodyTableEditAdvancedBusiness td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    border: 1px solid #eee;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
}

.tableEditAdvancedBusiness .tbodyTableEditAdvancedBusiness .anchorOpenBusinessInNewWindow {
    text-decoration: underline !important;
    padding: 0;
    margin: 0;
    color: var(--color-focus);
}

.tableEditAdvancedBusiness .tbodyTableEditAdvancedBusiness .iconStatusAdvancedBusiness {
    font-size: 16px;
    color: var(--color---primary-color);
}
.activities {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.activities .warningActivities {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.activities .containerNavigationHeaderActivities {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities { 
    display: flex;
    margin-bottom: 20px;
    position: relative;
    height: 30px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .dropIntervalsHeaderActivities {
    position: absolute;
    width: 550px;
    height: 140px;
    top: 35px;
    right: 10px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    z-index: 900;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;    
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyModalMoreFiltersBusiness {
   padding: 13px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities {
    padding: 5px 13px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities .colOne {
    padding-right: 2px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities .colTwo {
    padding-left: 2px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities {
    display: inline-flex;
    flex-direction: row;
    border: none;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities button {
    font-size: 14px;
    height: 30px;
    border-radius: 2px;
    margin-right: 2px;
    margin-left: 2px;
    padding: 2px 6px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities button.active {
    border: 1px solid var(--color-focus);
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities.types {
    justify-content: flex-start;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities.intervals {
    justify-content: flex-end;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities:hover {
    background-color: var(--white-dark);
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.activities .containerNavigationHeaderActivities .buttonPlusNavigationHeaderActivities {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.activities .containerNavigationHeaderActivities .buttonTrashNavigationHeaderActivities {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    margin-left: 8px;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities.one {
    margin-left: auto;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .iconFilterHeaderActivities {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities {
    width: 100%;
    border: none;
    cursor: pointer;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities .optionsSelectMulti {
    min-width: 200px;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities .fieldSelectMulti {
    border: none;
}

.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .iconfilterStatusHeaderActivities {
    font-size: 14px;
    color: var(--dark-dark);
}

.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .selectStatusHeaderActivities .textShowSelect,
.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .selectStatusHeaderActivities .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .selectStatusHeaderActivities .fieldSelect,
.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .selectStatusHeaderActivities .fieldSelectMulti
 {
    border: none;
    width: 100%;
}

.activities .containerNavigationHeaderActivities .filterStatusHeaderActivities .select.selectStatusHeaderActivities {
    width: 100%;
    cursor: pointer;
}

.activities .containerNavigationHeaderActivities .filterOwnersHeaderActivities {
    margin-right: 20px;
    min-width: 180px;    
    position: relative;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    cursor: pointer;
}

.activities .containerNavigationHeaderActivities .filterOwnersHeaderActivities .selectOwnersHeaderActivities .fieldSelectMulti {
    border: none;
}

.activities .containerListHeaderActivities {
    height: calc(100vh - 56px - 65px - 50px); /*56px of the Header component plus 65px of the .containerNavigationHeaderActivities plus 50px of the .containerTypesAndIntervalsNavigationHeaderActivities*/
}

@media (max-width: 1024px)
{
    .activities .containerNavigationHeaderActivities {
        flex-wrap: wrap;
        height: auto;
        padding: 5px;
        justify-content: center;
    }

    .activities .containerNavigationHeaderActivities .filterHeaderActivities,
    .activities .containerNavigationHeaderActivities .filterHeaderActivities.one {
        margin-left: 0;
    }

    .buttonNavigationHeaderActivities {
        display: none;
    }

    .activities .containerTypesAndIntervalsNavigationHeaderActivities {
        height: auto;
    }

    .activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities.types,
    .activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities.intervals {
        justify-content: center;
    }

    .activities .containerTypesAndIntervalsNavigationHeaderActivities .dropIntervalsHeaderActivities {
        width: initial;
        height: 200px;
    }

    .activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities .colOne,
    .activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities .colTwo {
        padding: 0;
    }

}
.tableEditActivities {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditActivities .helpTableEditActivities {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditActivities table, tr, th, td {
    margin: 0;
}

.tableEditActivities table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditActivities table .buttonCheckTableEditActivities {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditActivities table .buttonCheckTableEditActivities .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditActivities table .buttonCheckTableEditActivities.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditActivities table .buttonCheckTableEditActivities.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditActivities table .buttonFinishTableEditActivities {
    width: initial;
    padding-left: 10px;
    border: 1px solid #eee;
    border-right: none;
    border-bottom: none;
    border-left: none;
    height: 100%;
    width: 100%;
}

.tableEditActivities table .buttonFinishTableEditActivities {
    position: relative;
    
}

.tableEditActivities table .buttonFinishTableEditActivities .squareCheck {
    border-radius: 50%;
    
}

.tableEditActivities table .buttonFinishTableEditActivities .squareCheck:hover {
    border-color: var(--color-success);
}

.tableEditActivities table .buttonFinishTableEditActivities.active .squareCheck {
    border: 2px solid var(--color-success);
    background-color: var(--color-success);
}

.tableEditActivities table .buttonFinishTableEditActivities.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditActivities table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditActivities table tr {
    height: 32px;
}

.tableEditActivities .theadTableEditActivities td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditActivities .theadTableEditActivities td.minimus {
    width: 90px!important;
}

.tableEditActivities .theadTableEditActivities td.minimus45 {
    width: 45px!important;
}

.tableEditActivities .theadTableEditActivities td.minimus60 {
    width: 60px!important;
}

.tableEditActivities .theadTableEditActivities td.minimus75 {
    width: 75px!important;
}

.tableEditActivities .theadTableEditActivities td.minimus120 {
    width: 120px!important;
}

.tableEditActivities .theadTableEditActivities td.minimus180 {
    width: 180px!important;
}

.tableEditActivities .theadTableEditActivities td:last-of-type {
    border-right: none;
}

.tableEditActivities td.checkTableEditActivities {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditActivities td.finishTableEditActivities {
    text-align: center;
    vertical-align: middle;
    padding: 0;
    height: 25px!important;
}

.tableEditActivities .tbodyTableEditActivities:hover {
    background-color: var(--white-dark);
}

.tableEditActivities .tbodyTableEditActivities:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditActivities .tbodyTableEditActivities td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditActivities .table .tbodyTableEditActivities td.checkTableEditActivities { 
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditActivities .table .tbodyTableEditActivities td.finishTableEditActivities {
    border: 1px solid #eee;
    position: relative;
    align-items: center;
}

.tableEditActivities .tbodyTableEditActivities td:hover .buttonEditTableEditActivities {
    opacity: 1;
}

.tableEditActivities .tbodyTableEditActivities td:hover .editTableEditActivities {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditActivities .tbodyTableEditActivities td .stampEditActivities {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities .modalFieldEdit {
    z-index: 1000;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.error i.iconFieldEdit {
    color: var(--color-error)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.warning i.iconFieldEdit {
    color: var(--color-warning)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.success i.iconFieldEdit {
    color: var(--color-success)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.inactive i.iconFieldEdit {
    color: var(--color-inactive)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.justifyCenter {
    justify-content: center;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.inheritIconColor i {
    color: inherit;
}

.tableEditActivities .tbodyTableEditActivities .detailsTableEditActivities {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditActivities .tbodyTableEditActivities .detailsTableEditActivities button.button.buttonShowDetailsTableEditActivities { 
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditActivities .tbodyTableEditActivities .detailsTableEditActivities .messageDetailsTableEditActivities {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.tableEditActivities .tbodyTableEditActivities.finished .editTableEditActivities {
    text-decoration: line-through;

}
.tableEditActivities .tbodyTableEditActivities .acitiviesIconShowedup {
    cursor: pointer;
}
.owners {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.owners .containerNavigationHeaderOwners {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners:hover {
    background-color: var(--white-dark);
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.owners .containerNavigationHeaderOwners .buttonPlusNavigationHeaderOwners {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.owners .containerNavigationHeaderOwners .buttonTrashNavigationHeaderOwners {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .iconFilterFontsHeaderOwners {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .selectFontHeaderOwners {
    cursor: pointer;
    width: 100%;
    border: none;
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .selectFontHeaderOwners .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .selectFontHeaderOwners .fieldSelectMulti {
    border: none;
}

.owners .containerListHeaderOwners {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderLeads */
}
.tableEditOwners {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditOwners .helpTableEditOwners {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditOwners table, tr, th, td {
    margin: 0;
}

.tableEditOwners table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditOwners table .buttonCheckTableEditOwners {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditOwners table .buttonCheckTableEditOwners .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditOwners table .buttonCheckTableEditOwners.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditOwners table .buttonCheckTableEditOwners.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditOwners table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditOwners table tr {
    height: 32px;
}

.tableEditOwners .theadTableEditOwners td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditOwners .theadTableEditOwners td.minimus {
    width: 90px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus45 {
    width: 45px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus54 {
    width: 54px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus60 {
    width: 60px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus75 {
    width: 75px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus120 {
    width: 120px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus180 {
    width: 180px!important;
}

.tableEditOwners .theadTableEditOwners td.minimus200 {
    width: 200px!important;
}

.tableEditOwners .theadTableEditOwners td:last-of-type {
    border-right: none;
}

.tableEditOwners td.checkTableEditOwners {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditOwners .tbodyTableEditOwners:hover {
    background-color: var(--white-dark);
}

.tableEditOwners .tbodyTableEditOwners:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditOwners .tbodyTableEditOwners td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditOwners .table .tbodyTableEditOwners td.checkTableEditOwners {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditOwners .tbodyTableEditOwners td:hover .buttonEditTableEditOwners {
    opacity: 1;
}

.tableEditOwners .tbodyTableEditOwners td .editTableEditOwners.minimus500 .modalFieldEdit {
    max-width: 550px!important;
}

.tableEditOwners .tbodyTableEditOwners td:hover .editTableEditOwners {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditOwners .tbodyTableEditOwners td .editTableEditOwners.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditOwners .tbodyTableEditOwners td .stampEditOwners {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditOwners .tbodyTableEditOwners td .editTableEditOwners .modalFieldEdit {
    z-index: 1000;
}

.tableEditOwners .tbodyTableEditOwners td .buttonShowConfigTableEditOwners {
    background-color: transparent;
    width: 100%;
    font-size: 14px;
    color: var(--color-focus);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
}

.tableEditOwners .tbodyTableEditOwners td .buttonShowConfigTableEditOwners.error {
    color: var(--color-error);
}

.tableEditOwners .tbodyTableEditOwners td .companyEditOwners {
    position: absolute;
    z-index: 99;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 4px;
    border-radius: 2px;
    margin-right: 8px;
    text-align: center;
}
.detailsPermissions {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.detailsPermissions .warningDetailsPermissions {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.detailsPermissions .scrollDetailsPermissions {
    overflow: scroll;
    height: 100%;
}

.detailsPermissions .headerDetailsPermissions {
    margin-top: 20px;
}

.detailsPermissions .headerDetailsPermissions h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.detailsPermissions .headerDetailsPermissions h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}

.detailsPermissions .bodyDetailsPermissions  {
    margin-bottom: 250px;
    overflow: scroll;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions {
    min-width: 1000px;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineCollectionsDetailsPermissions .filterBodyDetailsPermissions {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    margin-left: auto;
    margin-right: auto;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineCollectionsDetailsPermissions .filterBodyDetailsPermissions .iconFilterBodyDetailsPermissions {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineCollectionsDetailsPermissions .filterBodyDetailsPermissions .selectFilterBodyDetailsPermissions {
    width: 100%;
    border: none;
    cursor: pointer;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineCollectionsDetailsPermissions .filterBodyDetailsPermissions .selectFilterBodyDetailsPermissions .optionsSelect {
    min-width: 200px;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineCollectionsDetailsPermissions .filterBodyDetailsPermissions .selectFilterBodyDetailsPermissions .fieldSelectMulti {
    border: none!important;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineCollectionsDetailsPermissions .filterBodyDetailsPermissions .selectFilterBodyDetailsPermissions .textShowSelect {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineCollectionsDetailsPermissions .filterBodyDetailsPermissions .selectFilterBodyDetailsPermissions .fieldSelect {
    border: none;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineTitleHeaderChecksConfigDetailsPermissions {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineTitleHeaderChecksConfigDetailsPermissions .columnTitleHeaderChecksConfigDetailsPermissions {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions {
    background-color: var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions {
    width: initial!important;
    margin-left: auto;
    margin-right: auto;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions .squareCheck {
    margin: 0;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions.active .squareCheck {
    border-color: var(--color-success);
    background-color: var(--color-success);
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions.active .iconCheck {
    color: var(--white-light);
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid var(--white-dark-two);
    border-top: none;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions {
    width: initial;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions .squareCheck {
    margin: 0;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions.active .squareCheck {
    border-color: var(--color-success);
    background-color: var(--color-success);
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: var(--dark-dark-three);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.detailsPermissions .bodyDetailsPermissions .boxBodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.permissions {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.permissions .warningPermissions {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.permissions .scrollPermissions {
    overflow: scroll;
    height: 100%;
}

.permissions .headerPermissions {
    margin-top: 20px;
}

.permissions .headerPermissions h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.permissions .headerPermissions h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}

.permissions .bodyPermissions {
    margin-bottom: 250px;
    overflow: scroll;
}

.permissions .bodyPermissions .boxBodyPermissions {
    min-width: 1000px;
}

.permissions .bodyPermissions .lineChosenPermissionGroupPermissions {
    padding-bottom: 20px;
}

.permissions .bodyPermissions .lineChosenPermissionGroupPermissions .columnChosenPermissionsGroupPermissions { 
    width: 100%;
}

.permissions .bodyPermissions .lineChosenPermissionGroupPermissions .columnChosenPermissionsGroupPermissions .buttonPlusPermissionsGroupPermisions {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
}

.permissions .bodyPermissions .lineTitleHeaderConfigPermissions {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.permissions .bodyPermissions .lineTitleHeaderConfigPermissions .columnTitleHeaderConfigPermissions {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.permissions .bodyPermissions .lineBodyConfigPermissions.pair {
    background-color: var(--white-dark-three);
}

.permissions .bodyPermissions .lineBodyConfigPermissions.final {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.permissions .bodyPermissions .lineBodyConfigPermissions {
    background-color: var(--white-light);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border: 1px solid var(--white-dark-two);
    border-top: none;
}

.permissions .bodyPermissions .lineBodyConfigPermissions .columnBodyConfigPermissions {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}

.permissions .bodyPermissions .lineBodyConfigPermissions .columnBodyConfigPermissions .buttonBodyConfiPermissions {
    background-color: transparent;
    color: var(--dark-dark-three);
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
}

.permissions .bodyPermissions .lineBodyConfigPermissions .columnBodyConfigPermissions .buttonBodyConfiPermissions:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-dark-two);
    transition: background-color 1s;
}
.personalPreferences {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.personalPreferences .scrollPersonalPreferences {
    overflow: scroll;
    height: 100%;
    margin-bottom: 300px;
}

.personalPreferences .headerPersonalPreferences {
    margin-top: 20px;
}

.personalPreferences .headerPersonalPreferences h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.personalPreferences .headerPersonalPreferences h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-focus);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.personalPreferences .bodyPersonalPreferences {
    margin-bottom: 300px;
}

.personalPreferences .bodyPersonalPreferences .chosenAvatarPersonPreferences .inputInputGeneral input {
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
    padding: 0px;    
}

.personalPreferences .bodyPersonalPreferences .lineTitleHeaderConfigPersonalPreferences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
}

.personalPreferences .bodyPersonalPreferences .lineTitleHeaderConfigPersonalPreferences .columnTitleHeaderConfigPersonalPreferences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    flex-direction: row;
    padding-left: 13px;
    padding-right: 13px;
    background-color: var(--dark-dark-three);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 100%;
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    padding-left: 13px;
    padding-right: 13px;
    background-color: var(--dark-dark-three);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100%;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    width: 100px!important;

}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.lineCheckRequirement {
    display: flex;
    flex-direction: row;
    min-width: 250px;
    padding: 8px 12px;
    margin: 5px;
}

.lineCheckRequirement.success {

}

.lineCheckRequirement .columnIconCheckRequirement {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}

.lineCheckRequirement .columnIconCheckRequirement .iconCheckRequirement {
    font-size: 14px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid var(--dark-light);
    color: var(--dark-light);
}

.lineCheckRequirement .columnIconCheckRequirement .iconCheckRequirement.success {
    color: var(--color-success)!important;
    border: 1px solid var(--color-success);
}

.lineCheckRequirement .columnIconCheckRequirement .iconCheckRequirement.error {
    color: var(--color-error)!important;
    border: 1px solid var(--color-error);
}

.lineCheckRequirement .columnIconCheckRequirement i.iconCheckRequirement {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    font-size: 10px;
    padding: 0;
    margin: 0;
}

.lineCheckRequirement .columnLabelCheckRequirement {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    color: var(--dark-light);
    padding-left: 10px;
    font-size: 14px;
}

.lineCheckRequirement .columnLabelCheckRequirement span.error {
    color: var(--color-error)!important;
}

.lineCheckRequirement .columnLabelCheckRequirement span.success {
    color: var(--color-success)!important;
}
.companyPreferences {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.companyPreferences .scrollCompanyPreferences {
    overflow: scroll;
    height: 100%;
    margin-bottom: 300px;
}

.companyPreferences .headerCompanyPreferences {
    margin-top: 20px;
}

.companyPreferences .headerCompanyPreferences h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.companyPreferences .headerCompanyPreferences h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-warning);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.companyPreferences .bodyCompanyPreferences {
    margin-bottom: 300px;
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences {
    justify-content: center;
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus {
    background-color: transparent;
    border-bottom: 2px solid transparent;
    font-weight: 400;
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus.active {
    border-bottom: 2px solid var(--color-focus);
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus:hover {
    border-bottom: 2px solid var(--white-dark-two);
    transition: border 1.5s;
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus.active:hover {
    border-bottom: 2px solid var(--color-focus);
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences {
    width: 100%;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences {
    width: 100%;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .subtitleBodyConfigCompanyPreferences {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .buttonPlusFinancialServicesConfigCompanyPreferences,
.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .buttonPlusReasonConfigCompanyPreferences {
    background-color: var(--color-success);
    color: var(--white-light);
    border-radius: 50%;
    margin-bottom: 10px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .editReasonConfigCompanyPreferences {   
    height: 150px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 900;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences { 
    position: absolute;
    top: 0;
    right: 0;
    width: 340px;
    z-index: 900;
    padding: 0;
    margin: 0;
    height: 245px;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    overflow: hidden;    
    margin-left: auto;
    margin-right: auto;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropHeaderEditFinancialServicesConfigCompanyPreferences {
    padding: 15px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropHeaderEditFinancialServicesConfigCompanyPreferences .titleDropEditCompanyPreferences { 
    color: var(--dark-light);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropBodyEditFinancialServicesConfigCompanyPreferences { 
    padding: 15px;
    padding-top: 0;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropBodyEditFinancialServicesConfigCompanyPreferences .inputFinancialServicesConfigCompanPreferences { 
    margin-bottom: 10px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences {
    border-top: 1px solid var(--white-dark-two);
    background-color: var( --white-dark);
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 0 15px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 80px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    /* width: 50px; */
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences {
    border-top: 1px solid #e5e5e5;
    padding: 5px 10px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences:hover {
    background-color: var(--white-dark);
    transition: background-color 0.8s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences:last-of-type {
    border-bottom: 1px solid #e5e5e5;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .nameReasonBodyConfigCompanyPreferences {
    color: var(--color-focus);
    cursor: pointer;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences .buttonReasonBodyConfigCompanyPreferences {
    font-size: 10px;
    width: initial;
    background-color: transparent;
    color: var(--color-focus);
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: none;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences:hover .buttonReasonBodyConfigCompanyPreferences {
    display: flex;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences .buttonReasonBodyConfigCompanyPreferences.negative {
    color: var(--color-error);
    position: relative;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences .buttonReasonBodyConfigCompanyPreferences.negative::before {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    content: " ";
    display: block;
    background-color: var(--primary-color);
    margin-left: 4px;
    margin-right: 4px;
}   

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    padding-left: 13px;
    padding-right: 13px;
    background-color: var(--dark-dark-three);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100%;
}

.companyPreferences .bodyCompanyPreferences .buttonCompanyPreferences {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    min-width: 120px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;

}

.companyPreferences .bodyCompanyPreferences .buttonCompanyPreferences:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.companyPreferences .bodyCompanyPreferences .buttonCompanyPreferences.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.companyPreferences .bodyCompanyPreferences .buttonCompanyPreferences.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditReasonsForLossConfigCompanyPreferences { 
    position: absolute;
    top: 0;
    right: 0;
    width: 340px;
    z-index: 900;
    padding: 0;
    margin: 0;
    height: 200px;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    overflow: hidden;    
    margin-left: auto;
    margin-right: auto;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditReasonsForLossConfigCompanyPreferences .dropHeaderEditReasonsForLossConfigCompanyPreferences {
    padding: 15px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditReasonsForLossConfigCompanyPreferences .dropHeaderEditReasonsForLossConfigCompanyPreferences .titleDropEditCompanyPreferences { 
    color: var(--dark-light);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditReasonsForLossConfigCompanyPreferences .dropBodyEditReasonsForLossConfigCompanyPreferences { 
    padding: 15px;
    padding-top: 0;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditReasonsForLossConfigCompanyPreferences .dropBodyEditReasonsForLossConfigCompanyPreferences .inputReasonsForLossConfigCompanPreferences { 
    margin-bottom: 10px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditReasonsForLossConfigCompanyPreferences .dropFooterEditReasonsForLossConfigCompanyPreferences {
    border-top: 1px solid var(--white-dark-two);
    background-color: var( --white-dark);
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 0 15px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditReasonsForLossConfigCompanyPreferences .dropFooterEditReasonsForLossConfigCompanyPreferences .buttonDropEditCompanyPreferences {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 80px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    /* width: 50px; */
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditReasonsForLossConfigCompanyPreferences .dropFooterEditReasonsForLossConfigCompanyPreferences .buttonDropEditCompanyPreferences:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditReasonsForLossConfigCompanyPreferences .dropFooterEditReasonsForLossConfigCompanyPreferences .buttonDropEditCompanyPreferences.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditReasonsForLossConfigCompanyPreferences .dropFooterEditReasonsForLossConfigCompanyPreferences .buttonDropEditCompanyPreferences.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.unitCompany {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.unitCompany .containerNavigationHeaderUnitCompany {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany:hover {
    background-color: var(--white-dark);
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonPlusNavigationHeaderUnitCompany {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonTrashNavigationHeaderUnitCompany {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}
.tableEditUnitCompany {
    background-color: var(--white-light);
}

.tableEditUnitCompany .helpTableEditUnitCompany {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditUnitCompany table, tr, th, td {
    margin: 0;
}

.tableEditUnitCompany table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditUnitCompany table .buttonCheckTableEditUnitCompany {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditUnitCompany table .buttonCheckTableEditUnitCompany .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditUnitCompany table .buttonCheckTableEditUnitCompany.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditUnitCompany table .buttonCheckTableEditUnitCompany.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditUnitCompany table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditUnitCompany table tr {
    height: 32px;
}

.tableEditUnitCompany .theadTableEditUnitCompany td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditUnitCompany .theadTableEditUnitCompany td:last-of-type {
    border-right: none;
}

.tableEditUnitCompany td.checkTableEditUnitCompany {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany:hover {
    background-color: var(--white-dark);
}

.tableEditUnitCompany .tbodyTableEditUnitCompany:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditUnitCompany .table .tbodyTableEditUnitCompany td.checkTableEditUnitCompany {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td:hover .buttonEditTableEditUnitCompany {
    opacity: 1;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td:hover .editTableEditUnitCompany {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td .editTableEditUnitCompany.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td .stampEditOwners {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditUnitCompany .tbodyTableEditUnitCompany td .editTableEditUnitCompany .modalFieldEdit {
    z-index: 1000;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td .buttonShowConfigTableEditUnitCompany {
    background-color: transparent;
    width: 100%;
    font-size: 14px;
    color: var(--color-focus);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
}
.dashboard {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.dashboard .flagTypeServiceDashboard {
    background-color: var(--color-focus);
    height: 40px;
    top: -40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: var(--white-light);
    font-size: 14px;
    width: 350px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-transform: uppercase;
}

.dashboard .flagTypeServiceDashboard.newServiceDashboard {
    margin-top: 20px;
}

.dashboard .warningNoContentDashboard {
    background-color: var(--color-warning);
    padding: 10px 14px;
    color: var(--white-light);
    font-size: 14px;
    border-radius: 5px;
}

.dashboard .headerDashboard {
    /* nothing */
}

.dashboard .containerBodyDashboard {
    position: relative;
    height: calc(100vh - 56px); /*56px by header component*/
    overflow: scroll;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard {
    margin: 20px 20px 0 20px;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
    color: var(--color-information);
}

.dashboard .containerBodyDashboard .lineHeaderDashboard h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-information);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard {
    width: 100%;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard {
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: inherit;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .inputFilterDashboard {
    width: 180px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .buttonSearchFilterDashboard {
    background-color: transparent!important;
    color: var(--color-success);
    width: 50px;
    height: 50px;
    font-size: 16px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .checkToggleIntervalFilterDashboard {
    width: 120px;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .checkToggleIntervalFilterDashboard .squareCheck {
    border-radius: 50%;
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .checkToggleIntervalFilterDashboard .squareCheck.checked {
    background-color: var(--color-information);
    border: var(--color-information);
}

.dashboard .containerBodyDashboard .lineHeaderDashboard .boxHeaderDashboard.filterDashboard .checkToggleIntervalFilterDashboard .squareCheck.checked .iconCheck {
    
    color: var(--color-information);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .table:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard {
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaSubtitlesChartPieDashboard {
    position: relative;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaSubtitlesChartPieDashboard .areSubtitleChartPieDashboard {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 300;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    width: 100%;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .labelsCardChartPieDashboard {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .labelsCardChartPieDashboard.italic {
    font-style: italic;
    font-weight: 300;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .labelsCardChartPieDashboard.italic .strong {
    font-weight: 600;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span::before {
    background-color: var(--color-success);
    width: 10px;
    height: 10px;
    border-radius: 2px;
    content: " ";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-right: 5px;
    font-size: 14px;
    color: var(--dark-dark);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.success::before {
    background-color: var(--color-success);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.error::before {
    background-color: var(--color-error);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.warning::before {
    background-color: var(--color-warning);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.information::before {
    background-color: var(--color-information);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .cardInfoLeftChart {
    min-height: 350px!important;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .barsChartCardInfoLeftDashboard {
    /* nothing here */
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .barsChartCardInfoLeftDashboard .columnInfoCardInfoLeftChart {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    vertical-align: middle;
    padding-bottom: 40px;
    padding-left: 10px;
    flex-direction: column;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .boxBodyDashboard {
    width: 100%;
    margin: 0px 20px 20px 20px;
    position: relative;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-right-radius: 5px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard {
    color: var(--white-light);
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding-right: 20px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard.left {
    justify-content: flex-start;
    padding-left: 20px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard .help {
    position: absolute;
    top: -40px;
    left: 0px;
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;
    display: none;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard:hover .help {
    display: block;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard.final {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard {
    background-color: var(--white-light);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border: 1px solid var(--white-dark-two);
    border-top: none;
    font-size: 12px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard .columnBodyDashboard {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard .columnBodyDashboard.left {
    padding-left: 20px;
    justify-content: flex-start;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard .columnBodyDashboard.right {
    justify-content: flex-end;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleFooterDashboard {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 48px;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleFooterDashboard .columnTitleFooterDashboard {
    color: var(--white-light);
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleFooterDashboard .columnTitleFooterDashboard.left {
    padding-left: 20px;
    justify-content: flex-start;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleFooterDashboard .columnTitleFooterDashboard.right {
    padding-right: 20px;
    justify-content: flex-end; 
}

@media (min-width: 768px) and (max-width: 1140px)
{
    .dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleHeaderDashboard .columnTitleHeaderDashboard,
    .dashboard .containerBodyDashboard .lineBodyDashboard .lineTitleFooterDashboard .columnTitleFooterDashboard,
    .dashboard .containerBodyDashboard .lineBodyDashboard .lineBodyDashboard .columnBodyDashboard
    {
        font-size: 10px;
    }
}

@media (min-width: 1023px)
{
    .dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaSubtitlesChartPieDashboard {
        position: absolute;
        bottom: 0;
    }
}
.cardChartPie {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

.cardChartPie .titleCardChartPie {
    width: 100%;
}

.cardChartPie .subtitleCardChartPie {
    width: 100%;
}

.cardChartPie .chartChartPie {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    position: relative;
}

.cardChartPie .chartChartPie .labelOverCardChartPie {
    /* start alignment */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 135px;
    height: 135px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* ends alignment */
    
    /* start general style */
    border: 2px solid var(--color-success);
    border-style: dotted!important;
    border-radius: 50%;
    font-weight: 600;
    font-size: 32px;
    color: var(--color-success);
    /* ends general style */
}

.cardChartPie .chartChartPie .labelOverCardChartPie.success {
    border: 2px solid var(--color-success);
    color: var(--color-success);
}

.cardChartPie .chartChartPie .labelOverCardChartPie.error {
    border: 2px solid var(--color-error);
    color: var(--color-error);
}

.cardChartPie .chartChartPie .labelOverCardChartPie.warning {
    border: 2px solid var(--color-warning);
    color: var(--color-warning);
}

.cardChartPie .chartChartPie .labelOverCardChartPie.information {
    border: 2px solid var(--color-information);
    color: var(--color-information);
}

.cardChartPie .chartChartPie .labelOverCardChartPie.purple {
    border: 2px solid var(--color-purple);
    color: var(--color-purple);
}
.cardInfoLeftChart {
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    box-sizing: border-box;
    position: relative;
}

.cardInfoLeftChart .iconCardInfoLeftChart {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart {
    width: 100%;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft {
    width: 100%;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4 {
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.focus::before {
    background-color: var(--color-focus);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.error::before {
    background-color: var(--color-error);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.warning::before {
    background-color: var(--color-warning);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.information::before {
    background-color: var(--color-information);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.purple::before {
    background-color: var(--color-purple);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft span {
    font-weight: 300;
    color: var(--dark-dark);
    display: inline-block;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnChartCardInfoLeftChart {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 5px;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnChartCardInfoLeftChart.multipleChildren {
    flex-direction: column;
}
.subtitleProgressLine {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    margin-bottom: 2px;
}

.subtitleProgressLine .labelSubtitleProgressLine {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 300;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
    margin-bottom: -12px;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine .barSubtitleProgressLine {
    width: 100%;
    height: 5px;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine .progressSubtitleProgressLine {
    color: #000;
    font-size: 22px;
    font-weight: 400;
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine .progressSubtitleProgressLine.divider {
    cursor: default;
    margin: 0;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine .progressSubtitleProgressLine.two {
    margin: 0;
}

.subtitleProgressLine .areaProgressSubtitleProgressLine .progressSubtitleProgressLine.withAction {
    cursor: pointer;
}
.stylizedMoneyOrNumber {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
    min-width: 120px;
    display: inline-flex;
    justify-content: space-between;
}
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button {
    color: black!important;
}

.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(1),
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(8),
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(15),
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(22),
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(29),
.inputDatePickerCustom .calendarDatePickerCustom .react-calendar__viewContainer .react-calendar__month-view button:nth-child(36) {
    color: var(--color-error)!important;
    font-weight: 700;
}

.react-date-picker__wrapper {
    /* nothing */
}
.performanceIndex {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    width: 100%;
}

.performanceIndex .namePerformaceIndex {
    color: var(--primary-color)!important;
    font-weight: 600!important;
}

.performanceIndex .areaPerformaceIndex {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    font-size: 22px;
    margin-left: 8px;
}

.performanceIndex .percentPerformaceIndex {
    color: var(--primary-color)!important;
    font-weight: 400!important;
}

.performanceIndex .percentPerformaceIndex.positive {
    color: var(--color-success)!important;
}

.performanceIndex .percentPerformaceIndex.negative {
    color: var(--color-error)!important;
}

.performanceIndex .percentPerformaceIndex.equal {
    color: var(--color-information)!important;
}

.performanceIndex .areaPerformaceIndex .iconPerformaceIndex {
    color: black;
}

.performanceIndex .areaPerformaceIndex .iconPerformaceIndex.positive {
    color: var(--color-success);
    margin-bottom: -14px;
}

.performanceIndex .areaPerformaceIndex .iconPerformaceIndex.negative {
    color: var(--color-error);
    margin-top: -14px;
}

.performanceIndex .areaPerformaceIndex .iconPerformaceIndex.equal {
    color: var(--color-information);
    font-size: 12px;
}
.commissionRules {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.commissionRules .containerNavigationHeaderCommissionRules {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonNavigationHeaderCommissionRules {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonNavigationHeaderCommissionRules:hover {
    background-color: var(--white-dark);
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonNavigationHeaderCommissionRules:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonNavigationHeaderCommissionRules:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonNavigationHeaderCommissionRules.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonPlusNavigationHeaderCommissionRules {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonTrashNavigationHeaderCommissionRules {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.commissionRules .containerNavigationHeaderCommissionRules .filterFontsHeaderCommissionRules {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionRules .containerNavigationHeaderCommissionRules .filterFontsHeaderCommissionRules .iconFilterFontsHeaderCommissionRules {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionRules .containerNavigationHeaderCommissionRules .filterFontsHeaderCommissionRules .selectFontHeaderCommissionRules {
    cursor: pointer;
    width: 100%;
    border: none;
}

.commissionRules .containerNavigationHeaderCommissionRules .filterFontsHeaderCommissionRules .selectFontHeaderCommissionRules .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionRules .containerNavigationHeaderCommissionRules .filterFontsHeaderCommissionRules .selectFontHeaderCommissionRules .fieldSelectMulti {
    border: none;
}

.commissionRules .containerNavigationHeaderCommissionRules .filterStatusHeaderCommissionRules {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionRules .containerNavigationHeaderCommissionRules .filterStatusHeaderCommissionRules .inputFilterCommissionRules .fieldInputGeneral {
    border: none!important;
}

.commissionRules .containerNavigationHeaderCommissionRules .filterStatusHeaderCommissionRules .iconfilterStatusHeaderCommissionRules {
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionRules .containerNavigationHeaderCommissionRules .filterStatusHeaderCommissionRules .selectStatusHeaderCommissionRules {
    cursor: pointer;
    width: 100%;
    border: none;
}

.commissionRules .containerNavigationHeaderCommissionRules .filterStatusHeaderCommissionRules .selectStatusHeaderCommissionRules .textShowSelect,
.commissionRules .containerNavigationHeaderCommissionRules .filterStatusHeaderCommissionRules .selectStatusHeaderCommissionRules .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionRules .containerNavigationHeaderCommissionRules .filterStatusHeaderCommissionRules .selectStatusHeaderCommissionRules .fieldSelect,
.commissionRules .containerNavigationHeaderCommissionRules .filterStatusHeaderCommissionRules .selectStatusHeaderCommissionRules .fieldSelectMulti
 {
    border: none;
}

.commissionRules .containerListHeaderCommissionRules {
    height: calc(100vh - 56px - 65px);
}
.tableEditCommissionRules {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditCommissionRules .helpTableEditCommissionRules {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionRules table, tr, th, td {
    margin: 0;
}

.tableEditCommissionRules table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditCommissionRules table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditCommissionRules table tr {
    height: 32px;
}

.tableEditCommissionRules .theadTableEditCommissionRules td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditCommissionRules .theadTableEditCommissionRules td:last-of-type {
    border-right: none;
}

.tableEditCommissionRules td.checkTableEditCommissionRules {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionRules .tbodyTableEditCommissionRules:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditCommissionRules .table .tbodyTableEditCommissionRules td.checkTableEditCommissionRules {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td:hover .buttonEditTableEditCommissionRules {
    opacity: 1;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td:hover .editTableEditCommissionRules {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td .editTableEditCommissionRules.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td .stampEditCommissionRules {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditCommissionRules .tbodyTableEditCommissionRules td .editTableEditCommissionRules .modalFieldEdit {
    z-index: 1000;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules .detailsTableEditCommissionRules {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules .detailsTableEditCommissionRules button.button.buttonShowDetailsTableEditCommissionRules { 
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditCommissionRules .tbodyTableEditCommissionRules .detailsTableEditCommissionRules button.button.buttonShowDetailsTableEditCommissionRules.success { 
    color: var(--color-success);
}

.tableEditCommissionRules .tbodyTableEditCommissionRules .detailsTableEditCommissionRules button.button.buttonShowDetailsTableEditCommissionRules.error { 
    color: var(--color-error);
}

.tableEditCommissionRules .tbodyTableEditCommissionRules .detailsTableEditCommissionRules button.button.buttonShowDetailsTableEditCommissionRules.warning { 
    color: var(--color-warning);
}

.tableEditCommissionRules .tbodyTableEditCommissionRules .detailsTableEditCommissionRules .messageDetailsTableEditCommissionRules {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}
.commissionRulesProspection {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .buttonNavigationHeaderCommissionRulesProspection {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .buttonNavigationHeaderCommissionRulesProspection:hover {
    background-color: var(--white-dark);
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .buttonNavigationHeaderCommissionRulesProspection:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .buttonNavigationHeaderCommissionRulesProspection:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .buttonNavigationHeaderCommissionRulesProspection.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .buttonPlusNavigationHeaderCommissionRulesProspection {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .buttonTrashNavigationHeaderCommissionRulesProspection {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterFontsHeaderCommissionRulesProspection {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterFontsHeaderCommissionRulesProspection .iconFilterFontsHeaderCommissionRulesProspection {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterFontsHeaderCommissionRulesProspection .selectFontHeaderCommissionRulesProspection {
    cursor: pointer;
    width: 100%;
    border: none;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterFontsHeaderCommissionRulesProspection .selectFontHeaderCommissionRulesProspection .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterFontsHeaderCommissionRulesProspection .selectFontHeaderCommissionRulesProspection .fieldSelectMulti {
    border: none;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterStatusHeaderCommissionRulesProspection {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterStatusHeaderCommissionRulesProspection .inputFilterCommissionRulesProspection .fieldInputGeneral {
    border: none!important;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterStatusHeaderCommissionRulesProspection .iconfilterStatusHeaderCommissionRulesProspection {
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterStatusHeaderCommissionRulesProspection .selectStatusHeaderCommissionRulesProspection {
    cursor: pointer;
    width: 100%;
    border: none;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterStatusHeaderCommissionRulesProspection .selectStatusHeaderCommissionRulesProspection .textShowSelect,
.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterStatusHeaderCommissionRulesProspection .selectStatusHeaderCommissionRulesProspection .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterStatusHeaderCommissionRulesProspection .selectStatusHeaderCommissionRulesProspection .fieldSelect,
.commissionRulesProspection .containerNavigationHeaderCommissionRulesProspection .filterStatusHeaderCommissionRulesProspection .selectStatusHeaderCommissionRulesProspection .fieldSelectMulti
 {
    border: none;
}
.tableEditCommissionRulesProspection {
    background-color: var(--white-light);
}

.tableEditCommissionRulesProspection .helpTableEditCommissionRulesProspection {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionRulesProspection table, tr, th, td {
    margin: 0;
}

.tableEditCommissionRulesProspection table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionRulesProspection table .buttonCheckTableEditCommissionRulesProspection {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditCommissionRulesProspection table .buttonCheckTableEditCommissionRulesProspection .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditCommissionRulesProspection table .buttonCheckTableEditCommissionRulesProspection.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditCommissionRulesProspection table .buttonCheckTableEditCommissionRulesProspection.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditCommissionRulesProspection table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditCommissionRulesProspection table tr {
    height: 32px;
}

.tableEditCommissionRulesProspection .theadTableEditCommissionRulesProspection td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditCommissionRulesProspection .theadTableEditCommissionRulesProspection td:last-of-type {
    border-right: none;
}

.tableEditCommissionRulesProspection td.checkTableEditCommissionRulesProspection {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditCommissionRulesProspection .table .tbodyTableEditCommissionRulesProspection td.checkTableEditCommissionRulesProspection {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td:hover .buttonEditTableEditCommissionRulesProspection {
    opacity: 1;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td:hover .editTableEditCommissionRulesProspection {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td .editTableEditCommissionRulesProspection.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td .stampEditCommissionRules {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection td .editTableEditCommissionRulesProspection .modalFieldEdit {
    z-index: 1000;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection button.button.buttonShowDetailsTableEditCommissionRulesProspection { 
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection button.button.buttonShowDetailsTableEditCommissionRulesProspection.success { 
    color: var(--color-success);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection button.button.buttonShowDetailsTableEditCommissionRulesProspection.error { 
    color: var(--color-error);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection button.button.buttonShowDetailsTableEditCommissionRulesProspection.warning { 
    color: var(--color-warning);
}

.tableEditCommissionRulesProspection .tbodyTableEditCommissionRulesProspection .detailsTableEditCommissionRulesProspection .messageDetailsTableEditCommissionRulesProspection {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.tableEditCommissionRulesProspection .theadTableEditCommissionRulesProspection td.minimus {
    width: 90px!important;
}
.commissionRulesIntervals {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.commissionRulesIntervals .loadingCommissionRulesIntervals {
    transition: all ease-in .5s;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .buttonNavigationHeaderCommissionRulesIntervals {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .buttonNavigationHeaderCommissionRulesIntervals:hover {
    background-color: var(--white-dark);
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .buttonNavigationHeaderCommissionRulesIntervals:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .buttonNavigationHeaderCommissionRulesIntervals:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .buttonNavigationHeaderCommissionRulesIntervals.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .buttonPlusNavigationHeaderCommissionRulesIntervals {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .buttonTrashNavigationHeaderCommissionRulesIntervals {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterFontsHeaderCommissionRulesIntervals {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterFontsHeaderCommissionRulesIntervals .iconFilterFontsHeaderCommissionRulesIntervals {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterFontsHeaderCommissionRulesIntervals .selectFontHeaderCommissionRulesIntervals {
    cursor: pointer;
    width: 100%;
    border: none;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterFontsHeaderCommissionRulesIntervals .selectFontHeaderCommissionRulesIntervals .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterFontsHeaderCommissionRulesIntervals .selectFontHeaderCommissionRulesIntervals .fieldSelectMulti {
    border: none;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterStatusHeaderCommissionRulesIntervals {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterStatusHeaderCommissionRulesIntervals .inputFilterCommissionRulesIntervals .fieldInputGeneral {
    border: none!important;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterStatusHeaderCommissionRulesIntervals .iconfilterStatusHeaderCommissionRulesIntervals {
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterStatusHeaderCommissionRulesIntervals .selectStatusHeaderCommissionRulesIntervals {
    cursor: pointer;
    width: 100%;
    border: none;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterStatusHeaderCommissionRulesIntervals .selectStatusHeaderCommissionRulesIntervals .textShowSelect,
.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterStatusHeaderCommissionRulesIntervals .selectStatusHeaderCommissionRulesIntervals .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterStatusHeaderCommissionRulesIntervals .selectStatusHeaderCommissionRulesIntervals .fieldSelect,
.commissionRulesIntervals .containerNavigationHeaderCommissionRulesIntervals .filterStatusHeaderCommissionRulesIntervals .selectStatusHeaderCommissionRulesIntervals .fieldSelectMulti
 {
    border: none;
}
.tableEditCommissionRulesIntervals {
    background-color: var(--white-light);
}

.tableEditCommissionRulesIntervals .helpTableEditCommissionRulesIntervals {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionRulesIntervals table, tr, th, td {
    margin: 0;
}

.tableEditCommissionRulesIntervals table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionRulesIntervals table .buttonCheckTableEditCommissionRulesIntervals {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditCommissionRulesIntervals table .buttonCheckTableEditCommissionRulesIntervals .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditCommissionRulesIntervals table .buttonCheckTableEditCommissionRulesIntervals.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditCommissionRulesIntervals table .buttonCheckTableEditCommissionRulesIntervals.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditCommissionRulesIntervals table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditCommissionRulesIntervals table tr {
    height: 32px;
}

.tableEditCommissionRulesIntervals .theadTableEditCommissionRulesIntervals td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditCommissionRulesIntervals .theadTableEditCommissionRulesIntervals td:last-of-type {
    border-right: none;
}

.tableEditCommissionRulesIntervals td.checkTableEditCommissionRulesIntervals {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditCommissionRulesIntervals .table .tbodyTableEditCommissionRulesIntervals td.checkTableEditCommissionRulesIntervals {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td:hover .buttonEditTableEditCommissionRulesIntervals {
    opacity: 1;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td:hover .editTableEditCommissionRulesIntervals {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td .editTableEditCommissionRulesIntervals.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td .stampEditCommissionRules {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals td .editTableEditCommissionRulesIntervals .modalFieldEdit {
    z-index: 1000;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals button.button.buttonShowDetailsTableEditCommissionRulesIntervals { 
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals button.button.buttonShowDetailsTableEditCommissionRulesIntervals.success { 
    color: var(--color-success);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals button.button.buttonShowDetailsTableEditCommissionRulesIntervals.error { 
    color: var(--color-error);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals button.button.buttonShowDetailsTableEditCommissionRulesIntervals.warning { 
    color: var(--color-warning);
}

.tableEditCommissionRulesIntervals .tbodyTableEditCommissionRulesIntervals .detailsTableEditCommissionRulesIntervals .messageDetailsTableEditCommissionRulesIntervals {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.tableEditCommissionRulesIntervals .theadTableEditCommissionRulesIntervals td.minimus {
    width: 90px!important;
}
.commissionReport {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.commissionReport .warningCommissionReport {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.commissionReport .containerNavigationHeaderCommissionReport {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionReport .containerNavigationHeaderCommissionReport .buttonNavigationHeaderCommissionReport {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionReport .containerNavigationHeaderCommissionReport .buttonNavigationHeaderCommissionReport:hover {
    background-color: var(--white-dark);
}

.commissionReport .containerNavigationHeaderCommissionReport .buttonNavigationHeaderCommissionReport:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionReport .containerNavigationHeaderCommissionReport .buttonNavigationHeaderCommissionReport:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionReport .containerNavigationHeaderCommissionReport .buttonNavigationHeaderCommissionReport.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionReport .containerNavigationHeaderCommissionReport .buttonPlusNavigationHeaderCommissionReport {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.commissionReport .containerNavigationHeaderCommissionReport .buttonTrashNavigationHeaderCommissionReport {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.commissionReport .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReport .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport .iconFilterHeaderCommissionReport {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReport .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport .selectFilterHeaderCommissionReport {
    width: 100%;
    border: none;
    cursor: pointer;
}

.commissionReport .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport .selectFilterHeaderCommissionReport .optionsSelectMulti {
    min-width: 200px;
}

.commissionReport .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport .selectFilterHeaderCommissionReport .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReport .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport .selectFilterHeaderCommissionReport .fieldSelectMulti {
    border: none;
}

.commissionReport .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReport .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .iconfilterStatusHeaderCommissionReport {
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReport .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .selectStatusHeaderCommissionReport .textShowSelect,
.commissionReport .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .selectStatusHeaderCommissionReport .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReport .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .selectStatusHeaderCommissionReport .fieldSelect,
.commissionReport .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .selectStatusHeaderCommissionReport .fieldSelectMulti
 {
    border: none;
    width: 100%;
}

.commissionReport .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .select.selectStatusHeaderCommissionReport {
    width: 100%;
    cursor: pointer;
}

.commissionReport .containerNavigationHeaderCommissionReport .filterOwnersHeaderCommissionReport {
    margin-right: 20px;
    min-width: 180px;    
    position: relative;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    cursor: pointer;
}

.commissionReport .containerNavigationHeaderCommissionReport .filterOwnersHeaderCommissionReport .selectOwnersHeaderCommissionReport .fieldSelectMulti {
    border: none;
}

.commissionReport .containerListHeaderCommissionReport {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderCommissionReport */
}
.tableEditCommissionReport {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditCommissionReport .helpTableEditCommissionReport {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionReport .hoverEditEffect:hover {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.tableEditCommissionReport table, tr, th, td {
    margin: 0;
}

.tableEditCommissionReport table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionReport table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditCommissionReport table td.center {
    text-align: center;
}

.tableEditCommissionReport table td.left {
    text-align: left;
}

.tableEditCommissionReport table td.right {
    text-align: right;
}

.tableEditCommissionReport table tr {
    height: 32px;
}

.tableEditCommissionReport .theadTableEditCommissionReport td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
    padding-right: 10px;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus {
    width: 90px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus45 {
    width: 45px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus60 {
    width: 60px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus75 {
    width: 75px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus80 {
    width: 80px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus100 {
    width: 100px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus120 {
    width: 120px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus140 {
    width: 140px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus160 {
    width: 160px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td.minimus180 {
    width: 180px !important;
}

.tableEditCommissionReport .theadTableEditCommissionReport td:last-of-type {
    border-right: none;
}

.tableEditCommissionReport .tbodyTableEditCommissionReport:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionReport .tbodyTableEditCommissionReport:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionReport .tbodyTableEditCommissionReport td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    border: 1px solid #eee;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
}

.tableEditCommissionReport .tfootTableEditCommissionReport td {
    border: 1.02px solid #b9babb;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 14px;
    position: relative;
    padding-right: 10px;
}
.commissionReportAttendant {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.commissionReportAttendant .warningCommissionReportAttendant {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonNavigationHeaderCommissionReportAttendant {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonNavigationHeaderCommissionReportAttendant:hover {
    background-color: var(--white-dark);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonNavigationHeaderCommissionReportAttendant:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonNavigationHeaderCommissionReportAttendant:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonNavigationHeaderCommissionReportAttendant.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonPlusNavigationHeaderCommissionReportAttendant {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .buttonTrashNavigationHeaderCommissionReportAttendant {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant .iconFilterHeaderCommissionReportAttendant {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant .selectFilterHeaderCommissionReportAttendant {
    width: 100%;
    border: none;
    cursor: pointer;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant .selectFilterHeaderCommissionReportAttendant .optionsSelectMulti {
    min-width: 200px;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant .selectFilterHeaderCommissionReportAttendant .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterHeaderCommissionReportAttendant .selectFilterHeaderCommissionReportAttendant .fieldSelectMulti {
    border: none;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .iconfilterStatusHeaderCommissionReportAttendant {
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .selectStatusHeaderCommissionReportAttendant .textShowSelect,
.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .selectStatusHeaderCommissionReportAttendant .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .selectStatusHeaderCommissionReportAttendant .fieldSelect,
.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .selectStatusHeaderCommissionReportAttendant .fieldSelectMulti
 {
    border: none;
    width: 100%;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterStatusHeaderCommissionReportAttendant .select.selectStatusHeaderCommissionReportAttendant {
    width: 100%;
    cursor: pointer;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterOwnersHeaderCommissionReportAttendant {
    margin-right: 20px;
    min-width: 180px;    
    position: relative;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    cursor: pointer;
}

.commissionReportAttendant .containerNavigationHeaderCommissionReportAttendant .filterOwnersHeaderCommissionReportAttendant .selectOwnersHeaderCommissionReportAttendant .fieldSelectMulti {
    border: none;
}

.commissionReportAttendant .containerListHeaderCommissionReportAttendant {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderCommissionReportAttendant */
}
.tableEditCommissionReportAttendant {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditCommissionReportAttendant .helpTableEditCommissionReportAttendant {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionReportAttendant .hoverEditEffect:hover {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.tableEditCommissionReportAttendant table, tr, th, td {
    margin: 0;
}

.tableEditCommissionReportAttendant table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionReportAttendant table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditCommissionReportAttendant table td.center {
    text-align: center;
}

.tableEditCommissionReportAttendant table td.left {
    text-align: left;
}

.tableEditCommissionReportAttendant table td.right {
    text-align: right;
}

.tableEditCommissionReportAttendant table tr {
    height: 32px;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
    padding-right: 10px;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td.minimus {
    width: 90px !important;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td.minimus45 {
    width: 45px !important;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td.minimus60 {
    width: 60px !important;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td.minimus75 {
    width: 75px !important;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td.minimus80 {
    width: 80px !important;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td.minimus100 {
    width: 100px !important;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td.minimus120 {
    width: 120px !important;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td.minimus140 {
    width: 140px !important;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td.minimus160 {
    width: 160px !important;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td.minimus180 {
    width: 180px !important;
}

.tableEditCommissionReportAttendant .theadTableEditCommissionReportAttendant td:last-of-type {
    border-right: none;
}

.tableEditCommissionReportAttendant .tbodyTableEditCommissionReportAttendant:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionReportAttendant .tbodyTableEditCommissionReportAttendant:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionReportAttendant .tbodyTableEditCommissionReportAttendant td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    border: 1px solid #eee;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
}

.tableEditCommissionReportAttendant .tfootTableEditCommissionReportAttendant td {
    border: 1.02px solid #b9babb;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 14px;
    position: relative;
    padding-right: 10px;
}
.commissionReportManager {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.commissionReportManager .warningCommissionReport {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.commissionReportManager .containerNavigationHeaderCommissionReport {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .buttonNavigationHeaderCommissionReport {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .buttonNavigationHeaderCommissionReport:hover {
    background-color: var(--white-dark);
}

.commissionReportManager .containerNavigationHeaderCommissionReport .buttonNavigationHeaderCommissionReport:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .buttonNavigationHeaderCommissionReport:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .buttonNavigationHeaderCommissionReport.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .buttonPlusNavigationHeaderCommissionReport {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .buttonTrashNavigationHeaderCommissionReport {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport .iconFilterHeaderCommissionReport {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport .selectFilterHeaderCommissionReport {
    width: 100%;
    border: none;
    cursor: pointer;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport .selectFilterHeaderCommissionReport .optionsSelectMulti {
    min-width: 200px;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport .selectFilterHeaderCommissionReport .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterHeaderCommissionReport .selectFilterHeaderCommissionReport .fieldSelectMulti {
    border: none;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .iconfilterStatusHeaderCommissionReport {
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .selectStatusHeaderCommissionReport .textShowSelect,
.commissionReportManager .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .selectStatusHeaderCommissionReport .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .selectStatusHeaderCommissionReport .fieldSelect,
.commissionReportManager .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .selectStatusHeaderCommissionReport .fieldSelectMulti
 {
    border: none;
    width: 100%;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterStatusHeaderCommissionReport .select.selectStatusHeaderCommissionReport {
    width: 100%;
    cursor: pointer;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterOwnersHeaderCommissionReport {
    margin-right: 20px;
    min-width: 180px;    
    position: relative;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    cursor: pointer;
}

.commissionReportManager .containerNavigationHeaderCommissionReport .filterOwnersHeaderCommissionReport .selectOwnersHeaderCommissionReport .fieldSelectMulti {
    border: none;
}

.commissionReportManager .containerListHeaderCommissionReport {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderCommissionReport */
}
.tableEditCommissionReportManager {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditCommissionReportManager .helpTableEditCommissionReport {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;
}

.tableEditCommissionReportManager .hoverEditEffect:hover {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.tableEditCommissionReportManager table, tr, th, td {
    margin: 0;
}

.tableEditCommissionReportManager table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionReportManager table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditCommissionReportManager table td.center {
    text-align: center;
}

.tableEditCommissionReportManager table td.left {
    text-align: left;
}

.tableEditCommissionReportManager table td.right {
    text-align: right;
}

.tableEditCommissionReportManager table tr {
    height: 32px;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
    padding-right: 10px;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td.minimus {
    width: 90px !important;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td.minimus45 {
    width: 45px !important;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td.minimus60 {
    width: 60px !important;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td.minimus75 {
    width: 75px !important;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td.minimus80 {
    width: 80px !important;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td.minimus100 {
    width: 100px !important;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td.minimus120 {
    width: 120px !important;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td.minimus140 {
    width: 140px !important;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td.minimus160 {
    width: 160px !important;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td.minimus180 {
    width: 180px !important;
}

.tableEditCommissionReportManager .theadTableEditCommissionReport td:last-of-type {
    border-right: none;
}

.tableEditCommissionReportManager .tbodyTableEditCommissionReport:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionReportManager .tbodyTableEditCommissionReport:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionReportManager .tbodyTableEditCommissionReport td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    border: 1px solid #eee;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
}

.tableEditCommissionReportManager .tfootTableEditCommissionReport td {
    border: 1.02px solid #b9babb;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 14px;
    position: relative;
    padding-right: 10px;
}
.commissionReportSupervisor {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.commissionReportSupervisor .warningCommissionReportSupervisor {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonNavigationHeaderCommissionReportSupervisor {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonNavigationHeaderCommissionReportSupervisor:hover {
    background-color: var(--white-dark);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonNavigationHeaderCommissionReportSupervisor:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonNavigationHeaderCommissionReportSupervisor:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonNavigationHeaderCommissionReportSupervisor.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonPlusNavigationHeaderCommissionReportSupervisor {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .buttonTrashNavigationHeaderCommissionReportSupervisor {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor .iconFilterHeaderCommissionReportSupervisor {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor .selectFilterHeaderCommissionReportSupervisor {
    width: 100%;
    border: none;
    cursor: pointer;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor .selectFilterHeaderCommissionReportSupervisor .optionsSelectMulti {
    min-width: 200px;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor .selectFilterHeaderCommissionReportSupervisor .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterHeaderCommissionReportSupervisor .selectFilterHeaderCommissionReportSupervisor .fieldSelectMulti {
    border: none;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .iconfilterStatusHeaderCommissionReportSupervisor {
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .selectStatusHeaderCommissionReportSupervisor .textShowSelect,
.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .selectStatusHeaderCommissionReportSupervisor .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .selectStatusHeaderCommissionReportSupervisor .fieldSelect,
.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .selectStatusHeaderCommissionReportSupervisor .fieldSelectMulti
 {
    border: none;
    width: 100%;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterStatusHeaderCommissionReportSupervisor .select.selectStatusHeaderCommissionReportSupervisor {
    width: 100%;
    cursor: pointer;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterOwnersHeaderCommissionReportSupervisor {
    margin-right: 20px;
    min-width: 180px;    
    position: relative;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    cursor: pointer;
}

.commissionReportSupervisor .containerNavigationHeaderCommissionReportSupervisor .filterOwnersHeaderCommissionReportSupervisor .selectOwnersHeaderCommissionReportSupervisor .fieldSelectMulti {
    border: none;
}

.commissionReportSupervisor .containerListHeaderCommissionReportSupervisor {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderCommissionReportSupervisor */
}
.tableEditCommissionReportSupervisor {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditCommissionReportSupervisor .helpTableEditCommissionReportSupervisor {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionReportSupervisor .hoverEditEffect:hover {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.tableEditCommissionReportSupervisor table, tr, th, td {
    margin: 0;
}

.tableEditCommissionReportSupervisor table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionReportSupervisor table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditCommissionReportSupervisor table td.center {
    text-align: center;
}

.tableEditCommissionReportSupervisor table td.left {
    text-align: left;
}

.tableEditCommissionReportSupervisor table td.right {
    text-align: right;
}

.tableEditCommissionReportSupervisor table tr {
    height: 32px;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
    padding-right: 10px;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td.minimus {
    width: 90px !important;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td.minimus45 {
    width: 45px !important;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td.minimus60 {
    width: 60px !important;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td.minimus75 {
    width: 75px !important;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td.minimus80 {
    width: 80px !important;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td.minimus100 {
    width: 100px !important;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td.minimus120 {
    width: 120px !important;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td.minimus140 {
    width: 140px !important;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td.minimus160 {
    width: 160px !important;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td.minimus180 {
    width: 180px !important;
}

.tableEditCommissionReportSupervisor .theadTableEditCommissionReportSupervisor td:last-of-type {
    border-right: none;
}

.tableEditCommissionReportSupervisor .tbodyTableEditCommissionReportSupervisor:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionReportSupervisor .tbodyTableEditCommissionReportSupervisor:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionReportSupervisor .tbodyTableEditCommissionReportSupervisor td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    border: 1px solid #eee;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
}

.tableEditCommissionReportSupervisor .tfootTableEditCommissionReportSupervisor td {
    border: 1.02px solid #b9babb;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 14px;
    position: relative;
    padding-right: 10px;
}
.serviceQueue {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.serviceQueue .warningServiceQueue {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.serviceQueue .scrollServiceQueue {
    overflow: scroll;
    height: 100%;
}

.serviceQueue .headerServiceQueue {
    margin-top: 20px;
}

.serviceQueue .headerServiceQueue h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.serviceQueue .headerServiceQueue h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-error);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}

.serviceQueue .bodyServiceQueue  {
    /* nothing */
}

.serviceQueue .bodyServiceQueue .lineTitleHeaderServiceQueue {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.serviceQueue .bodyServiceQueue .lineTitleHeaderServiceQueue .columnTitleHeaderServiceQueue {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue {
    background-color: hsla(134, 61%, 50%, 0.527);
    cursor: -webkit-grab;
    cursor: grab;
    border-bottom: 1px solid ;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .companyUnitServiceQueue {
    border: 2px solid var(--dark-dark-three);
}


.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    margin-left: auto;
    margin-right: auto;
}

.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue .iconFilterBodyServiceQueue {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue .selectFilterBodyServiceQueue {
    width: 100%;
    border: none;
    cursor: pointer;
}

.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue .selectFilterBodyServiceQueue .optionsSelect {
    min-width: 200px;
}

.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue .selectFilterBodyServiceQueue .textShowSelect {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.serviceQueue .bodyServiceQueue .lineCompanyUnitServiceQueue .filterBodyServiceQueue .selectFilterBodyServiceQueue .fieldSelect {
    border: none;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue.final {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue.frozen {
    background-color: hsla(354, 70%, 50%, 0.493);
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue.companyUnit {
    display: flex;
    justify-content: flex-start;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue.companyUnit .partialCompanyUnitServiceQueue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue.companyUnit .fullCompanyUnitServiceQueue {
    display: none;
    background-color: var(--dark-dark-two);
    color: var(--white-dark);
    position: absolute;
    padding: 5px 10px;
    border-radius: 5px;
    top: -10px;
    left: 0;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue.companyUnit:hover .fullCompanyUnitServiceQueue {
    display: inline-flex;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue .buttonBodyConfiServiceQueue {
    background-color: transparent;
    color: var(--dark-dark-three);
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
}

.serviceQueue .bodyServiceQueue .lineBodyServiceQueue .columnBodyServiceQueue .buttonBodyConfiServiceQueue:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-dark-two);
    transition: background-color 1s;
}

.serviceQueue .footerServiceQueue {
    margin-bottom: 250px;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue {
    width: 100%;
    border-bottom: 1px solid;
    padding-top: 10px;
    padding-bottom: 10px;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue.success {
    background-color: hsla(134, 61%, 50%, 0.527);
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue.error {
    background-color: hsla(354, 70%, 50%, 0.493);
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue {
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
}

.serviceQueue .footerServiceQueue .lineTitleFooterServiceQueue {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.serviceQueue .footerServiceQueue .lineTitleFooterServiceQueue .columnTitleFooterServiceQueue {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue span {
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue.reason {
    position: relative;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue .partialReasonServiceQueue {
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    text-overflow: ellipsis;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue .fullReasonServiceQueue {
    display: none;
    cursor: default;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue .columnFooterServiceQueue.reason:hover .fullReasonServiceQueue {
    display: block;
    background-color: var(--dark-dark-two);
    color: var(--white-dark);
    position: absolute;
    padding: 5px 10px;
    border-radius: 5px;
    top: -40px;
    left: 0;
}

.serviceQueue .footerServiceQueue .lineFooterServiceQueue:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.campaign {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.campaign .containerNavigationHeaderCampaign {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.campaign .containerNavigationHeaderCampaign .buttonNavigationHeaderCampaign {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.campaign .containerNavigationHeaderCampaign .buttonNavigationHeaderCampaign:hover {
    background-color: var(--white-dark);
}

.campaign .containerNavigationHeaderCampaign .buttonNavigationHeaderCampaign:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.campaign .containerNavigationHeaderCampaign .buttonNavigationHeaderCampaign:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.campaign .containerNavigationHeaderCampaign .buttonNavigationHeaderCampaign.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.campaign .containerNavigationHeaderCampaign .buttonPlusNavigationHeaderCampaign {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.campaign .containerNavigationHeaderCampaign .filterPeriodHeaderCampaign {
    padding-left: 0;
    padding-right: 0;
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .buttonStatusHeaderCampaign {
    font-size: 16px;
    background-color: transparent;
    padding: 4px 8px;
    text-align: center;
    white-space: nowrap;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    color: var(--primary-color);
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .inputFilterCampaign .fieldInputGeneral {
    border: none!important;
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .iconfilterStatusHeaderCampaign {
    font-size: 14px;
    color: var(--dark-dark);
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .selectStatusHeaderCampaign {
    cursor: pointer;
    width: 100%;
    border: none;
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .selectStatusHeaderCampaign .textShowSelect,
.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .selectStatusHeaderCampaign .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .selectStatusHeaderCampaign .fieldSelect,
.campaign .containerNavigationHeaderCampaign .filterStatusHeaderCampaign .selectStatusHeaderCampaign .fieldSelectMulti
 {
    border: none;
}

.campaign .containerNavigationHeaderCampaign .dropIntervalsHeaderCampaign {
    position: absolute;
    width: 550px;
    height: 140px;
    top: 35px;
    right: 0px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    z-index: 900;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;
    overflow: hidden;
}

.campaign .containerNavigationHeaderCampaign .lineBodyDropIntervalsHeaderCampaign {
    padding: 5px 13px;
}

.campaign .containerNavigationHeaderCampaign .lineFooterDropIntervalsHeaderCampaign {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
}

.campaign .containerNavigationHeaderCampaign .lineFooterDropIntervalsHeaderCampaign .buttonIntervalsHeaderCampaign {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.campaign .containerNavigationHeaderCampaign .lineFooterDropIntervalsHeaderCampaign .buttonIntervalsHeaderCampaign:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.campaign .containerNavigationHeaderCampaign .lineFooterDropIntervalsHeaderCampaign .buttonIntervalsHeaderCampaign.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.campaign .containerNavigationHeaderCampaign .lineFooterDropIntervalsHeaderCampaign .buttonIntervalsHeaderCampaign.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.campaign .containerListHeaderCampaign {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderCampaign*/
    overflow: scroll;
}
.tableEditCampaign {
    background-color: var(--white-light);
    overflow: scroll;
}

.tableEditCampaign .helpTableEditCampaign {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;
}

.tableEditCampaign table, tr, th, td {
    margin: 0;
}

.tableEditCampaign table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCampaign table .buttonCheckTableEditCampaign {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditCampaign table .buttonCheckTableEditCampaign .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditCampaign table .buttonCheckTableEditCampaign.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditCampaign table .buttonCheckTableEditCampaign.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditCampaign table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditCampaign table tr {
    height: 32px;
}

.tableEditCampaign .theadTableEditCampaign td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditCampaign .theadTableEditCampaign td.minimus {
    width: 90px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus45 {
    width: 30px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus60 {
    width: 60px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus75 {
    width: 75px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus100 {
    width: 100px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus120 {
    width: 120px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus140 {
    width: 140px!important;
}

.tableEditCampaign .theadTableEditCampaign td.minimus180 {
    width: 180px!important;
}

.tableEditCampaign .theadTableEditCampaign td:last-of-type {
    border-right: none;
}

.tableEditCampaign .tbodyTableEditCampaign:hover {
    background-color: var(--white-dark);
}

.tableEditCampaign .tbodyTableEditCampaign:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCampaign td.checkTableEditCampaign {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditCampaign .tbodyTableEditCampaign td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditCampaign .table .tbodyTableEditCampaign td.checkTableEditCampaign {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditCampaign .tbodyTableEditCampaign td:hover .buttonEditTableEditCampaign {
    opacity: 1;
}

.tableEditCampaign .tbodyTableEditCampaign td:hover .editTableEditCampaign {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditCampaign .tbodyTableEditCampaign td .editTableEditCampaign.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditCampaign .tbodyTableEditCampaign td .editTableEditCampaign .modalFieldEdit {
    z-index: 1000;
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign button.button.buttonShowDetailsTableEditCampaign {
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign button.button.buttonShowDetailsTableEditCampaign.noAction {
    cursor: default;
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign button.button.buttonShowDetailsTableEditCampaign.success { 
    color: var(--color-success);
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign button.button.buttonShowDetailsTableEditCampaign.error { 
    color: var(--color-error);
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign button.button.buttonShowDetailsTableEditCampaign.warning { 
    color: var(--color-warning);
}

.tableEditCampaign .tbodyTableEditCampaign .detailsTableEditCampaign .messageDetailsTableEditCampaign {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}
.development {
    height: 100vh;
    position: relative;
    overflow: scroll;

    --dark-version-one: purple;
    --dark-version-two: rgb(149, 0, 149);
    --dark-version-three: rgb(173, 70, 173);

    --light-version-one: var(--dark-dark-three);
    --light-version-two: var(--white-light);
    --light-version-three: var(--white-dark-two);
}

.development .warningDevelopment {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.development .scrollDevelopment {
    overflow: scroll;
    height: 3000px;
}

.development .scrollDevelopment .scrollDemoDevelopment {
    height: 200px;
    width: 200px;
    overflow: scroll;
    background-color: #f0db4f
}

.development .scrollDevelopment .scrollDemoDevelopment p {
    height: 300px;
    width: 300px;
}

.development .headerDevelopment {
    margin-top: 20px;
}

.development .headerDevelopment h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.development .headerDevelopment h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: purple;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}

.development .heart {
    color: red;
}

/* start overwritten DatePickerDark */
/* start overwritten DatePickerDark */
/* start overwritten DatePickerDark */
.development .customDatePickerDevelopmentDark {
    border: 2px solid var(--dark-version-one);
    height: 50px;
    border-radius: 10px;
    padding: 5px 20px;
    background-color: var(--dark-version-one);
    color: white;
    font-weight: 600;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper {
    border: none!important;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper {
    border: none!important;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper input {
    color: white;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper input::-webkit-input-placeholder {
    color: white;
    background-color: transparent;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper input::placeholder {
    color: white;
    background-color: transparent;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper button {
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
    background-color: transparent!important;
    color: white!important;
    fill: white;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper button svg {
    color: white!important;
    stroke: white!important;
}

.development .customDatePickerDevelopmentDark .react-date-picker__wrapper button svg:hover {
    color: rgb(202, 202, 202)!important;
    stroke:  rgb(202, 202, 202)!important;
    transition: stroke 2s;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark {
    background-color: var(--dark-version-one)!important;
    border: 1px solid var(--dark-version-one);
    overflow: hidden;
    color: white!important;
    border-radius: 10px;
    padding: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__navigation {
    border: none!important;
    box-shadow: none!important;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__navigation button {
    color: white;
    background-color: transparent;
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
    font-weight: 600;
    border-radius: 50%;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__navigation button:hover {
    background-color: var(--dark-version-two);
    transition: background-color 2s;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__navigation__label {
    background-color: transparent!important;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__viewContainer button {
    /* color: white; */
    font-weight: 300;
    border-radius: 5px;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__viewContainer button:hover {
    background-color: var(--dark-version-three)!important;
    transition: background-color 1s;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__viewContainer button.react-calendar__tile--active {
    background-color: var(--color-information);
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__viewContainer button.react-calendar__tile--now {
    background-color: var(--dark-version-three);
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__month-view__days__day--weekend {
    /* background-color: var(--color-error); */
    color: var(--color-error);
    font-weight: 400;
}

.development .customDatePickerDevelopmentDark .customCalendarDatePickerDevelopmentDark .react-calendar__month-view__days__day--neighboringMonth {
    color: grey;
}
/* ends overwritten DatePickerDark */
/* ends overwritten DatePickerDark */
/* ends overwritten DatePickerDark */

/* start overwritten DatePickerLight */
/* start overwritten DatePickerLight */
/* start overwritten DatePickerLight */
.development .customDatePickerDevelopmentLight {
    border: 1px solid var(--light-version-one);
    height: 50px;
    min-width: 220px;
    border-radius: 0px;
    padding: 5px 20px;
    background-color: white;
    color: black;
    font-weight: 400;
    font-size: 18px;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper {
    border: none!important;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper {
    border: none!important;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper input {
    color: black;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper input::-webkit-input-placeholder {
    color: black;
    background-color: transparent;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper input::placeholder {
    color: black;
    background-color: transparent;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper button {
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
    background-color: transparent!important;
    color: black!important;
    fill: black;
    font-size: 18px;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper button svg {
    color: black!important;
    stroke: black!important;
}

.development .customDatePickerDevelopmentLight .react-date-picker__wrapper button svg:hover {
    color: rgb(202, 202, 202)!important;
    stroke:  rgb(202, 202, 202)!important;
    transition: stroke 2s;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight {
    background-color: white!important;
    border: 1px solid var(--light-version-one);
    margin-left: -1px;
    overflow: hidden;
    border-radius: 0px;
    padding: 20px;
    margin-top: 1px;
    margin-bottom: 1px;
    font-size: 18px;
    color: var(--color-error)!important;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__navigation {
    border: none!important;
    box-shadow: none!important;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__navigation button {
    color: black;
    background-color: transparent;
    border: none!important;
    box-shadow: none!important;
    outline: none!important;
    font-weight: 300;
    border-radius: 0px;
    font-size: 18px;
    text-transform: uppercase;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__navigation button:hover {
    background-color: var(--light-version-two);
    transition: background-color 2s;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__navigation__label {
    background-color: transparent!important;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__viewContainer button {
    font-weight: 300;
    border-radius: 0px;
    color: black;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__viewContainer button:hover {
    background-color: var(--light-version-three)!important;
    transition: background-color 1s;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__viewContainer button.react-calendar__tile--active {
    background-color: var(--color-information);
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__viewContainer button.react-calendar__tile--now {
    background-color: var(--light-version-three);
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__month-view__days__day--weekend {
    color: var(--color-error);
    font-weight: 300;
}

.development .customDatePickerDevelopmentLight .customCalendarDatePickerDevelopmentLight .react-calendar__month-view__days__day--neighboringMonth {
    color: grey;
}
/* ends overwritten DatePickerLight */
/* ends overwritten DatePickerLight */
/* ends overwritten DatePickerLight */

.development .colorDevelopment {
    width: 100px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: var(--white-light);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
    margin: 5px;
}

.development .lineColorDevelopment {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}


/* start overwritten DatePickerOriginalWeekend */
/* start overwritten DatePickerOriginalWeekend */
/* start overwritten DatePickerOriginalWeekend */
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(7),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(14),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(21),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(28),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(35),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(42) {
    color: black;
}

.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(1),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(8),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(15),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(22),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(29),
.development .customDatePickerOriginalWeekendDevelopment .customCalendarDatePickerOriginalWeekendDevelopment .react-calendar__viewContainer .react-calendar__month-view button:nth-child(36) {
    color: var(--color-error)!important;
    font-weight: 700;
}
/* ends overwritten DatePickerOriginalWeekend */
/* ends overwritten DatePickerOriginalWeekend */
/* ends overwritten DatePickerOriginalWeekend */
.connectionLogs {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.connectionLogs .warningConnectionLogs {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.connectionLogs .scrollConnectionLogs {
    overflow: scroll;
    height: 100%;
}

.connectionLogs .headerConnectionLogs h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.connectionLogs .headerConnectionLogs h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}

.connectionLogs .bodyConnectionLogs  {
    margin-bottom: 250px;
    margin-top: 20px;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs {
    display: flex;
    justify-content: flex-end;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    margin: 5px;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs.extra {
    min-width: 380px;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .buttonToggleIntervalsConnectionLogs {
    background-color: transparent!important;
    color: var(--primary-color)!important;
    font-size: 14px;
    padding: 4px 8px;
    height: 32px;
    font-weight: 400;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .iconFilterBodyConnectionLogs {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectMultiFilterBodyConnectionLogs,
.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectFilterBodyConnectionLogs {
    width: 100%;
    border: none;
    cursor: pointer;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectMultiFilterBodyConnectionLogs .optionsSelect,
.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectFilterBodyConnectionLogs .optionsSelect {
    min-width: 200px;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectMultiFilterBodyConnectionLogs .textShowSelect,
.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectFilterBodyConnectionLogs .textShowSelect {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectMultiFilterBodyConnectionLogs .fieldSelectMulti,
.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .selectFilterBodyConnectionLogs .fieldSelect{
    border: none;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .dropIntervalsBodyConnectionLogs {
    position: absolute;
    width: 550px;
    height: 140px;
    top: 35px;
    right: 10px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    z-index: 900;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;    
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineBodyDropIntervalsBodyConnectionLogs {
    padding: 5px 13px;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineFooterDropIntervalsBodyConnectionLogs {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineFooterDropIntervalsBodyConnectionLogs .buttonIntervalsBodyConnectionLogs {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineFooterDropIntervalsBodyConnectionLogs .buttonIntervalsBodyConnectionLogs:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineFooterDropIntervalsBodyConnectionLogs .buttonIntervalsBodyConnectionLogs.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .lineFooterDropIntervalsBodyConnectionLogs .buttonIntervalsBodyConnectionLogs.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.connectionLogs .bodyConnectionLogs .lineChosenPermissionGroupConnectionLogs {
    padding-bottom: 20px;
}

.connectionLogs .bodyConnectionLogs .lineChosenPermissionGroupConnectionLogs .columnChosenConnectionLogsGroupConnectionLogs { 
    width: 100%;
}

.connectionLogs .bodyConnectionLogs .lineChosenPermissionGroupConnectionLogs .columnChosenConnectionLogsGroupConnectionLogs .buttonPlusConnectionLogsGroupPermisions {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
}

.connectionLogs .bodyConnectionLogs .lineTitleHeaderConfigConnectionLogs {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.connectionLogs .bodyConnectionLogs .lineTitleHeaderConfigConnectionLogs .columnTitleHeaderConfigConnectionLogs {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs.pair {
    background-color: var(--white-dark-three);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs.final {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs {
    background-color: var(--white-light);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border: 1px solid var(--white-dark-two);
    border-top: none;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs.left {
    display: flex;
    justify-content: flex-start;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .buttonBodyConfiConnectionLogs {
    background-color: transparent;
    color: var(--dark-dark-three);
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .buttonBodyConfiConnectionLogs:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-dark-two);
    transition: background-color 1s;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .isWhiteListConnectionLogs {
    width: 30px;
    height: 30px;
    background-color: black;
    content: " ";
    display: block;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .isWhiteListConnectionLogs.success {
    background-color: var(--color-success);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .isWhiteListConnectionLogs.information {
    background-color: var(--color-information);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .buttonInsertWhiteListConnectionLogs {
    background-color: transparent;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .columnBodyConfigConnectionLogs .buttonInsertWhiteListConnectionLogs .iconButtonIcon {
    color: var(--color-information);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .iconConnectionLog {
    font-size: 18px;
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .iconConnectionLog.success {
    color: var(--color-success);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .iconConnectionLog.error {
    color: var(--color-error);
}

.connectionLogs .bodyConnectionLogs .lineBodyConfigConnectionLogs .iconConnectionLog.warning {
    color: var(--color-warning);
}

@media (max-width: 1366px){
    .connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs {
        justify-content: center;
    }
    
    .connectionLogs .bodyConnectionLogs .lineFiltersConnectionLogs .filterBodyConnectionLogs .dropIntervalsBodyConnectionLogs {
        left: -115px;
        right: initial;
    }
}
.dashboardProspection {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.dashboardProspection .click {
    cursor: pointer;
}

.dashboardProspection .buttonToLoaderTablesDashboardProspection {
    width: 120px;
    font-size: 16px;
    padding: 8px 12px;
    margin: 10px auto;
    font-weight: 400;
}

.dashboardProspection .headerDashboardProspection {
    /* nothing */
}

.dashboardProspection .buttonTodayDashboardProspection {
    width: 50px;
    height: 30px;
    color: var(--color-success);
    background-color: transparent!important;
    border: 1px solid var(--color-success);
    font-size: 10px;
    margin: 0 5px;
    border-radius: 2px;
    padding: 0;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboardProspection .containerBodyDashboardProspection {
    position: relative;
    height: calc(100vh - 56px); /*56px by header component*/
    overflow: scroll;
}

.dashboardProspection .containerBodyDashboardProspection .lineHeaderDashboardProspection {
    margin: 20px 20px 0 20px;
}

.dashboardProspection .containerBodyDashboardProspection .lineHeaderDashboardProspection h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
    color: var(--color-information);
}

.dashboardProspection .containerBodyDashboardProspection .lineHeaderDashboardProspection h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-information);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.dashboardProspection .containerBodyDashboardProspection .lineHeaderDashboardProspection .boxHeaderDashboardProspection {
    z-index: inherit;
    width: 100%;
    margin-bottom: 10px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection {
    z-index: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    z-index: inherit;
    margin: 20px 20px 0 20px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    height: 32px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .iconFilterCompanyUnitDashboardProspection {
    font-size: 14px;
    color: var(--dark-dark);
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .selectCompanyUnitDashboardProspection {
    cursor: pointer;
    width: 100%;
    border: none;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .selectCompanyUnitDashboardProspection .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .selectCompanyUnitDashboardProspection .fieldSelectMulti {
    border: none;
    box-shadow: none;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .selectCompanyUnitDashboardProspection .optionsSelectMulti {
    z-index: 200;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .checkSpecificPeriodDashboardProspection {
    max-width: 220px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .buttonSearchReportDashboardProspection {
    background-color: transparent!important;
    color: var(--color-success);
    height: inherit;
    margin-left: 10px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection.filtersDashboardProspection .inputFilterDashboardProspection {
    width: 180px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border: 1px solid var(--white-dark-two);
    height: 32px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection.filtersDashboardProspection .inputTimeFilterDashboardProspection {
    width: 80px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection.filtersDashboardProspection .inputTimeFilterDashboardProspection .fieldInputGeneral {
    padding: 4px 8px;
}

.dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection.filtersDashboardProspection .inputFilterDashboardProspection .react-date-picker__wrapper {
    border: none!important;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection {
    /* nothing here */
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection {
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .subtitle {
    padding: 5px 10px;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: var(--white-light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areaSubtitlesChartPieDashboardProspection {
    position: relative;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areSubtitleChartPieDashboardProspection {
    position: relative;
    margin-bottom: 20px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areaSubtitlesChartPieDashboardProspection .areSubtitleChartPieDashboardProspection {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 300;
    margin-left: 5px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    width: 100%;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .cardInfoLeftChartDashboardProspection.exclusive {
    min-height: 419px!important;
    position: relative;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areaDashboardProspection {
    max-width: 200px;
    margin: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areaDashboardProspection .avatarDashboardProspection {
    border: 2px solid var(--color-success);
    border-radius: 50%;
    overflow: hidden;
    max-width: 140px;
    height: 100%;
} 

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .itemInfoLeftChartDashboardProspection {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 300;
    line-height: 22px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .itemInfoLeftChartDashboardProspection .featured {
    font-weight: 600;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .labelsCardChartPieDashboardProspection {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .labelsCardChartPieDashboardProspection.italic {
    font-style: italic;
    font-weight: 300;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .labelsCardChartPieDashboardProspection.italic .strong {
    font-weight: 600;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection {
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    box-sizing: border-box;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .lineBoxDashboardProspection {
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    display: flex;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection {
    margin: 0;
    /* margin-bottom: 20px; */
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection.success::before {
    background-color: var(--color-success);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection.error::before {
    background-color: var(--color-error);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection.focus::before {
    background-color: var(--color-focus);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .titleBoxDashboardPropsection.information::before {
    background-color: var(--color-information);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection h4.titleBoxDashboardPropsection.success {
    color: var(--color-success);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection h4.titleBoxDashboardPropsection.error {
    color: var(--color-error);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection h4.titleBoxDashboardPropsection.focus {
    color: var(--color-focus);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection h4.titleBoxDashboardPropsection.information {
    color: var(--color-information);
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxScroolManager {
    padding-top: 40px;
    overflow: scroll;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxScroolTeam {
    padding-top: 42px;
    overflow: scroll;
    max-width: initial;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection {
    padding: 10px 10px 5px 10px;
    position: relative;
    border: 2px solid var(--color-success);
    border-radius: 5px;
    border-top-left-radius: 0;
    margin-top: 1px;
    margin-bottom: 50px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection {
    flex-direction: column;
    position: relative;
    margin-bottom: 50px;
    min-width: 800px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .attendantsLimitedDynamicTableDashboardProspection .lineHeaderLimitedDynamicTable {
    border-top-left-radius: 0px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .performaceIndexDashboardProspection {
    width: initial;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .performaceIndexDashboardProspection .areaPerformaceIndex {
    margin-left: 0;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .flagSupervisor {
    position: absolute;
    background-color: var(--color-focus);
    height: 40px;
    top: -40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: var(--white-light);
    font-size: 14px;
    min-width: 350px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-transform: uppercase;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .flagManager {
    position: absolute;
    background-color: var(--color-success);
    height: 40px;
    top: -40px;
    left: -2px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: var(--white-light);
    font-size: 14px;
    min-width: 320px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-transform: uppercase;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection {
    background-color: var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-right-radius: 5px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection {
    color: var(--primary-color);
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    width: 7.07%;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection.attendant {
    width: 16%;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection.left {
    justify-content: flex-start;
    padding-left: 20px;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection .help {
    position: absolute;
    top: -40px;
    left: 0px;
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;
    display: none;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .lineBoxTableDashboardProspection .lineHeaderTableDashboardProspection .columnHeaderTableDashboardProspection:hover .help {
    display: block;
}

.dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection.spaceCardDashboardProspection {
    /* border: 2px solid red; */
}

/* WARNING: content displayed on modal, no in the DashboardProspection */
.lineDetailsCardDashboardProspection {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    border-bottom: 1px solid var(--white-dark-two);
}

.lineDetailsCardDashboardProspection .iconDetailsCardDashboardProspection {
    cursor: pointer;
    color: var(--color-focus);
}

@media (max-width: 425px)
{
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxScroolTeam {
        max-width: 66vw;
    }

    .dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection {
        margin: 10px;
    }
}

@media (max-width: 768px)
{
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxScroolTeam {
        max-width: 78vw;
    }

    .dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection {
        display: flex;
        flex-direction: column;
    }

    .dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection .filterCompanyUnitDashboardProspection .selectCompanyUnitDashboardProspection {
        min-width: 200px;
    }

    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxManagerDashboardProspection .flagManager {
        min-width: 300px;
        padding: 6px
    }

    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection.spaceCardDashboardProspection.one,
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection.spaceCardDashboardProspection.three,
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection.spaceCardDashboardProspection.four {
        /* border: 2px solid green; */
        display: none;
    }
}

@media (min-width: 769px)
{
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .boxDashboardProspection .boxScroolTeam {
        max-width: initial;
    }

}

@media (max-width: 1439px)
{
    .dashboardProspection .containerBodyDashboardProspection .lineBodyDashboardProspection .columnBodyDashboardProspection .areaDashboardProspection .avatarDashboardProspection {
        max-width: 120px;
        height: 100%;
    }
}

@media (max-width: 1024px)
{
    /* .dashboardProspection .containerBodyDashboardProspection .boxHeaderFiltersDashboardProspection {
        flex-direction: column;
    } */
}
.messageContent {
    background-color: rgba(0, 0, 0, 0.342);
}

.modal-content { /* bootstrap class */
    background-color: transparent;
    border: none;
}

.messageContent .content {
    border-radius: 20px;
    background-color: var(--white-light);
    overflow: hidden;
}

/* Start styling header */
/* Start styling header */
/* Start styling header */
.messageContent .content .header {
    border-bottom: 2px solid var(--dark-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 30px;
}

.messageContent .content .header .status{
    font-weight: 600;
    font-size: 16px;
}

.messageContent .content .header .status.success{ color: #28a745 }

.messageContent .content .header .status.error{ color: #dc3545 }

.messageContent .content .header .status.warning{ color: #ffc107 }

.messageContent .content .header .status.information{ color: #007bff }

.messageContent .content .header .status .iconStatus{
    margin-right: 10px;
    font-size: 22x;
}

.messageContent .content .header .close{
    border: 1px solid var(--dark-dark);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent!important;
}
.messageContent .content .header .button .iconButton{
    color: var(--dark-dark);
}
/* Ends styling header */
/* Ends styling header */
/* Ends styling header */

/* Start styling body */
/* Start styling body */
/* Start styling body */
.messageContent .content .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: justify;
    padding: 30px;
    flex-direction: column;
}

.messageContent .content .body p {
    font-size: 18px;
    font-weight: 400;
    color: var(--dark-dark);
}

.messageContent .content .body .lineBodyMessageContent {
    width: 100%;
}

.messageContent .content .body .lineBodyMessageContent .columnBodyMessageContent .buttonMessageContent {
    width: 100px;
    font-weight: 400;
    height: 40px;
    font-size: 14px;
    margin: 0;
    background-color: var(--color-error);
}

.messageContent .content .body .lineBodyMessageContent .columnBodyMessageContent .buttonMessageContent.positive {
    background-color: var(--color-success);
}
/* Ends styling body */
/* Ends styling body */
/* Ends styling body */
.limitedDynamicTable {
    position: relative;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable {
    background-color: var(--white-dark-two);
    width: 100%;
    background-color: var(--white-dark-two);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 48px;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable.fixed {
    position: fixed;
    top: 55px;
    z-index: 100;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable.none{
    display: none;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--primary-color);
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable.left {
    justify-content: start;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable.center {
    justify-content: center;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable.right {
    justify-content: end;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable span {
    color: var(--primary-color);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable .help {
    position: absolute;
    top: -40px;
    left: 0px;
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color); 
    color: var(--white-light);
    font-size: 12px;
    display: none;
}

.limitedDynamicTable .lineHeaderLimitedDynamicTable .columnHeaderLimitedDynamicTable:hover .help {
    display: block;
}

.limitedDynamicTable .lineBodyLimitedDynamicTable {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

}

.limitedDynamicTable .lineBodyLimitedDynamicTable:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 30%;

    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 14px;


}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable span.click {
    cursor: pointer;
}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable.left {
    justify-content: start;
}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable.center {
    justify-content: center;
}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable.right {
    justify-content: end;
}

.limitedDynamicTable .lineBodyLimitedDynamicTable .columnBodyLimitedDynamicTable.left {
    justify-content: start;
}

.limitedDynamicTable .lineFooterLimitedDynamicTable {
    height: 32px;
    width: 100%;

    background-color: var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 48px;
}

.limitedDynamicTable .lineFooterLimitedDynamicTable .columnFooterLimitedDynamicTable {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
    color: var(--primary-color);
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
}

.limitedDynamicTable .lineFooterLimitedDynamicTable .columnFooterLimitedDynamicTable.left {
    justify-content: start;
}

.limitedDynamicTable .lineFooterLimitedDynamicTable .columnFooterLimitedDynamicTable.center {
    justify-content: center;
}

.limitedDynamicTable .lineFooterLimitedDynamicTable .columnFooterLimitedDynamicTable.right {
    justify-content: end;
}
.dashboardMarketing {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.dashboardMarketing .headerDashboardMarketing {
    /* nothing */
}

.dashboardMarketing .containerBodyDashboardMarketing {
    position: relative;
    height: calc(100vh - 56px); /*56px by header component*/
    overflow: scroll;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing {
    z-index: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    z-index: inherit;
    margin: 20px 20px 0 20px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    height: 32px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .iconFilterCompanyUnitDashboardMarketing {
    font-size: 14px;
    color: var(--dark-dark);
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing {
    cursor: pointer;
    width: 100%;
    border: none;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing .fieldSelectMulti {
    border: none;
    box-shadow: none;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing .optionsSelectMulti {
    z-index: 200;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .checkSpecificPeriodDashboardMarketing {
    max-width: 220px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .buttonTodayDashboardMarketing {
    width: 50px;
    height: 30px;
    color: var(--color-success);
    background-color: transparent!important;
    border: 1px solid var(--color-success);
    font-size: 10px;
    margin: 0 5px;
    border-radius: 2px;
    padding: 0;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .buttonSearchReportDashboardMarketing {
    background-color: transparent!important;
    color: var(--color-success);
    height: inherit;
    margin-left: 10px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing.filtersDashboardMarketing .inputFilterDashboardMarketing {
    width: 180px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border: 1px solid var(--white-dark-two);
    height: 32px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing.filtersDashboardMarketing .inputTimeFilterDashboardMarketing {
    width: 80px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing.filtersDashboardMarketing .inputTimeFilterDashboardMarketing .fieldInputGeneral {
    padding: 4px 8px;
}

.dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing.filtersDashboardMarketing .inputFilterDashboardMarketing .react-date-picker__wrapper {
    border: none!important;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing {
    margin: 20px 20px 0 20px;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
    color: var(--color-information);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-information);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing .boxHeaderDashboardMarketing {
    width: 100%;
    z-index: 0;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing .boxHeaderDashboardMarketing.filterDashboardMarketing {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineHeaderDashboardMarketing .boxHeaderDashboardMarketing.filterDashboardMarketing .inputFilterDashboardMarketing {
    width: 180px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing {
    /* nothing here */
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing {
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .cardInfoLeftChartDashboardMarketing.exclusive .columnChartCardInfoLeftChart {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    align-items: flex-start;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .cardInfoLeftChartDashboardMarketing {
    min-height: 296px!important;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .areaDashboardMarketing {
    max-width: 200px;
    margin: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .areaDashboardMarketing .avatarDashboardMarketing {
    border: 2px solid var(--color-success);
    border-radius: 50%;
    overflow: hidden;
    max-width: 140px;
    height: 140px;
} 

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .itemInfoLeftChartDashboardMarketing {
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 300;
    line-height: 22px;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .itemInfoLeftChartDashboardMarketing .featured {
    font-weight: 600;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .labelsCardChartPieDashboardMarketing {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .labelsCardChartPieDashboardMarketing.italic {
    font-style: italic;
    font-weight: 300;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .labelsCardChartPieDashboardMarketing.italic .strong {
    font-weight: 600;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing {
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    box-sizing: border-box;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .lineBoxDashboardMarketing {
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    display: flex;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing {
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing.success::before {
    background-color: var(--color-success);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing.error::before {
    background-color: var(--color-error);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing.focus::before {
    background-color: var(--color-focus);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .titleBoxDashboardMarketing.information::before {
    background-color: var(--color-information);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing h4.titleBoxDashboardMarketing.success {
    color: var(--color-success);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing h4.titleBoxDashboardMarketing.error {
    color: var(--color-error);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing h4.titleBoxDashboardMarketing.focus {
    color: var(--color-focus);
}

.dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing h4.titleBoxDashboardMarketing.information {
    color: var(--color-information);
}

/* WARNING: content displayed on modal, no in the DashboardMarketing */
.lineDetailsCardDashboardMarketing {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    border-bottom: 1px solid var(--white-dark-two);
}

.lineDetailsCardDashboardMarketing .iconDetailsCardDashboardMarketing {
    cursor: pointer;
    color: var(--color-focus);
}

@media (max-width: 425px)
{
    .dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .boxScroolTeam {
        max-width: 66vw;
    }

    .dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing {
        min-width: 120px;
        max-width: 120px;
    }

}

@media (max-width: 768px)
{
    .dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .boxScroolTeam {
        max-width: 78vw;
    }

    .dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing {
        display: flex;
        flex-direction: column;
    }

    .dashboardMarketing .containerBodyDashboardMarketing .boxHeaderFiltersDashboardMarketing .filterCompanyUnitDashboardMarketing .selectCompanyUnitDashboardMarketing {
        min-width: 200px;
    }

}

@media (min-width: 769px)
{
    .dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .boxDashboardMarketing .boxScroolTeam {
        max-width: initial;
    }

}

@media (max-width: 1439px)
{
    .dashboardMarketing .containerBodyDashboardMarketing .lineBodyDashboardMarketing .columnBodyDashboardMarketing .areaDashboardMarketing .avatarDashboardMarketing {
        max-width: 120px;
        height: 120px;
    }
}
.cardResumeWithHover {
    width: 220px; /*DEFAULT: 220px*/
    height: 96px; /*DANGER!!! DEFAULT: 96px*/
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px var(--dark-dark-three);
    overflow: scroll;
    background-color: var(--white-light);
}

.cardResumeWithHover:hover {
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    transition: box-shadow 0.8s;
}

.cardResumeWithHover .headCardResumeWithHover {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    position: relative;
    height: 40px;
}

.cardResumeWithHover .headCardResumeWithHover .iconCardResumeWithHover {
    font-size: 34px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    color: var(--dark-dark);
}

.cardResumeWithHover .headCardResumeWithHover .titleCardResumeWithRouter {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    color: var(--dark-dark);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.cardResumeWithHover .bodyCardResumeWithHover {
    /* nothing */
}

.cardResumeWithHover .bodyCardResumeWithHover .buttonCardResumeWithRouter {
    margin-top: 5px;
    background-color: var(--dark-dark);
    padding: 4px 8px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.cardResumeWithHover .bodyCardResumeWithHover .buttonCardResumeWithRouter.success {
    background-color: var(--color-success);
}

.cardResumeWithHover .bodyCardResumeWithHover .buttonCardResumeWithRouter.error {
    background-color: var(--color-error);
}

.cardResumeWithHover .bodyCardResumeWithHover .buttonCardResumeWithRouter.focus {
    background-color: var(--color-focus);
}

.cardResumeWithHover .bodyCardResumeWithHover .buttonCardResumeWithRouter .labelButtonCardResumeWithRouter {
    color: var(--white-dark);
    font-size: 12px;
    font-weight: 400;
}

.cardResumeWithHover .hoverContentBodyCardResumeWithRouter {
    /* nothing */
}
.cardButonsTopLineChart {
    padding: 20px;
    margin: 10px 18px 10px 18px;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    box-sizing: border-box;
    position: relative;
}

.cardButonsTopLineChart .filterLoadingCardButtonsTopLineCharts {
    background-color: rgba(0, 0, 0, 0.396);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}

.cardButonsTopLineChart .filterLoadingCardButtonsTopLineCharts .loadingCardButtonsTopLineChart {
    background-color: transparent;
}

.cardButonsTopLineChart .lineGeneralCardButonsTopLineChart {
    position: relative;
    display: flex;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart {
    
}

.cardButonsTopLineChart .lineCardButonsTopLineChart.chart {
}

.cardButonsTopLineChart .lineCardButonsTopLineChart.buttons {
    display: flex;
    overflow: scroll;
    padding-left: 5px;
    padding-right: 5px;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .columnCardButonsTopLineChart {
    flex-direction: row;
    display: flex;
    width: 0;
    padding-left: 30px; /* same value of the width buttonCarousel */
    padding-right: 30px; /* same value of the width buttonCarousel */
}

.cardButonsTopLineChart .lineGeneralCardButonsTopLineChart .buttonCarouselCardButtonsTopLineChart {
    width: 0px; 
    height: 120px;
    padding: 10px;
    background-color: var(--white-dark-three);
    margin-left: 5px;
    margin-right: 5px;
}

.cardButonsTopLineChart .lineGeneralCardButonsTopLineChart .buttonCarouselCardButtonsTopLineChart.previous {
    top: 0;
    left: 0;
}

.cardButonsTopLineChart .lineGeneralCardButonsTopLineChart .buttonCarouselCardButtonsTopLineChart.next {
    top: 0;
    right: 0;
}

.cardButonsTopLineChart .lineGeneralCardButonsTopLineChart .buttonCarouselCardButtonsTopLineChart .iconButtonIcon {
    color: var(--primary-color);
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
    flex-direction: column;
    min-width: 140px!important;
    height: 120px;
    padding: 10px 15px 0 15px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart::before {

}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart:hover {
    background-color: var(--white-dark-three);
    transition: background-color 1s;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart .labelButtonToggleCardButtonsTopLineChart {
    font-size: 16px;
    color: var(--dark-dark-three);
    font-weight: 400;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active .labelButtonToggleCardButtonsTopLineChart {
    font-weight: 600;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart .amountButtonToggleCardButtonsTopLineChart {
    font-size: 15px;
    color: var(--dark-dark-three);
    font-weight: 400;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active .amountButtonToggleCardButtonsTopLineChart.success {
    color: var(--color-success);
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active .amountButtonToggleCardButtonsTopLineChart.information {
    color: var(--color-information);
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart .performanceButtonToggleCardButtonsTopLLineChart {
    margin: 0;
    margin-left: -16px;
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active::before {
    position: absolute;
    top: 0px;
    left: 20px;
    display: block;
    content: " ";
    width: 100px;
    height: 7px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--color-information);
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active .labelButtonToggleCardButtonsTopLineChart {
    color: var(--color-information);
}

.cardButonsTopLineChart .lineCardButonsTopLineChart .buttonToggleCardButtonsTopLineChart.active .amountButtonToggleCardButtonsTopLineChart {
    color: #000;
}
.customTooltipLineCharts {
    background-color: #fff;
    padding: 20px;
    padding-left: 30px;
    border-radius: 5px;
    min-width: 320px;
    min-height: 140px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
}

.customTooltipLineCharts .label {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 300;
}

.customTooltipLineCharts .lineCustomTooltipLineCharts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.customTooltipLineCharts .lineCustomTooltipLineCharts .emptyCustomToolTipLineCharts {
    content: " ";
    display: block;
    min-width: 122px;
}


.customTooltipLineCharts .lineCustomTooltipLineCharts .title {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 400;
    margin-right: 10px;
}

.customTooltipLineCharts .lineCustomTooltipLineCharts .title.success {
    color: var(--color-success);
}

.customTooltipLineCharts .lineCustomTooltipLineCharts .title.information {
    color: var(--color-information);
}

.customTooltipLineCharts .lineCustomTooltipLineCharts .amount {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 400;
}

.customTooltipLineCharts .lineCustomTooltipLineCharts .performanceCustomTooltipLineCharts {
    max-width: 120px;
}

.debug {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.debug .warningDebug {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.debug .scrollDebug {
    overflow: scroll;
    height: 100%;
}

.debug .headerDebug {
    margin-top: 20px;
}

.debug .headerDebug h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.debug .headerDebug h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}

.debug .bodyDebug  {
    margin-bottom: 250px;
}

.debug .bodyDebug .boxDebug {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.debug .bodyDebug.error {
    background-color: var(--color-error);
    color: #fff;
    padding: 8px 12px;
    border-radius: 5px;
    text-align: center;
}

.debug .bodyDebug .boxDebug .lineDebug {
    width: 100%;
}

.debug .bodyDebug .boxDebug .lineDebug .columnDebug {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.debug .bodyDebug .boxDebug .lineDebug .columnDebug .inputStringSQLDebug .fieldInputGeneral {
    width: 100%;
}

.debug .bodyDebug .boxDebug.input {
    border: 2px solid var(--color-error);
}

.debug .bodyDebug .boxDebug.processing {
    border: 2px solid var(--color-warning);
}

.debug .bodyDebug .boxDebug.output {
    border: 2px solid var(--color-success);
}

.debug .bodyDebug .boxDebug .inputAttributeDebug  {
    max-width: 300px;
}

.debug .bodyDebug .boxDebug span {
    margin: 10px 20px;
}

.debug .bodyDebug .boxDebug .checkApplyTreatmentDebug .squareCheck {
    border-radius: 50%;
}

.debug .bodyDebug .buttonDebug {
    width: 180px;
    font-weight: 400;
    font-size: 16px;
    margin: 10px;
}

.debug .bodyDebug .buttonDebug.error {
    background-color: var(--color-error);
}

.debug .bodyDebug .boxDebug .treatmentOptionsDebug {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.chat {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.chat .headerCompanyPreferences {
    margin-top: 20px;
}

.chat .headerChat {
    border: 2px solid red;
}

.chat .bodyChat {
    background-color: transparent;
    height: calc(100vh - 56px - 56px); /* 56px by header component and 100px footer section*/
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 100%;
    position: relative;
}

.chat .bodyChat .boxChat {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    min-width: 400px;
}

.chat .bodyChat .boxChat .labelChat {
    margin-top: 20px;
}

.chat .buttonChat {
    width: 100px;
    font-size: 14px;
    padding: 8px 12px;
    margin: 10px;
}

.chat .inputChat {
    max-width: 250px;
}

.chat .bodyChat {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    height: calc(100vh - 56px);
}

.chat .bodyChat .brandChat {
    color: var(--color-focus);
    font-size: 60px;
    margin-bottom: 20px;
}

.chat .footerChat {
    height: 56px;
    background-color: var(--white-light);
    z-index: 20;
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 1px 1px, rgb(0 0 0 / 6%) 0px 1px 2px -2px, rgb(0 0 0 / 7%) 0px 1px 3px 0px;
    color: var(--primary-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
.containerToastCustom {
    width: 220px;
    position: absolute;
    top: 20px;
    right: 20px;
    overflow: scroll;
    height: 100vh;
}

.containerToastCustom .toastCustom {
    position: relative;
    padding: 5px;
    padding-left: 10px;
    border-radius: 2px;
    color: var(--white-dark);
    font-size: 14px;
    color: var(--dark-dark);
    z-index: 200;
    margin: 5px 0px;
    min-height: 60px;
    background-color: var(--white-dark-three);
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
}

.containerToastCustom .toastCustom::before {
    display: block;
    content: " ";
    width: 4px;
    height: 10px;
    background-color: var(--color-information);
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.containerToastCustom .toastCustom.success::before {
    background-color: var(--color-success);
}

.containerToastCustom .toastCustom.information::before {
    background-color: var(--color-information);
}

.containerToastCustom .toastCustom.error::before {
    background-color: var(--color-error);
}

.containerToastCustom .toastCustom.warning::before {
    background-color: var(--color-warning);
}
.room {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.room .headerCompanyPreferences {
    margin-top: 20px;
}

.room .headerRoom {
    border: 2px solid red;
}

.room .bodyRoom {
    background-color: transparent;
    height: calc(100vh - 56px - 56px); /* 56px by header component and 100px footer section*/
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 100%;
    position: relative;
    background-color: var(--white-dark-two);
}

.room .buttonRoom {
    width: 100px;
    font-size: 14px;
    padding: 8px 12px;
    margin: 10px;
}

.room .buttonIconRoom {
    border-radius: 50%;
    margin: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #404346;
    z-index: 300;
}

.room .buttonIconRoom.maximum {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: none;
}

.room .buttonIconRoom.messages {
    position: absolute;
    top: 20px;
    right: 20px;
}

.room .buttonIconRoom.close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.room .inputRoom {
    max-width: 250px;
}

.room .bodyCreateRoom {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    height: calc(100vh - 56px);
}

.room .bodyRoom .areaVideosRoom {
    width: 70%;
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: var(--white-light);
    border-radius: 5px;
    position: relative;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: transparent;
    position: relative;
    display: flex;
    justify-content: center;
    max-height: 300px;
    width: -webkit-max-content;
    width: max-content;
    margin: 5px;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat:hover .buttonIconRoom.maximum {
    display: flex;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat.hidden {
    display: none;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat .nameRemoteUserChat {
    position: absolute;
    top: 0;
    background: rgba(0,0,0,0.6);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: var(--white-light);
    text-align: center;
    padding: 4px;
    z-index: 300;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat .videoRemoteUserChat {
    background-color: transparent;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat .videoRemoteUserChat video {
    height: 100%;
    max-width: 250px;
    object-fit: cover;
    border-radius: 5px;
}

.room .bodyRoom .areaVideosRoom .videoStreamChat .videoRemoteUserChat video.mySelf {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}

.room .bodyRoom .areaMessagesRoom {
    width: 30%;
    background-color: var(--white-light);
    border-radius: 5px;
    margin-left: 10px;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom {
    height: calc(100% - 60px);
    overflow: scroll;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem {
    display: flex;
    flex-direction: column;
    height: -webkit-max-content;
    height: max-content;
    width: -webkit-max-content;
    width: max-content;
    max-width: 80%;
    margin: 5px;
    padding: 10px;
    word-wrap: break-word;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem .author {
    background-color: transparent;
    font-size: 14px;
    color: var(--dark-dark);
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem .content {
    background-color: transparent;
    color: #000;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem.left {
    margin-right: auto;
    background-color: #dc35468d;
    border-radius: 5px;
    border-top-left-radius: 0;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem.right {
    margin-left: auto;
    background-color: #28a74681;
    border-radius: 5px;
    border-top-right-radius: 0;
}

.room .bodyRoom .areaMessagesRoom .messagesRoom .messageItem.hidden {
    display: none;
}    

.room .bodyRoom .areaMessagesRoom .sendMessagesRoom {
    flex-direction: row;
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border-top: 4px solid var(--white-dark-two);
}

.room .bodyRoom .areaMessagesRoom .sendMessagesRoom .inputMessagesRoom {
    height: 100%;
    width: calc(100% - 56px);
}

.room .bodyRoom .areaMessagesRoom .sendMessagesRoom .inputMessagesRoom .inputInputGeneral {
    height: 100%;
}

.room .bodyRoom .areaMessagesRoom .sendMessagesRoom .inputMessagesRoom .inputInputGeneral .fieldInputGeneral {
    height: 100%;
    border: none;
    box-shadow: none;
    outline: none;
    border-bottom-left-radius: 5px;
}

.room .footerRoom {
    height: 56px;
    background-color: var(--white-light);
    z-index: 20;
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 1px 1px, rgb(0 0 0 / 6%) 0px 1px 2px -2px, rgb(0 0 0 / 7%) 0px 1px 3px 0px;
    color: var(--primary-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.room .modalMaximumStream {
    height: calc(100vh - 56px);
    width: calc(100vw - 56px);
    background-color: var(--white-dark-two);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    position: relative;
}

.room .modalMaximumStream.hidden {
    display: flex;
}

.room .modalMaximumStream.hidden {
    display: none;
}

.room .modalMaximumStream video {
    border-radius: 5px;
}

.room .modalMaximumStream .nameMaximumStream {
    font-size: 18px;
    color: #404346;
    position: absolute;
    top: 20px;
    left: 20px;
}

.room .modalMaximumStream .videoMaximumStream.mySelf {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); 
}

@media (max-width: 767px)
{
    .room .bodyRoom {
        height: calc(100vh - 56px - 56px - 56px);
    }

    .room .bodyRoom .areaVideosRoom {
        display: none!important;
    }

    .room .bodyRoom .areaMessagesRoom {
        width: 100%!important;
        display: block!important;
    }
}
.training {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.training .scrollTraining {
    overflow: scroll;
    height: 100%;
}

.training .headerTraining {
    margin-top: 20px;
}

.training .headerTraining h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.training .headerTraining h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.training .bodyTraining .materialTraining {
    border-bottom: 1px solid var(--dark-light);
    padding: 10px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.training .bodyTraining .materialTraining:last-of-type {
    border-bottom: none;
}

.training .bodyTraining .materialTraining .iconTraining {
    font-size: 18px;
    color: var(--color-information);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 5px;
}

.training .bodyTraining .materialTraining .nameTraining {
    color: var(--color-information);
    font-size: 18px;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;
}

.training .bodyTraining .materialTraining .nameTraining:hover {
    color: var(--color-success);
    transition: 2s color;
}

.training .bodyTraining .materialTraining .dateTraining {
    right: 30px;
    color: var(--dark-dark-three);
    position: absolute;
    font-weight: 300;
}
.detailsService {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.detailsService .warningDetailsService {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.detailsService .headerNavigationDetailsService {
}

.select.servicesConfirmationDetailsService .optionsSelect { /* WARNING: Don't have reference with .detailsService, because is modal*/
    position: relative;
}

.detailsService .scrollDetailsService {
    overflow: scroll;
    height: 100%;
}

/* start styles header */
/* start styles header */
/* start styles header */
.detailsService .headerDetailsService {
    background-color: var(--white-light);
    padding: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e5e5e5;
    margin-top: 2px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService {
    margin-bottom: 16px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService {
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalEditTitleDetailsService {
    position: absolute;
    top: 30px;
    width: 300px;
    height: 140px;
    z-index: 800;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalMoreActionsDetailsService {
    position: absolute;
    top: 50px;
    z-index: 800;
    width: 250px;
    min-height: 120px;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0.1px solid var(--white-dark-two);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalMoreActionsDetailsService::after {
    width: 18px;
    height: 18px;
    background-color: var(--white-light);
    display: block;
    content: " ";
    position: absolute;
    top: -9px;
    right: 10px;
    z-index: 700;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    border: none;
    border-left: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    /* box-shadow: 0px 0px 7px 1px var(--white-dark-two); */
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalMoreActionsDetailsService .buttonMoreActionsDetailsService {
    border: none;
    font-size: 15px;
    background-color: transparent;
    color: var(--dark-dark);
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    font-weight: 400;
    padding: 8px 20px;
    width: 100%;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalMoreActionsDetailsService .buttonMoreActionsDetailsService:hover {
    padding: 0;
    padding: 8px 20px;
    background-color: var(--color-focus);
    color: var(--white-light);
    transition: none;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .modalEditMoneyDetailsService {
    position: absolute;
    top: 80px;
    width: 300px;
    height: 140px;
    z-index: 800;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .titleHeaderInfoDetailsService {
    color: var(--color-focus);
    font-size: 27px;
    font-weight: 400;
    display: initial;
    cursor: pointer;
    margin: 0;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .titleHeaderInfoDetailsService:hover {
    background-color: #fffcdc;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .nameStampHeaderDetailsService { 
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .nameStampHeaderDetailsService.warning {
    color: black;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .modalEditStampDetailsService { 
    position: absolute;
    top: 30px;
    width: 300px;
    min-height: 140px;
    z-index: 800;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .notificationStampDetailsService{
    display: none;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService:hover .notificationStampDetailsService{
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    top: -25px;
    min-width: 60px;
    z-index: 600;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: block;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .buttonStampHeaderDetailsService {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .buttonStampHeaderDetailsService:hover {
    background-color: var(--white-dark);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService {
    margin-top: 12px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: flex-start;
    line-height: 30px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .moneyInfoDetailsService {
    line-height: 24px;
    font-weight: 400;
    font-size: 23px;
    color: var(--primary-color);
    cursor: pointer;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .moneyInfoDetailsService:hover {
    background-color: #fffcdc;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService::before {
    content: " ";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 1px;
    min-height: 25px;
    margin-left: 16px;
    margin-right: 16px;
    background-color: var(--white-dark-two);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService .iconUserMoreInfoDetailsService {
    font-size: 18px;
    color: #404346;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService .nameUserMoreInfoDetailsService {
    color: var(--primary-color);
    margin-left: 6px;
    font-size: 16px;
    font-weight: 400;
    
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService .buttonPlusBusinessMoreInformationDetailsService.positive {
    min-width: 50px;
    max-width: 120px;
    margin: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    color: var(--white-light);
    border: 0;
    border: none;
    border-radius: 2px;
    margin-top: 0;
    padding: 0 10px;
    margin-bottom: 0px;

}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService {
    min-width: 32px;
    max-width: 100px;
    margin: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    background: var(--white-light);
    color: var(--white-light);
    border: 0;
    border: none;
    border-radius: 2px;
    margin-top: 0;
    padding: 0 4px;
    margin-bottom: 0px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService:hover {
    opacity: 0.8;
    transition: opacity 0.8s;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService.positive {
    background-color: var(--color-success);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService.negative {
    background-color: var(--color-error);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService.primary {
    background-color: var(--color-focus);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService.warning {
    background-color: var(--color-warning);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonStatusHeaderInfoDetailsService.default {
    background-color: var(--white-light);
    color: var(--dark-dark);
    border: 1px solid var(--dark-light);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonMoreActionsHeaderInfoDetailsService {
    height: 32px;
    width: 32px;
    background: #fff;
    border: 0;
    border-radius: 2px;
    margin: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    color: var(--primary-color);
    border: 1px solid #cbcccd;

}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .buttonMoreActionsHeaderInfoDetailsService:hover {
    background-color: var(--white-dark);
    transition: background-color .12s;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .dividerMoreActionsDetailsService {
    width: 100%;
    height: 1px;
    background-color: var(--white-dark-two);
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .avatarOwnerHeaderDetailsService {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--white-dark-two);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .nameOwnerHeaderDetailsService {
    padding: 0;
    margin: 0 5px 0 10px;
    line-height: 18px;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .notificationOwnerDetailsService {
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    top: -25px;
    min-width: 60px;
    z-index: 600;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: none;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService:hover .notificationOwnerDetailsService {
    display: block;    
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .nameOwnerHeaderDetailsService .modalEditOwnerDetailsService{
    position: absolute;
    top: 40px;
    left: 0;
    width: 300px;
    height: 140px;
    z-index: 800;
    text-align: left;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .nameOwnerHeaderDetailsService .anchorNameOwnerHeaderDetailsService {
    padding: 0;
    margin: 0;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .nameOwnerHeaderDetailsService .anchorNameOwnerHeaderDetailsService:hover {
    text-decoration: underline!important;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .nameOwnerHeaderDetailsService .levelNameOwnerHeaderDetailsService {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-light);
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .buttonEditOwnerHeaderDetailsService {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    margin-right: 10px;
}

.detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .editOwnersHeaderInfoDetailsService .buttonEditOwnerHeaderDetailsService:hover {
    background-color: var(--white-dark);
}

/* ends styles header */
/* ends styles header */
/* ends styles header */


/* start styles body */
/* start styles body */
/* start styles body */
.detailsService .bodyDetailsService {
    height: 100%;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService {
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .headerBoxOthersBodyDetailsService {
    border-bottom: 1px solid #e5e5e5;
    display: inline-flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    padding-bottom: 16px;
    width: 100%;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .headerBoxOthersBodyDetailsService .buttonEditPersonOthersDetailsService {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    color: var(--primary-color);
    border: 1px solid var(--white-dark-two);
    padding: 4px;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .headerBoxOthersBodyDetailsService .buttonEditPersonOthersDetailsService:hover {
    background-color: var(--white-dark);
}


.detailsService .bodyDetailsService .boxOthersBodyDetailsService .headerBoxOthersBodyDetailsService .subtitleOthersDetailsService {
    font-size: 13px;
    color: #721ea9;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    display: block;
    
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .lineBodyOthersDetailsService {
    margin: 8px 0;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colOne {
    padding-right: 10px;
    display: flex;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colOne .avatarClientOthersDetailsService {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--color-focus);
    fill: var(--color-focus);
    border: 2px solid rgba(38,41,44,.64);
    box-sizing: border-box;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colOne span {
    font: 400 13px/16px Source Sans Pro,sans-serif;
    font-weight: 600;
    color: rgba(38,41,44,.64);
    text-align: right;
    word-wrap: break-word;
    margin: 2px 0;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colTwo {
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colTwo span {
    overflow-wrap: anywhere;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .buttonBodyOthersDetailsService {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .buttonBodyOthersDetailsService:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .buttonBodyOthersDetailsService.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
    margin-left: 10px;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .buttonBodyOthersDetailsService.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colTwo span.nameClientBodyOthersDetailsService{
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .colTwo span {
    color: var(--primary-color);
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService span.minumus {
    color: #747678;
    font-size: 13px;
    line-height: 9.2px;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService.general {
    margin-top: 15px;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .progressOthersDetailsService {
    width: 100%;
    display: block;
    content:  " ";
    background-color: var(--color-success);
    border-radius: 120px;
    height: 16px;
    margin-bottom: 10px;
}

.detailsService .bodyDetailsService .boxOthersBodyDetailsService .bodyBoxOthersBodyDetailsService .lineBodyOthersDetailsService .progressOthersDetailsService:hover {
    -webkit-transform: scaleX(1.2);
            transform: scaleX(1.2);
    -webkit-transform: scaleY(1.3);
            transform: scaleY(1.3);
}

.detailsService .bodyDetailsService .boxActivitiesBodyDetailsService {
    padding: 0;
}

.detailsService span.businessStatus {
    height: 32px;
    font-weight: 700;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 20px;
    color: var(--white-light);
}

.detailsService span.businessStatus.danger {
    background-color: var(--color-error);
}

.detailsService span.businessStatus.success {
    background-color: var(--color-success);
}

.detailsService .bodyDetailsService .menusActivitiesDetailsService {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}

.detailsService .bodyDetailsService .boxActivitiesHistoricBodyDetailsService {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    /* margin-bottom: 200px;
    min-height: 1000px; */
}

.detailsService .bodyDetailsService .boxActivitiesHistoricBodyDetailsService .statusActivitiesDetailsService {
    background-color: #747678;
    border-radius: 180px;
    color: white;
    padding: 1px 20px;
    margin: 10px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
}


.detailsService .buttonLinkDetailsService {
    background-color: #FFF;
    color: #317AE2;
    font-weight: 400;
    padding: 0;
    font-size: 16px;
    justify-content: flex-start;
}

.detailsService .buttonActivitiesBusinessRecovered {
    font-size: 16px;
    background-color: var(--color-focus);
}

.detailsService .titleActivitiesBusinessRecovered {
    font-size: 13px;
    color: #721ea9;
    font-weight: 600;
    text-transform: uppercase;
}

.detailsService .remittance-status {
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    margin-top: 5px;
    cursor: pointer;
}

/* ends styles body */
/* ends styles body */
/* ends styles body */


@media (min-width: 767px) and (max-width: 1024px)
{
    .detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineTopMoreInfoDetailsService .columnEditStampHeaderDetailsService .modalEditStampDetailsService {
        position: fixed;
        top: 30px;
        left: 60px;
    }
}

@media (max-width: 425px)
{
    .detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService {
        flex-direction: column;        
    }

    .detailsService .headerDetailsService .lineHeaderInfoDetailsService .columnHeaderInfoDetailsService .lineBottomMoreInfoDetailsService .ownersInfoDetailsService::before {
        display: none;
    }
}
.service {
    height: 100vh;
    position: relative;
    overflow: hidden;
    padding-bottom: 100px;
}

.service .scrollService {
    overflow: scroll;
    height: 100%;
    margin-bottom: 300px;
}
.personOnline {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.personOnline .amountPersonOnline {
    width: 170px!important;
    font-size: 16px;
    color: var(--dark-dark);
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.personOnline .amountPersonOnline:before {
    display: block;
    content: " ";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-success);
    margin-right: 10px;
}

.personOnline .scrollPersonOnline {
    overflow: scroll;
    height: 100%;
    margin-bottom: 300px;
}

.personOnline .headerPersonOnline {
    margin-top: 20px;
}

.personOnline .headerPersonOnline h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.personOnline .headerPersonOnline h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-focus);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.personOnline .bodyPersonOnline {
    margin-bottom: 300px;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid transparent;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline.header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline.header .minimum {
    font-weight: 600;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline.header .minimum.right {
    justify-content: flex-end;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline .minimum {
    width: 150px;
    font-size: 16px;
    color: var(--dark-dark);
    display: flex;
    align-items: center;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline .minimum.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline.body .minimum.right {
    justify-content: flex-end;
}

.personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline.body .minimum:first-child::before {
    display: block;
    content: " ";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-success);
    margin-right: 10px;
}

@media (max-width: 767px) {
    .personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline .minimum.name {
        margin: 0 10px;
        max-width: 100px;
    }

    .personOnline .bodyPersonOnline .boxTablePersonOnline .socketMainPersonOnline .minimum {
        width: initial;
    }
}
.serviceQueuePreSale {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.serviceQueuePreSale .warningServiceQueuePreSale {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.serviceQueuePreSale .scrollServiceQueuePreSale {
    overflow: scroll;
    height: 100%;
}

.serviceQueuePreSale .headerServiceQueuePreSale {
    margin-top: 20px;
}

.serviceQueuePreSale .headerServiceQueuePreSale h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.serviceQueuePreSale .headerServiceQueuePreSale h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-error);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}

.serviceQueuePreSale .bodyServiceQueuePreSale  {
    /* nothing */
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineTitleHeaderServiceQueuePreSale {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineTitleHeaderServiceQueuePreSale .columnTitleHeaderServiceQueuePreSale {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineBodyServiceQueuePreSale {
    background-color: hsla(134, 61%, 50%, 0.527);
    cursor: -webkit-grab;
    cursor: grab;
    border-bottom: 1px solid ;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineBodyServiceQueuePreSale .companyUnitServiceQueuePreSale {
    border: 2px solid var(--dark-dark-three);
}


.serviceQueuePreSale .bodyServiceQueuePreSale .lineCompanyUnitServiceQueuePreSale .buttonRefreshServiceQueuePreSale {
    background-color: transparent;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineCompanyUnitServiceQueuePreSale .buttonRefreshServiceQueuePreSale .iconButtonIcon {
    color: var(--color-success);
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineCompanyUnitServiceQueuePreSale .filterBodyServiceQueuePreSale {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    margin-left: auto;
    margin-right: auto;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineCompanyUnitServiceQueuePreSale .filterBodyServiceQueuePreSale .iconFilterBodyServiceQueuePreSale {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineCompanyUnitServiceQueuePreSale .filterBodyServiceQueuePreSale .selectFilterBodyServiceQueuePreSale {
    width: 100%;
    border: none;
    cursor: pointer;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineCompanyUnitServiceQueuePreSale .filterBodyServiceQueuePreSale .selectFilterBodyServiceQueuePreSale .optionsSelect {
    min-width: 200px;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineCompanyUnitServiceQueuePreSale .filterBodyServiceQueuePreSale .selectFilterBodyServiceQueuePreSale .textShowSelect {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineCompanyUnitServiceQueuePreSale .filterBodyServiceQueuePreSale .selectFilterBodyServiceQueuePreSale .fieldSelect {
    border: none;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineBodyServiceQueuePreSale.final {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineBodyServiceQueuePreSale.frozen {
    background-color: hsla(354, 70%, 50%, 0.493);
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineBodyServiceQueuePreSale .columnBodyServiceQueuePreSale {
    padding: 0;
    padding-right: 20px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    min-height: 40px;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineBodyServiceQueuePreSale .columnBodyServiceQueuePreSale.companyUnit {
    display: flex;
    justify-content: flex-start;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineBodyServiceQueuePreSale .columnBodyServiceQueuePreSale.companyUnit .partialCompanyUnitServiceQueuePreSale {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineBodyServiceQueuePreSale .columnBodyServiceQueuePreSale.companyUnit .fullCompanyUnitServiceQueuePreSale {
    display: none;
    background-color: var(--dark-dark-two);
    color: var(--white-dark);
    position: absolute;
    padding: 5px 10px;
    border-radius: 5px;
    top: -10px;
    left: 0;
}

.serviceQueuePreSale .bodyServiceQueuePreSale .lineBodyServiceQueuePreSale .columnBodyServiceQueuePreSale.companyUnit:hover .fullCompanyUnitServiceQueuePreSale {
    display: inline-flex;
}

.serviceQueuePreSale .footerServiceQueuePreSale {
    margin-bottom: 250px;
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineFooterServiceQueuePreSale {
    width: 100%;
    border-bottom: 1px solid;
    padding-top: 10px;
    padding-bottom: 10px;
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineFooterServiceQueuePreSale.success {
    background-color: hsla(134, 61%, 50%, 0.527);
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineFooterServiceQueuePreSale.error {
    background-color: hsla(354, 70%, 50%, 0.493);
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineFooterServiceQueuePreSale .columnFooterServiceQueuePreSale {
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineTitleFooterServiceQueuePreSale {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineTitleFooterServiceQueuePreSale .columnTitleFooterServiceQueuePreSale {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineFooterServiceQueuePreSale .columnFooterServiceQueuePreSale span {
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineFooterServiceQueuePreSale .columnFooterServiceQueuePreSale.reason {
    position: relative;
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineFooterServiceQueuePreSale .columnFooterServiceQueuePreSale .partialReasonServiceQueuePreSale {
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    text-overflow: ellipsis;
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineFooterServiceQueuePreSale .columnFooterServiceQueuePreSale .fullReasonServiceQueuePreSale {
    display: none;
    cursor: default;
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineFooterServiceQueuePreSale .columnFooterServiceQueuePreSale.reason:hover .fullReasonServiceQueuePreSale {
    display: block;
    background-color: var(--dark-dark-two);
    color: var(--white-dark);
    position: absolute;
    padding: 5px 10px;
    border-radius: 5px;
    top: -40px;
    left: 0;
}

.serviceQueuePreSale .footerServiceQueuePreSale .lineFooterServiceQueuePreSale:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.absenceAttendants {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .buttonNavigationHeaderAbsenceAttendants {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .buttonNavigationHeaderAbsenceAttendants:hover {
    background-color: var(--white-dark);
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .buttonNavigationHeaderAbsenceAttendants:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .buttonNavigationHeaderAbsenceAttendants:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .buttonNavigationHeaderAbsenceAttendants.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .buttonPlusNavigationHeaderAbsenceAttendants {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .filterStatusHeaderAbsenceAttendants {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .filterPeriodHeaderAbsenceAttendants {
    padding-left: 0;
    padding-right: 0;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .filterStatusHeaderAbsenceAttendants .buttonStatusHeaderAbsenceAttendants {
    font-size: 16px;
    background-color: transparent;
    padding: 4px 8px;
    text-align: center;
    white-space: nowrap;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    color: var(--primary-color);
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .filterStatusHeaderAbsenceAttendants .inputFilterAbsenceAttendants .fieldInputGeneral {
    border: none!important;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .filterStatusHeaderAbsenceAttendants .iconfilterStatusHeaderAbsenceAttendants {
    font-size: 14px;
    color: var(--dark-dark);
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .filterStatusHeaderAbsenceAttendants .selectStatusHeaderAbsenceAttendants {
    cursor: pointer;
    width: 100%;
    border: none;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .filterStatusHeaderAbsenceAttendants .selectStatusHeaderAbsenceAttendants .textShowSelect,
.absenceAttendants .containerNavigationHeaderAbsenceAttendants .filterStatusHeaderAbsenceAttendants .selectStatusHeaderAbsenceAttendants .textShowSelectMulti
 {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .filterStatusHeaderAbsenceAttendants .selectStatusHeaderAbsenceAttendants .fieldSelect,
.absenceAttendants .containerNavigationHeaderAbsenceAttendants .filterStatusHeaderAbsenceAttendants .selectStatusHeaderAbsenceAttendants .fieldSelectMulti
 {
    border: none;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .dropIntervalsHeaderAbsenceAttendants {
    position: absolute;
    width: 550px;
    height: 140px;
    top: 35px;
    right: 0px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    z-index: 900;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;
    overflow: hidden;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .lineBodyDropIntervalsHeaderAbsenceAttendants {
    padding: 5px 13px;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .lineFooterDropIntervalsHeaderAbsenceAttendants {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .lineFooterDropIntervalsHeaderAbsenceAttendants .buttonIntervalsHeaderAbsenceAttendants {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .lineFooterDropIntervalsHeaderAbsenceAttendants .buttonIntervalsHeaderAbsenceAttendants:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .lineFooterDropIntervalsHeaderAbsenceAttendants .buttonIntervalsHeaderAbsenceAttendants.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.absenceAttendants .containerNavigationHeaderAbsenceAttendants .lineFooterDropIntervalsHeaderAbsenceAttendants .buttonIntervalsHeaderAbsenceAttendants.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.absenceAttendants .containerListHeaderAbsenceAttendants {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderAbsenceAttendants*/
    overflow: scroll;
}
.tableEditAbsenceAttendants {
    background-color: var(--white-light);
    overflow: scroll;
}

.tableEditAbsenceAttendants .helpTableEditAbsenceAttendants {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;
}

.tableEditAbsenceAttendants table, tr, th, td {
    margin: 0;
}

.tableEditAbsenceAttendants table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditAbsenceAttendants table .buttonCheckTableEditAbsenceAttendants {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditAbsenceAttendants table .buttonCheckTableEditAbsenceAttendants .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditAbsenceAttendants table .buttonCheckTableEditAbsenceAttendants.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditAbsenceAttendants table .buttonCheckTableEditAbsenceAttendants.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditAbsenceAttendants table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditAbsenceAttendants table tr {
    height: 32px;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus {
    width: 90px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus45 {
    width: 30px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus60 {
    width: 60px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus75 {
    width: 75px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus100 {
    width: 100px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus120 {
    width: 120px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus140 {
    width: 140px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td.minimus180 {
    width: 180px!important;
}

.tableEditAbsenceAttendants .theadTableEditAbsenceAttendants td:last-of-type {
    border-right: none;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants:hover {
    background-color: var(--white-dark);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditAbsenceAttendants td.checkTableEditAbsenceAttendants {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditAbsenceAttendants .table .tbodyTableEditAbsenceAttendants td.checkTableEditAbsenceAttendants {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants td:hover .buttonEditTableEditAbsenceAttendants {
    opacity: 1;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants td:hover .editTableEditAbsenceAttendants {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants td .editTableEditAbsenceAttendants.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants td .editTableEditAbsenceAttendants .modalFieldEdit {
    z-index: 1000;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants {
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.noAction {
    cursor: default;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.success { 
    color: var(--color-success);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.error { 
    color: var(--color-error);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.warning { 
    color: var(--color-warning);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants .messageDetailsTableEditAbsenceAttendants {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}


.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants { 
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.success { 
    color: var(--color-success);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.error { 
    color: var(--color-error);
}

.tableEditAbsenceAttendants .tbodyTableEditAbsenceAttendants .detailsTableEditAbsenceAttendants button.button.buttonShowDetailsTableEditAbsenceAttendants.warning { 
    color: var(--color-warning);
}
.notFound {
    background-color: var(--white-dark);
    height: 100vh;
    color: var(--dark-light);
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
}
.integrations {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.integrations .warningIntegrations {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.integrations .scrollIntegrations {
    overflow: scroll;
    height: 100%;
}

.integrations .headerIntegrations {
    margin-top: 20px;
}

.integrations .headerIntegrations h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.integrations .headerIntegrations h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-error);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}

.integrations .bodyIntegrations  {
    /* nothing */
}
.commissionReportIntervals {
    height: 100%;
    width: calc(100vw - 56px);
    position: relative;
    overflow: hidden;
}

.commissionReportIntervals .warningCommissionReportIntervals {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .buttonNavigationHeaderCommissionReportIntervals {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .buttonNavigationHeaderCommissionReportIntervals:hover {
    background-color: var(--white-dark);
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .buttonNavigationHeaderCommissionReportIntervals:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .buttonNavigationHeaderCommissionReportIntervals:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .buttonNavigationHeaderCommissionReportIntervals.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals .iconFilterHeaderCommissionReportIntervals {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals .selectFilterHeaderCommissionReportIntervals {
    width: 100%;
    border: none;
    cursor: pointer;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals .selectFilterHeaderCommissionReportIntervals .optionsSelectMulti {
    min-width: 200px;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals .selectFilterHeaderCommissionReportIntervals .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionReportIntervals .containerNavigationHeaderCommissionReportIntervals .filterHeaderCommissionReportIntervals .selectFilterHeaderCommissionReportIntervals .fieldSelectMulti {
    border: none;
}

.commissionReportIntervals .containerListHeaderCommissionReportIntervals {
    height: calc(100vh - 56px - 65px); /*56px of the Header component plus 65px of the .containerNavigationHeaderCommissionReportIntervals */
}
.tableEditCommissionReportIntervals {
    background-color: var(--white-light);
    height: 100%;
    overflow: scroll;
}

.tableEditCommissionReportIntervals .helpTableEditCommissionReportIntervals {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionReportIntervals .hoverEditEffect:hover {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.tableEditCommissionReportIntervals table, tr, th, td {
    margin: 0;
}

.tableEditCommissionReportIntervals table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionReportIntervals table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditCommissionReportIntervals table td.center {
    text-align: center;
}

.tableEditCommissionReportIntervals table td.left {
    text-align: left;
}

.tableEditCommissionReportIntervals table td.right {
    text-align: right;
}

.tableEditCommissionReportIntervals table tr {
    height: 32px;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
    padding-right: 10px;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td.minimus {
    width: 90px !important;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td.minimus45 {
    width: 45px !important;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td.minimus60 {
    width: 60px !important;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td.minimus75 {
    width: 75px !important;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td.minimus80 {
    width: 80px !important;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td.minimus100 {
    width: 100px !important;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td.minimus120 {
    width: 120px !important;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td.minimus140 {
    width: 140px !important;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td.minimus160 {
    width: 160px !important;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td.minimus180 {
    width: 180px !important;
}

.tableEditCommissionReportIntervals .theadTableEditCommissionReportIntervals td:last-of-type {
    border-right: none;
}

.tableEditCommissionReportIntervals .tbodyTableEditCommissionReportIntervals:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionReportIntervals .tbodyTableEditCommissionReportIntervals:nth-child(2n) {
    background-color: var(--white-dark-three);
}

.tableEditCommissionReportIntervals .tbodyTableEditCommissionReportIntervals td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    border: 1px solid #eee;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
}

.tableEditCommissionReportIntervals .tfootTableEditCommissionReportIntervals td {
    border: 1.02px solid #b9babb;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 14px;
    position: relative;
    padding-right: 10px;
}
.whatsAppInProgress {
    height: 100vh;
    position: relative;
    overflow: hidden;
}
.whatsApp {
    background-color: rgb(240, 242, 245);
    height: 100vh;
    width: 100%;
    display: inline-flex;
    overflow: hidden;
}

.whatsApp .developmentWhatsApp {
    position: absolute;
    top: 4px;
    left: 60px;
    padding: 10px;
    font-weight: 400;
    background-color: var(--dark-dark);
    min-width: 400px;
    min-height: 100px;
    border-radius: 5px;
    z-index: 999;
    color: var(--white-dark);
}

.whatsApp .errorWhatsApp {
    background-color: var(--color-error);
    color: var(--white-light);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
    height: -webkit-max-content;
    height: max-content;
    margin-left: auto;
    margin-right: auto;
}

.whatsApp .buttonDefaultWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.whatsApp .noSelectionWhatsApp {
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.select.selectDepartamentsWhatsApp .optionsSelect { /* WARNING: Don't have reference with .whatsApp, because is modal*/
    position: relative;
}

.whatsApp .chatListWhatsApp {
    height: 100%;
    width: var(--whatsapp-width-chatlist);
    background-color: rgb(240, 242, 245);
    border-right: 1px solid #e9edef;
    background-color: #fff;
    position: relative;
    display: initial;
    /* REMOVE */
    /* display: none; */
}

.whatsApp .chatListWhatsApp .dropChatListWhatsApp {
    left: 240px;
    top: 0;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp {
    height: var(--whatsapp-height-chatlist-header);
    padding: 10px 16px;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .buttonAvatarChatListHeaderWhatsApp {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    border: none;
    margin-right: auto;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .avatarChatListHeaderWhatsApp {
    height: 100%;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .avatarDefaultChatListHeaderWhatsApp {
    width: 40px;
    height: 40px;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .anothersButtonsChatListHeaderWhatsApp {
    display: flex;
    flex-direction: row;
    position: relative;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .anothersButtonsChatListHeaderWhatsApp .buttonOthersChatListHeaderWhatsApp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    padding: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: background-color 0.28s;
    position: relative;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .anothersButtonsChatListHeaderWhatsApp .buttonOthersChatListHeaderWhatsApp.active {
    background-color: #d9dbdf;
    transition: background-color 0s;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .anothersButtonsChatListHeaderWhatsApp .buttonOthersChatListHeaderWhatsApp .iconOthersChatListHeaderWhatsApp {
    fill: rgb(84, 101, 111);
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .dropChatListHeaderWhatsApp {
    top: 45px;
    right: 4px;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp {
    background-color: #fff;
    position: relative;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 49px;
    transition: box-shadow .18s ease-out,background-color .25s ease-out;
    padding: 0 0 0 12px;
    border-bottom: 1px solid #e9edef;
    box-shadow: 0 2px 3px rgba(11,20,26,0.08);
    /* border-bottom: 1px solid #e9edef; */    
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .helpFilterSearchingWhatsApp {
    position: absolute;
    top: -42px;
    left: 80px;
    background-color: rgba(0, 0, 0, 0.791);
    padding: 4px;
    color: white;
    z-index: 100;
    font-size: 14px;
    font-weight: 400;
    opacity: 0;
    transition: opacity 0.5s;
    border-radius: 4px;
    cursor: pointer;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .helpFilterSearchingWhatsApp::before {
    display: block;
    content: " ";
    position: absolute;
    bottom: -10px;
    left: 4px;
    border-left: 0;
    width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 10px solid rgba(0, 0, 0, 0.791);
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .helpFilterSearchingWhatsApp.active {
    opacity: 1;
    transition: opacity 0.5s;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .helpFilterSearchingWhatsApp.inactive {
    opacity: 0;
    transition: opacity 0.5s;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp::after {
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    position: absolute;
    padding: 4px;
    background-color: #f0f2f5;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp .iconSearchWhatsApp.active {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: -webkit-transform 0.28s;
    transition: transform 0.28s;
    transition: transform 0.28s, -webkit-transform 0.28s;
    font-size: 12px;
    opacity: 1;
    left: 18px;
    position: absolute;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp .iconSearchWhatsApp.inactive {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp .iconLeftWhatsApp.active {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: -webkit-transform 0.28s;
    transition: transform 0.28s;
    transition: transform 0.28s, -webkit-transform 0.28s;
    color: #00a884;
    font-size: 16px;
    opacity: 1;
    left: 18px;
    position: absolute;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp .iconLeftWhatsApp.inactive {
    opacity: 0;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp.buttonClearChatListSearchWhatsApp {
    right: 61px;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp .iconButtonChatListSearchWhatsApp {
    color: #54656f;
    font-size: 8px;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonClearChatListSearchWhatsApp.inactive .iconButtonClearChatListSearchWhatsApp {
    opacity: 0;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonClearChatListSearchWhatsApp.active .iconButtonClearChatListSearchWhatsApp {
    -webkit-transform: scale(2, 2);
            transform: scale(2, 2);
    transition: -webkit-transform 0.28s;
    transition: transform 0.28s;
    transition: transform 0.28s, -webkit-transform 0.28s;
    opacity: 1;
    color: #8696a0;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonFilterChatListSearchWhatsApp.active {
    background-color: #00a884;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonFilterChatListSearchWhatsApp {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: relative;
    background-color: transparent;
    margin: 0;
    margin-left: 8px;
    margin-right: 8px;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonFilterChatListSearchWhatsApp .iconButtonFilterChatListSearchWhatsApp {
    fill: #8696a0;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonFilterChatListSearchWhatsApp.active .iconButtonFilterChatListSearchWhatsApp {
    fill: #fff;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .chatListSearchInputWhatsApp {
    padding-right: 32px;
    padding-left: 65px;
    border-radius: 8px;
    background-color: #f0f2f5;
    box-shadow: none;
    outline: none;
    border: none;
    height: 35px;
    width: 100%;
    color: #3b4a54;
    font-size: 15px;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .chatListSearchInputWhatsApp::-webkit-input-placeholder {
    color: #667781; 
    font-weight: 400;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .chatListSearchInputWhatsApp::placeholder {
    color: #667781; 
    font-weight: 400;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp {
    background-color: #fff;
    overflow: scroll;
    padding-right: 2px;
    height: calc(100% - var(--whatsapp-height-chatlist-header) - 49px); /* 59px by header component plus 49px by search component*/
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp.list {
    margin-right: 4px;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp.center {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .buttonChatListItemArchivedWhatsApp {
    height: 49px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .buttonChatListItemArchivedWhatsApp .areaIconChatListItemArchivedWhatsApp {
    height: 49px;
    width: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin: 0 15px 0 13px;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .buttonChatListItemArchivedWhatsApp .areaIconChatListItemArchivedWhatsApp .iconChatListItemArchivedWhatsApp {
    height: 18px;
    width: 18px;
    fill: #00a884;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .buttonChatListItemArchivedWhatsApp .areaIconChatListItemArchivedWhatsApp .nameChatListItemArchivedWhatsApp {
    
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .nothingChatsFilterWhatsApp {
    display: flex;
    flex-direction: column;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .nothingChatsFilterWhatsApp .messageNothingChatsFilterWhatsApp {
    margin-bottom: 18px;
    color: #8696a0;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .nothingChatsFilterWhatsApp .clearNothingChatsFilterWhatsApp {
    color: #008069;
    line-height: 20px;
    font-size: 0.875rem;
    font-weight: 400;
}

.whatsApp .chatListWhatsApp .chatListLoadingWhatsApp {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.whatsApp .conversationPanelWrapperWhatsApp {
    width: calc(100% - var(--whatsapp-width-chatlist)); /* width of the chatList*/
    height: 100vh;
    overflow: hidden;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp {
    height: var(--whatsapp-height-chatlist-header);
    padding: 10px 16px;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    border-bottom: 1px solid #e9edef;
    cursor: pointer;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonAvatarConversationHeaderWhatsApp {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonAvatarConversationHeaderWhatsApp .avatarConversationHeaderWhatsApp {
    height: 40px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonAvatarConversationHeaderWhatsApp .avatarDefaultConversationHeaderWhatsApp {
    width: 40px;
    height: 40px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonOthersConversationHeaderWhatsApp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    padding: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: background-color 0.28s;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonOthersConversationHeaderWhatsApp:active {
    background-color: #d9dbdf;
    transition: background-color 0s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonOthersConversationHeaderWhatsApp .iconOthersConversationHeaderWhatsApp {
    fill: rgb(84, 101, 111);
    color: rgb(84, 101, 111);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .conversationPanelContactInformationHeaderWhatsApp {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .conversationPanelContactInformationHeaderWhatsApp .nameContactHeaderWhatsApp {
    color: #111b21;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 21px;
    cursor: pointer;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .conversationPanelContactInformationHeaderWhatsApp .timeContactHeaderWhatsApp {
    color: #667781;
    font-size: 13px;
    cursor: pointer;
    font-weight: 400;
    line-height: 20.0005px;
    display: none;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .conversationPanelContactInformationHeaderWhatsApp .timeContactHeaderWhatsApp.active {
    display: block;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp {
    height: calc(100% - var(--whatsapp-height-chatlist-header) - 64px);
    background-color: #efeae2;
    overflow: scroll;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .groupMessagesWhatsApp {
    /* nothing */
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .groupMessagesWhatsApp .areaDataWhatsApp {
    text-align: center;
    text-shadow: 0 1px 0 rgba(255, 255, 255,.4);
    margin: 5px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    -webkit-user-select: none;
            user-select: none;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .groupMessagesWhatsApp .areaDataWhatsApp .dataMessageWhatsApp {
    padding: 5px 12px 6px;
    border-radius: 7.5px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    background-color: rgba(255,255,255, 0.95);
    min-width: 56px;
    font-size: 12.5px;
    line-height: 21px;
    font-weight: 400;
    color: #54656f;
    text-transform: uppercase;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .conversationInnerPanelMessagesWhatsApp {
    height: 100%;
    width: 100%;
    opacity: 0.4;
    background-image: url(/static/media/whatsapp.39f53e74.png);
    background-repeat: repeat;
    z-index: 100;

}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .conversationMessagesPanelMessagesWhatsApp {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 1;
    z-index: 200;
    background-color: transparent!important;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    overflow: scroll;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .conversationMessagesPanelMessagesWhatsApp .dropConversationActionsWhatsApp {
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .conversationMessagesPanelMessagesWhatsApp .buttonLoadOldConversationPanelMessagesWhatsApp {
    padding: 5px 12px 6px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255, 255, 255,.4);
    border-radius: 7.5px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    background-color: rgba(255,255,255, 0.95);
    font-size: 12.5px;
    line-height: 21px;
    font-weight: 400;
    color: #54656f;
    display: flex;
    margin: 5px auto;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .buttonScrollToBottomConversationPanelMessagesWhatsApp {
    width: 42px;
    height: 42px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(11,20,26,.06),0 2px 5px 0 rgba(11,20,26,.2);
    border-radius: 50%;
    position: absolute;
    bottom: 90px;
    right: 15px;
    z-index: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    -webkit-transform: scaleX(1) scaleY(1);
            transform: scaleX(1) scaleY(1);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .buttonScrollToBottomConversationPanelMessagesWhatsApp.active {
    -webkit-transform: scaleX(1) scaleY(1);
            transform: scaleX(1) scaleY(1);
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .buttonScrollToBottomConversationPanelMessagesWhatsApp.inactive {
    -webkit-transform: scaleX(0) scaleY(0);
            transform: scaleX(0) scaleY(0);
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .buttonScrollToBottomConversationPanelMessagesWhatsApp .iconButtonScrollToBottomConversationPanelMessagesWhatsApp {
    fill:  #888d90;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp {
    min-height: 63px;
    background-color: #f0f2f5;
    padding: 5px 16px;
    border-left: 1px solid #e9edef;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    vertical-align: middle;
    background-color: #f0f2f5;
    z-index: 300;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .inputConversationSendWhatsApp {
    box-shadow: none;
    outline: none;
    width: 100%;
    margin: 5px 8px;
    padding: 9px 12px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #fff;
    color: rgb(59, 74, 84);
    font-size: 15px;
    font-weight: 400;
    line-height: 22.05px;
    height: 42px;
    resize: none;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .inputConversationSendWhatsApp.inactive {
    opacity: 0;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .inputConversationSendWhatsApp.active {
    opacity: 1;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .inputConversationSendWhatsApp::-webkit-input-placeholder {
    color: rgb(59, 74, 84);
    font-size: 15px;
    font-weight: 400;
    line-height: 22.05px;
    padding-left: 6px;
    padding-right: 6px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .inputConversationSendWhatsApp::placeholder {
    color: rgb(59, 74, 84);
    font-size: 15px;
    font-weight: 400;
    line-height: 22.05px;
    padding-left: 6px;
    padding-right: 6px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationSendWhatsApp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: background-color 0.28s;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationWithoutEffectSendWhatsApp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: background-color 0.28s;
    position: relative;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    position: absolute;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationWithoutEffectSendWhatsApp.active {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    position: relative;
    opacity: 1;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationWithoutEffectSendWhatsApp.inactive {
    position: absolute;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationSendWhatsApp:active {
    background-color: #d9dbdf;
    transition: background-color 0s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationSendWhatsApp .iconOthersConversationSendWhatsApp, 
.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationWithoutEffectSendWhatsApp .iconOthersConversationSendWhatsApp {
    fill: rgb(84, 101, 111);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationWithoutEffectSendWhatsApp .iconOthersConversationSendWhatsApp.activeRecording {
    fill: #ff3b30;
    color: #ff3b30;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationSendWhatsApp .iconOthersConversationSendWhatsApp.delete {
    width: 16px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .audioRecordingConversationSendWhatsApp.inactive {
    opacity: 0;
    display: none;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .audioRecordingConversationSendWhatsApp.active {
    opacity: 1;
    display: flex;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp {
    width: 100%;
    position: absolute;
    /* bottom: 63px; default is bottom: 63px */
    min-height: 64px; /*defualt is min-height: 63px*/
    left: 0;
    padding: 5px 16px 0px 16px;
    z-index: 300;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    background-color: #f0f2f5;
    z-index: 200;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp.active {
    bottom: 63px; /*default is 63px*/
    transition: bottom .28s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp.inactive {
    bottom: -100px; /*default is -100px*/
    transition: bottom .28s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .innerMessageAnswerConversationSendMessageWhatsApp {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 100%;
    margin-left: 48px;
    margin-right: 8px;
    position: relative;
    border-radius: 7.5px;
    overflow: hidden;
    padding: 7px 12px 10px 11px;
    background-color: rgba(11,20,26,0.05);
    max-height: 70px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .innerMessageAnswerConversationSendMessageWhatsApp::before {
    width: 4px;
    left: 0px;
    top: -15px;
    height: 90px;
    background-color: #06cf9c!important;
    display: block;
    content: " ";
    position: absolute;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .nameAnswerConversationSendMessageWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    line-height: 22px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .messageAnswerConversationSendMessageWhatsApp {
    color: #667781;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size: 12.6px;
    font-weight: 400;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .messageAnswerConversationSendMessageWhatsApp .iconTypeAnswerConversationSendMessageWhatsApp {
    color: #8696a0;
    fill: #8696a0;
    margin-right: 3px;
    margin-top: -2px;
}


.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .buttonCloseAreaAnswerConversationSendMessageWhatsApp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: background-color 0.28s;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .buttonCloseAreaAnswerConversationSendMessageWhatsApp .iconCloseAreaAnswerConversationSendMessageWhatsApp {
    fill: rgb(84, 101, 111);
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp {
    width: 0;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp {
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .headerMoreDetailsProfileGeneralWhatsApp {
    height: 59px;
    width: 100%;
    background-color: #f0f2f5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    padding: 0px 20px 0px 25px;
    border-left: 1px solid #e9edef;
    position: relative;
    z-index: 200;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .headerMoreDetailsProfileGeneralWhatsApp .buttonCloseMoreDetailsProfileGeneralWhatsApp {
    margin-right: 30px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .headerMoreDetailsProfileGeneralWhatsApp .buttonCloseMoreDetailsProfileGeneralWhatsApp .iconButtonCloseMoreDetailsProfileGeneralWhatsApp {
    fill: #54656f;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .headerMoreDetailsProfileGeneralWhatsApp .aboutMoreDetailsProfileGeneralWhatsApp {
    font-size: 16px;
    font-weight: 400;
    color: rgb(17, 27, 33);
    -webkit-user-select: none;
            user-select: none;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px); /*default is translateY(-50px)*/
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp.active {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp.inactive {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 17px 30px 10px 30px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    box-shadow: 0 1px 3px rgba(11,20,26,0.08);
    position: relative;
    z-index: 100;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp.avatar {
    padding-top: 28px;
    padding-bottom: 19px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .buttonAvatarMoreDetailsProfileGeneralWhatsApp {
    height: 200px;
    width: 200px;
    overflow: hidden;
    border-radius: 50%;
    border: none;  
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .buttonAvatarMoreDetailsProfileGeneralWhatsApp.active {
    opacity: 1;
    transition: opacity 2s;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .buttonAvatarMoreDetailsProfileGeneralWhatsApp.inactive {
    opacity: 0;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .buttonAvatarMoreDetailsProfileGeneralWhatsApp .avatarMoreDetailsProfileGeneralWhatsApp {
    height: 100%;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .nameMoreDetailsProfileGeneralWhatsApp {
    color: rgb(59, 74, 84);
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 15px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .cellphoneMoreDetailsProfileGeneralWhatsApp {
    margin-top: 4px;
    font-weight: 400;
    line-height: 24px;
    color: rgb(59, 74, 84);
    margin-bottom: 12px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .buttonFilesWhatsApp .titleButtonDetailsAndSelectPanelWhatsApp {
    color: #667781;
    font-size: 15px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .infosCalledWhatsApp.title {
    color: rgb(17, 27, 33);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .infosCalledWhatsApp.subtitle {
    color: #667781;
    font-size: 15px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .infosCalledWhatsApp.subtitle .iconInfosCalledWhatsApp {
    color: #667781;
    font-size: 15px;
    margin-right: 4px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 200;
    transition: width .1s;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp.active {
    background-color: #f0f2f5;
    width: 100%;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp.inactive {
    background-color: #f0f2f5;
    width: 0;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp .headerMoreDetailsProfileFilesWhatsApp {
    height: 59px;
    width: 100%;
    background-color: #008069;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    padding: 0px 20px 0px 25px;
    border-left: 1px solid #e9edef;
    position: relative;
    z-index: 200;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp .headerMoreDetailsProfileFilesWhatsApp .buttonCloseMoreDetailsProfileFilesWhatsApp {
    margin-right: 30px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp .headerMoreDetailsProfileFilesWhatsApp .buttonCloseMoreDetailsProfileFilesWhatsApp .iconButtonCloseMoreDetailsProfileFilesWhatsApp {
    fill: #fff;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp .bodyMoreDetailsProfileProfileWhatsApp {
    height: 100%;
    padding: 20px;
    color: #aeaeae;
    font-size: 12px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp {
    height: calc(100% - var(--whatsapp-height-chatlist-header)); /* from height conversationPanelHeaderWhatsApp*/
    width: calc(100% - var(--whatsapp-width-chatlist) - 56px); /* width of the chatList*/
    background-color: rgb(240, 242, 245);
    position: absolute;
    top: var(--whatsapp-height-chatlist-header); /*from height conversationPanelHeaderWhatsApp*/
    z-index: 300;
    display: flex;
    flex-direction: column;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaHeaderWhatsApp {
    min-height: 60px;
    width: 100%;
    background-color: #e9edef;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaHeaderWhatsApp .nameConversationPanelUplodaHeaderWhatsApp{
    font-size: 0.875rem;
    color: #111b21;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaHeaderWhatsApp .buttonCloseConversationPanelUplodaHeaderWhatsApp {
    position: absolute;
    left: 16px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaHeaderWhatsApp .buttonCloseConversationPanelUplodaHeaderWhatsApp .iconButtonCloseConversationPanelUplodaHeaderWhatsApp {
    fill: rgb(102, 119, 129);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .iconConversationPanelUplodaBodyWhatsApp {
    margin-bottom: 20px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .titleConversationPanelUploadBodyWhatsApp {
    color: #aebac1;
    margin: 10px 0px;
    font-size: 24px;
    padding: 0;
    font-weight: 400;
    line-height: 24px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .imageConversationPanelUploadBodyWhatsApp {
    max-height: 220px;
    border-radius: 2px;
    box-shadow: 0px 0px 6px #a3a3a3;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .detailsConversationPanelUploadBodyWhatsApp {
    color: rgb(174, 186, 193);
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin: 0;
    padding: 0;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp {
    position: relative;
    width: calc(100% - 160px);
    margin-top: 50px;
    border-radius: 8px;
    overflow: hidden;
    height: 45px;
    padding: 4px 16px;
    padding-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #fff;
    position: absolute;
    bottom: 12px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp .buttonClearConversationPanelUploadBodyWhatsApp {
    position: absolute;
    right: 16px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp .buttonClearConversationPanelUploadBodyWhatsApp .iconButtonClearConversationPanelUploadBodyWhatsApp {
    fill: rgb(84, 101, 111);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp .inputMessageConversationPanelUploadBodyWhatsApp {
    width: 100%;
    height: 100%;
    box-shadow: none;
    outline: none;
    border: none;
    background-color: #fff;
    color: rgb(59, 74, 84);
    font-size: 17px;
    line-height: 25px;
    font-weight: 400;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp .inputMessageConversationPanelUploadBodyWhatsApp::-webkit-input-placeholder {
    color: red;
    padding-left: 6px;
    line-height: 25px;
    color: rgb(102, 119, 129);
    font-size: 17px;
    font-weight: 400;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp .inputMessageConversationPanelUploadBodyWhatsApp::placeholder {
    color: red;
    padding-left: 6px;
    line-height: 25px;
    color: rgb(102, 119, 129);
    font-size: 17px;
    font-weight: 400;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaFooterWhatsApp {
    border-top: 1px solid #d9d9d9;
    height: 99px;
    background-color: rgb(240, 242, 245);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    vertical-align: middle;
    margin: 0px 16px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaFooterWhatsApp .buttonSendMessageConversationPanelUploadFooterWhatsApp {
    height: 60px;
    width: 60px;
    background-color: #00a884;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    box-shadow: 0 1px 3px rgba(11,20,26,.4);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaFooterWhatsApp .buttonSendMessageConversationPanelUploadFooterWhatsApp .iconButtonSendMessageConversationPanelUploadFooterWhatsApp {
    fill: #fff;
    font-size: 14px;
    font-weight: 500;
}

.loadingWhatsApp {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3.8px solid #00a884;
    -webkit-animation: spinner-bulqg1 0.96s infinite linear alternate, spinner-oaa3wk 1.92s infinite linear;
            animation: spinner-bulqg1 0.96s infinite linear alternate, spinner-oaa3wk 1.92s infinite linear;
    position: absolute;
    bottom: 0;
}

@-webkit-keyframes spinner-bulqg1 {
   0% {
      -webkit-clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
              clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }

   12.5% {
      -webkit-clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
              clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }

   25% {
      -webkit-clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
              clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }

   50% {
      -webkit-clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
              clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   62.5% {
      -webkit-clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
              clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   75% {
      -webkit-clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
              clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }

   100% {
      -webkit-clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
              clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
}

@keyframes spinner-bulqg1 {
   0% {
      -webkit-clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
              clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }

   12.5% {
      -webkit-clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
              clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }

   25% {
      -webkit-clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
              clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }

   50% {
      -webkit-clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
              clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   62.5% {
      -webkit-clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
              clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   75% {
      -webkit-clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
              clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }

   100% {
      -webkit-clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
              clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
}

@-webkit-keyframes spinner-oaa3wk {
   0% {
      -webkit-transform: scaleY(1) rotate(0deg);
              transform: scaleY(1) rotate(0deg);
   }

   49.99% {
      -webkit-transform: scaleY(1) rotate(135deg);
              transform: scaleY(1) rotate(135deg);
   }

   50% {
      -webkit-transform: scaleY(-1) rotate(0deg);
              transform: scaleY(-1) rotate(0deg);
   }

   100% {
      -webkit-transform: scaleY(-1) rotate(-135deg);
              transform: scaleY(-1) rotate(-135deg);
   }
}

@keyframes spinner-oaa3wk {
   0% {
      -webkit-transform: scaleY(1) rotate(0deg);
              transform: scaleY(1) rotate(0deg);
   }

   49.99% {
      -webkit-transform: scaleY(1) rotate(135deg);
              transform: scaleY(1) rotate(135deg);
   }

   50% {
      -webkit-transform: scaleY(-1) rotate(0deg);
              transform: scaleY(-1) rotate(0deg);
   }

   100% {
      -webkit-transform: scaleY(-1) rotate(-135deg);
              transform: scaleY(-1) rotate(-135deg);
   }
}

@media (max-width: 1023px)
{
    .whatsApp .chatListWhatsApp,
    .whatsApp .conversationPanelWrapperWhatsApp {
        display: none;
    }

    .whatsApp .messageAudioWhatsApp {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media (max-width: 1439px)
{
    .whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .headerMoreDetailsProfileGeneralWhatsApp {
        border: none;
    }
}
@font-face {
    font-family: SF_Pro_Text_Light;
    src: url(/static/media/SF-Pro-Text-Light.b411f9bf.otf);
}

.itemListWhatsApp {
    height: 72px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
    width: 100%;
}

.itemListWhatsApp .buttonItemListWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.itemListWhatsApp:hover {
    background-color: #f5f6f6;
    cursor: pointer;
    transition: background-color 0.25s;
}

.itemListWhatsApp.opened {
    background-color: #f0f2f5;
}

.itemListWhatsApp .chatAvatarItemListWhatsApp {
    background-color: #dfe5e7;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
    margin: 0 15px 0 13px;
}

.itemListWhatsApp .chatAvatarItemListWhatsApp .photoContactItemListWhatsApp {
    height: 100%;
}

.itemListWhatsApp .chatAvatarItemListWhatsApp .chatListeItemIconAvatarWhatsApp {
    color: #fff;
    font-size: 40px;
    margin-bottom: -12px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp {
    border-top: 1px solid #e9edef;
    height: 100%;
    width: calc(100% - 77px); /* 509px by chatListWhatsApp component plus 77px by chatAvatarItemListWhatsApp component */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
    padding-right: 15px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .headDetailsItemListWhatsApp {
    display: inline-flex;
    width: 100%;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .headDetailsItemListWhatsApp .areaIconVerifiedContactItemListWhatsApp {
    margin-top: 1px;
    margin-left: 3px;
    display: flex;
    align-items: flex-start;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .headDetailsItemListWhatsApp .areaIconVerifiedContactItemListWhatsApp .iconVerifiedContactItemListWhatsApp {
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .headDetailsItemListWhatsApp .nameContactItemListWhatsApp {
    color: #111b21;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .headDetailsItemListWhatsApp .timeContactItemListWhatsApp {
    color: #667781;
    font-size: 12px;
    margin: 3px 0 0 6px;
    font-weight: 400;
    width: 114px;
    text-align: right;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp {
    width: 100%;
    height: 24px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .amountNotReadMessagesItemListWhatsApp {
    background-color: #25d366;
    font-weight: 500;
    max-width: 100%;
    margin-left: 6px;
    font-size: 12px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    color: #fff;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp.deleted {
    align-items: center;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .messageItemListWhatsApp {
    color: #667781;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    flex-grow: 1;
    width: 1px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .messageItemListWhatsApp.deleted {
    /* font-style: italic; */
    text-decoration: line-through;
    font-family: SF_Pro_Text_Light;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp {
    margin-right: 2px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.read {
    fill: #53bdeb;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.delivered {
    fill: #8696a0;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.sent {
    fill: #8696a0;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.deleted {
    margin: 0;
    fill: #8696a0;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.audioRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.videoRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.photoRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.documentRead {
    fill: #53bdeb;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.audioNoRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.videoNoRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.photoNoRead,
.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.documentNoRead {
    fill: #8696a0;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.audioRead {
    margin: 0;
    margin-right: 3px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .iconStatusItemListWhatsApp.audioNoRead {
    margin: 0;
    margin-right: 3px;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .buttonDropItemListWhatsApp {
    position: relative;
    opacity: 0;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    height: 100%;
}

.itemListWhatsApp:hover .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .buttonDropItemListWhatsApp {
    width: 40px;
    display: flex;
    justify-content: flex-end;
    transition: width 3s;
    opacity: 1;
}

.itemListWhatsApp .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .buttonDropItemListWhatsApp .iconDropItemListWhatsApp {
    position: absolute;
    margin-right: -8px;
}

.itemListWhatsApp:hover .chatDetailsItemListWhatsApp .bodyDetailsItemListWhatsApp .buttonDropItemListWhatsApp .iconDropItemListWhatsApp {
    margin-right: 0px;
    transition: margin-right 0.15s;
}
.dropdownWhatsApp {
    min-width: 230px;
    max-width: 340px;
    min-height: 100px;
    background-color: #fff;
    position: absolute;
    z-index: 10000;
    opacity: 0;
    text-align: left;
    padding: 9px 0;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(11,20,26,.26),0 2px 10px 0 rgba(11,20,26,.16);
    font-size: inherit;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
}

.dropdownWhatsApp.active {
    opacity: 1;
    -webkit-transform-origin: right top;
            transform-origin: right top;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition:  -webkit-transform 0.28s;
    transition:  transform 0.28s;
    transition:  transform 0.28s, -webkit-transform 0.28s;
}

.dropdownWhatsApp.active.activeLeftTop {
    -webkit-transform-origin: left top;
            transform-origin: left top;
}

.dropdownWhatsApp.active.activeLeftBottom {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
}

.dropdownWhatsApp.active.activeRightTop {
    -webkit-transform-origin: right top;
            transform-origin: right top;
}

.dropdownWhatsApp.active.activeRightBottom {
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
}

.dropdownWhatsApp.inactive {
    opacity: 0;
    z-index: -1;
}
.buttonWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    position: relative;
    padding: 0;
    margin: 0;
    padding-right: 58px;
    padding-left: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    position: relative;
    color: #3b4a54;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14.5px;
    height: 40px;
    font-weight: 400;
}

.buttonWhatsApp:hover {
    background-color: #f5f6f6;
}
@font-face {
    font-family: SF_Pro_Text_Light;
    src: url(/static/media/SF-Pro-Text-Light.b411f9bf.otf);
}

.noOpenedChatWhatsApp {
    height: 100%;
    border-bottom: 6px solid #25d366;
    cursor: default;
    background-color: #f0f2f5;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    position: relative;
}

.noOpenedChatWhatsApp .innerNoOpenedChatWhatsApp {
    padding-top: 28px;
    padding-bottom: 28px;
    margin-top: -20px;
    width: 80%;
    max-width: 560px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    vertical-align: middle;
    text-align: center;
}

.noOpenedChatWhatsApp .applicationNameNoOpenedChatWhatsApp {
    color: #41525d;
    font-size: 32px;
    font-style: normal;
    line-height: 37.5px;
    margin: 0;
    margin-top: 40px;
    font-family: SF_Pro_Text_Light;
}

.noOpenedChatWhatsApp .descriptionNoOpenedChatWhatsApp {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 16px;
    color: #667781;
}

.noOpenedChatWhatsApp .secureNoOpenedChatWhatsApp {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    bottom: 40px;
}

.noOpenedChatWhatsApp .secureNoOpenedChatWhatsApp .iconSecureNoOpenedChatWhatsApp {
    fill: rgb(134, 150, 160);
    margin-bottom: 2px;
}

.noOpenedChatWhatsApp .secureNoOpenedChatWhatsApp .nameSecureNoOpenedChatWhatsApp {
    color: rgb(134, 150, 160);
}
@font-face {
    font-family: SF_Pro_Text_Light;
    src: url(/static/media/SF-Pro-Text-Light.b411f9bf.otf);
}

.messageTextWhatsApp {
    width: 100%;
    background-color: transparent!important;
    border-radius: 5px;
    margin-bottom: 2px;
    padding-right: 63px;
    padding-left: 63px;
    display: flex;
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp.image {
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp .loadingWhatsApp {
    width: 74px;
    height: 74px;
    position: relative;
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp .areaTryingDownloadFileMessageTextWhatsApp {
    width: 74px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp .areaTryingDownloadFileMessageTextWhatsApp .buttonTryingDownloadFileMessageTextWhatsApp {
    position: relative;
    padding: 0;
    background-color: rgba(11,20,26,.35);
    height: 50px;
    border-radius: 25px;
    padding-right: 18px;
    padding-left: 18px;
}

.messageTextWhatsApp .areaLoadingMessageTextWhatsApp .areaTryingDownloadFileMessageTextWhatsApp .buttonTryingDownloadFileMessageTextWhatsApp .iconButtonTryingDownloadFileMessageTextWhatsApp {
    color: #fff;
}

.messageTextWhatsApp .buttonDefaultMessageTextWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.messageTextWhatsApp.right {
    justify-content: flex-end;
}

.messageTextWhatsApp.left {
    justify-content: flex-start;
}

.messageTextWhatsApp .areaMessageTextWhatsApp {
    position: relative;
    color: #111b21;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 7.5px;
    max-width: 65%;
    padding: 6px 7px 8px 9px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    z-index: 200;
    position: relative;
}

.messageTextWhatsApp.right .areaMessageTextWhatsApp {
    background-color: #d9fdd3;
}

.messageTextWhatsApp.left .areaMessageTextWhatsApp {
    background-color: #fff;
}

.messageTextWhatsApp.left .areaMessageTextWhatsApp.initial {
    border-top-left-radius: 0px;
}

.messageTextWhatsApp.right .areaMessageTextWhatsApp.initial {
    border-top-right-radius: 0px;
}

.messageTextWhatsApp.right .areaMessageTextWhatsApp.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageTextWhatsApp.left .areaMessageTextWhatsApp.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp {
    position: relative;
    vertical-align: baseline;
    display: flex;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .iconDeletedMessageTextWhatsApp {
    fill: rgba(17,27,33,.35);
    margin-right: 7px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp {
    position: absolute;
    top: 0;
    font-size: 14px;
    z-index: 100;
    display: none;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp.initial {
    display: block;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp.left {
    position: absolute;
    left: -8px;
    color: #fff;
    fill: #fff;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp.right {
    right: -8px;
    color: #d9fdd3;
    fill: #d9fdd3;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp.left.emphasis {
    fill: #cfcdcdd0;
    transition: fill .5s;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .iconStartMessageTextWhatsApp.right.emphasis {
    fill: #b7d5b2d6;
    transition: fill .5s;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp {
    flex-direction: row;
    min-width: 20px;
    white-space: pre-wrap;
    overflow: visible;
    word-wrap: break-word;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .textMessageTextWhatsApp {
    color: rgb(17, 27, 33);
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .textMessageTextWhatsApp.deleted {
    color: #8696a0;
    text-decoration: line-through;
    /* font-style: italic; RESERVED */
    /* font-family: SF_Pro_Text_Light; RESERVED */
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .textSpaceMessageTextWhatsApp {
    width: 59px;
    height: 17px;
    content: " ";
    display: inline-block;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp {
    padding: 5px 12px 7px 12px;
    min-height: 42px;
    max-height: 92px;
    overflow: hidden;
    position: relative;
    border-radius: 7.5px;
    margin: -3px -4px 6px -6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp.right {
    background-color: rgb(209, 244, 204);
    min-width: 150px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp.left {
    background-color: #f5f6f6;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp.right.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp.left.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .shadow {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 800;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    background: linear-gradient(15deg,rgba(11,20,26,0),rgba(11,20,26,0) 45%,rgba(11,20,26,.12) 70%,rgba(11,20,26,.33));
    opacity: 0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp:hover .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .shadow {
    opacity: 1;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .detailsAnswerMessageTextWhatsApp {
    width: calc(100% - 58px);
    min-width: 110px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .detailsAnswerMessageTextWhatsApp .textAnswerMessageTextWhatsApp {
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: #667781;
    font-size: 12.6px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .detailsAnswerMessageTextWhatsApp .textAnswerMessageTextWhatsApp .iconTypeAnswerMessageTextWhatsApp {
    fill: #8696a0;
    margin-right: 3px;
    margin-top: -2px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .detailsAnswerMessageTextWhatsApp .nameAnswerMessageTextWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .innerImageAnswerMessageTextWhatsApp {
    width: 58px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;    
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp .innerImageAnswerMessageTextWhatsApp .imageInnerImageAnswerMessageTextWhatsApp {
    background-size: cover;
    height: 100%;
    background-position: center;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .innerTextMessageTextWhatsApp .innerAnswerTextMessageTextWhatsApp::before {
    background-color: rgb(6, 207, 156);
    width: 4px;
    height: 120px;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
    top: -15px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    vertical-align: middle;
    z-index: 800;
    width: 42px;
    height: 27px;
    overflow: hidden;
} 

.messageTextWhatsApp .areaMessageTextWhatsApp.emphasis .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp {
    display: none!important;
}

.messageTextWhatsApp .areaMessageTextWhatsApp:hover .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp {
    background: radial-gradient(at top right,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 80%);
}

.messageTextWhatsApp .areaMessageTextWhatsApp:hover .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp.sentForMe {
    background: radial-gradient(at top right,rgba(217,253,211,1) 60%,rgba(217,253,211,0) 80%);
}

.messageTextWhatsApp .areaMessageTextWhatsApp:hover .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp.answer {
    background: transparent;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #fff;
    margin-right: -8px;
    opacity: 0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp.sentFromMe {
    background-color: #d9fdd3;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp.answer {
    background-color: transparent;
}

.messageTextWhatsApp .areaMessageTextWhatsApp:hover .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp {
    margin-right: 2px;
    transition: margin 0.08s;
    opacity: 1;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #8696a0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .moreActionsMessageTextWhatsApp .buttonDropMoreActionsMessageTextWhatsApp.answer .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #fff;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp {
    height: 15px;
    min-width: 42px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp {
    position: absolute;
    height: 100%;
    right: 0;
    bottom: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp .timeMessageTextWhatsApp {
    color: #667781;
    font-size: 11px;
    font-weight: 400;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp .iconStatusMessageTextWhatsApp {
    margin-left: 3px;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp .iconStatusMessageTextWhatsApp.sent {
    fill: #8696a0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp .iconStatusMessageTextWhatsApp.delivered {
    fill: #8696a0;
}

.messageTextWhatsApp .areaMessageTextWhatsApp .innerMessageTextWhatsApp .detailsTimeAndStatusMessageTextWhatsApp .innerDetailsTimeAndStatusMessageTextWhatsApp .iconStatusMessageTextWhatsApp.read {
    fill: #53bdeb;
}
@font-face {
    font-family: SF_Pro_Text_Light;
    src: url(/static/media/SF-Pro-Text-Light.b411f9bf.otf);
}

.messageDocumentWhatsApp {
    width: 100%;
    background-color: transparent!important;
    border-radius: 5px;
    margin-bottom: 2px;
    padding-right: 63px;
    padding-left: 63px;
    display: flex;
}

.messageDocumentWhatsApp .buttonDefaultMessageDocumentWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.messageDocumentWhatsApp.right {
    justify-content: flex-end;
}

.messageDocumentWhatsApp.left {
    justify-content: flex-start;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp {
    position: relative;
    color: #111b21;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 7.5px;
    max-width: 65%;
    padding: 6px 7px 8px 9px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    z-index: 200;
    position: relative;
}

.messageDocumentWhatsApp.right .areaMessageDocumentWhatsApp {
    background-color: #d9fdd3;
}

.messageDocumentWhatsApp.left .areaMessageDocumentWhatsApp {
    background-color: #fff;
}

.messageDocumentWhatsApp.left .areaMessageDocumentWhatsApp.initial {
    border-top-left-radius: 0px;
}

.messageDocumentWhatsApp.right .areaMessageDocumentWhatsApp.initial {
    border-top-right-radius: 0px;
}

.messageDocumentWhatsApp.right .areaMessageDocumentWhatsApp.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageDocumentWhatsApp.left .areaMessageDocumentWhatsApp.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp {
    position: relative;
    vertical-align: baseline;
    display: flex;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp.noMessage {
    flex-direction: column;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .iconDeletedMessageDocumentWhatsApp {
    fill: rgba(17,27,33,.35);
    margin-right: 7px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp {
    position: absolute;
    top: 0;
    font-size: 14px;
    z-index: 100;
    display: none;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp.initial {
    display: block;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp.left {
    position: absolute;
    left: -8px;
    color: #fff;
    fill: #fff;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp.right {
    right: -8px;
    color: #d9fdd3;
    fill: #d9fdd3;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp.left.emphasis {
    fill: #cfcdcdd0;
    transition: fill .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .iconStartMessageDocumentWhatsApp.right.emphasis {
    fill: #b7d5b2d6;
    transition: fill .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp {
    flex-direction: row;
    min-width: 20px;
    white-space: pre-wrap;
    overflow: visible;
    word-wrap: break-word;
    cursor: pointer;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .textMessageDocumentWhatsApp {
    color: rgb(17, 27, 33);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .textMessageDocumentWhatsApp.deleted {
    color: #8696a0;
    font-style: italic;
    text-decoration: line-through;
    font-family: SF_Pro_Text_Light;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .textSpaceMessageDocumentWhatsApp {
    width: 59px;
    height: 17px;
    content: " ";
    display: inline-block;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp {
    padding: 5px 12px 7px 12px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    min-height: 42px;
    max-height: 82px;
    overflow: hidden;
    position: relative;
    border-radius: 7.5px;
    margin: -3px -4px 6px -6px;
    flex-direction: column;
    cursor: pointer;
    position: relative;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp.right {
    background-color: #d1f4cc;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp.left {
    background-color: #f5f6f6;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp.right.emphasis {
    background-color: #b7d5b2d6;
    transition: background-color .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp.left.emphasis {
    background-color: #cfcdcdd0;
    transition: background-color .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .shadow {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 800;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    background: linear-gradient(15deg,rgba(11,20,26,0),rgba(11,20,26,0) 45%,rgba(11,20,26,.12) 70%,rgba(11,20,26,.33));
    opacity: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .shadow {
    opacity: 1;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .detailsAnswerMessageDocumentWhatsApp {
    width: calc(100% - 58px);
    min-width: 110px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .detailsAnswerMessageDocumentWhatsApp .textAnswerMessageDocumentWhatsApp {
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: #667781;
    font-size: 12.6px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .detailsAnswerMessageDocumentWhatsApp .textAnswerMessageDocumentWhatsApp .iconTypeAnswerMessageDocumentWhatsApp {
    fill: #8696a0;
    margin-right: 3px;
    margin-top: -2px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .detailsAnswerMessageDocumentWhatsApp .nameAnswerMessageDocumentWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .innerImageAnswerMessageDocumentWhatsApp {
    width: 58px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;    
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .innerImageAnswerMessageDocumentWhatsApp .imageInnerImageAnswerMessageDocumentWhatsApp {
    background-size: cover;
    height: 100%;
    background-position: center;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .textAnswerMessageDocumentWhatsApp {
    color: #667781;
    font-size: 12.6px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .textAnswerMessageDocumentWhatsApp .iconTypeAnswerMessageDocumentWhatsApp {
    fill: #8696a0;
    margin-right: 3px;
    margin-top: -2px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp .nameAnswerMessageDocumentWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerAnswerTextMessageDocumentWhatsApp::before {
    background-color: rgb(6, 207, 156);
    width: 4px;
    height: 100%;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp {
    margin: -3px -4px 6px -6px;
    border-radius: 6px;
    padding: 13px 19px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: flex-start;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp.left {
    background-color: #f5f6f6;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp.left.emphasis {
    background-color: #cfcdcdd0;
    transition: background-color .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp.right {
    background-color: #d1f4cc;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp.right.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp.noMessage {
    margin-bottom: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .areaIconFileMessageDocumentWhatsApp {
    height: 100%;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .areaIconFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp {
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px!important;
    width: 26px!important;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp.pdf {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAvVBMVEUAAAD3d3fxRkbyRkbyRkb3fn74g4PyRUX3lZXzRUXyRUX/RkbyQED3fn7zRUX2iYn/Rkb4lpb0cHDvQEDzRETzRkbzQEDzQkLzOzv////939/yODj2enr4kJD0UVHzPT38xMT92tr5lZX1ZGT+6+v4g4P1bW35m5v7yMj91dX3fn76sLD1XFz6qan//f36trb+5OT0VVX6rKz7vb3yNDT4iIj/9vb0TEz5o6PtTEzlRUXsYWHiNzfyRETsSEiKDgROAAAAFHRSTlMAH+Z5+80n+JHu0AWTv6w6CX3V/BbNCjoAAAHfSURBVHgB7daDsisxGMDxHNtftLZqu+//WDebOu1p08H1f7D8zRoIoferx4djXb4htYvXy7vrI32NXi4U8v6KjzcbFTcKer48bQplS3dYwyhbwhpGRTpGRZpGoNvzjUT6RkU6RkX6RiJNM7dUhE/2NYkVhDU2lCgIa6DxxNr0eathBPoab5re6xjJ1pkaRu2XmP8GiIzKCSrHAW9NiKhiwKnKsFkukRMOZaWCVnWZA7uGxEaZn6UDApBGYjz3ekDFZtrGoig1FRPwZXnoQoXL6p0qwSTjy9pdxdicG41K1ue8g6kwRiWLOPcHQDzO84aoE7N9M2SMWX1eD11hal0zNMQ8tzQ2c0UU75saBXB9zhMmTJthsyc2J7cTuIRSQQ5sxzRZK9oYN4x4VGXCDMtz14IDxiusntiM0SILQ6oG71umMLKcHTCL+rEL+qZe5iWUwta+hUwYz7btoCAHTCdJEgcTgKUxY84NfPwc1AihABhLM+x2N+c6oICXqWa5RJo8bTQ59x04YgJhYMvIMnnvfGtivxmsTdr0RbmNCca0ZjR7FCtGNnCcAd6aEIELyhLFABXb35qg62k59v9d9d/8MnN5fa75QFeXZ5qvN4SezzTPCKEn8et/p9vD49UT+gEdNgrQ89qG8QAAAABJRU5ErkJggg==);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp.general {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp.doc,
.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp.docx {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAulBMVEUAAACPw/ePw/lgqvVfqvaUx/mQxPhfqfWjz/lfqfVfqvZgp/9cp/ZfqvZgqvaayPpTpPVfqvVjpvNgqfaQw/mjzvq42PdfqvZgqvZiq/ZwsveRxflkqe9rsPdcqPZeqPRorvfN5fzw9/5bp/Z2tvdZoOqTxvqCuvPj8P7+//+QxPmBvfl3su+Pw/lWpfWx1fuJwPlUpPWVxvnp8/7////4+/9ure3C3/uq0fvb7P1usffn8v15tfFMmOceGILeAAAAF3RSTlMAIdHm7ijJ+JH8eQeTzaw68foX1Ll9kGZdllAAAAEQSURBVHgB7dZVcsMwFIVhhTkp5krmMJcZ9r+sMl7PEczopfC/f7ZFtoUQreZuWVepJ3g73VKtoSvaKzDS6kp9abSsMtQumQ1HtYbZcCSl2TBkZRiyNU+o4m6WxYqzeUIFd/P0eO7m6fGsDUNmQ+lsypA0ll6E4XdkYSbrTTj96AlJczSJ1rOP7vrQsFt9dl/HBnX8y00Cg0YFqBtkKJuDbgdEwMSLM9Cp9GnUCWoD54AOYH7XZwRDhtQRagVNfA5azKXX9RmirpGRaowKfM6bt/NDekPZYa6rIAEGz/VwNfJvskGuy3FimAOVzzhvlA+Z//f1vyltu5p90Sw5mq2eEG1H03759e+Ua7aVO82WeASxdDP0M8Z9fAAAAABJRU5ErkJggg==);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .iconFileMessageDocumentWhatsApp.xls {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAulBMVEUAAACK4K5X0otX14eI362F3aqG4q1X0Yqe5LxX0YpW0otT0YhS0YdW0otW0otX0otY0Iug5Lqg5r2e5byf3rr///9O0IVR0IdU0YlX0otV0opV0YpKz4L+//5S0YjJ8dqK4K7p+fCQ4rKI36x426KL4K5t15p12qDT9OFy2Z5Lz4JXyIdZzotb046r6MVY0oz9/v2D3qpo15e47M593KXc9uj4/fqh5r5PxYHL8dvP8t687dCd5bue5bzVEOpnAAAAFXRSTlMAwekIyxwk+JHcffGW/KzN0Ss+eo5vCipPAAABZklEQVR4Ae3WhfaqMACA8dn1T3VBd9jd+v6PZY/L2YExbtdnKz8aAQBQar43eH18ArbKW7HGbVioMKT0JvFThh6LXorZxmRQ7VXAMIgPqDHLldyGTimPuaFqfmOWq/kNXSZxQ6ckaByTQQJm6TFIyoz4thlHQmbg2p4XR5II8mdOVPkzMny0HURtu9RkpUSpdWrE6//VBqnpoUQjS87mmNbGUZIMkfYwvZ0uJxjla83aurWG0Iq35pow6F0zIJz5vagg5BpDQRjjBYTzPqYhxeCbEbm+PUDN1yUaGf0Io8uyfDcyTc8yE0wIuZo5IjQ84RtrfGsF4WIcy/ru2xROb2nPVxrfhMvAdV0DQjNwacEy/A7r4BdtU6wi1L/tbyqiqZhvVvatEMKTZ39pxTVa4rrWvvc2naYFDcbQXGeWlhNQwhiM0sN/zv/1f1N8zWs6oFnMaVptAF5ympf7pf9HqyZa671ZAhesTDDT4wdnEAAAAABJRU5ErkJggg==);    
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp {
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    top: -2px;
    max-width: 90%;
    width: 100%;
    /* white-space: nowrap; */
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp .areaNameFileMessageDocumentWhatsApp {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp .areaNameFileMessageDocumentWhatsApp .nameFileMessageDocumentWhatsApp {
    color: rgb(17, 27, 33);
    line-height: 19px;
    font-weight: 400;
    word-break: break-all;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp .othersDetailsFileMessageDocumentWhatsApp {
    padding-top: 3px;
    padding-bottom: 3px;
    color: #8696a0;
    line-height: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp .othersDetailsFileMessageDocumentWhatsApp .infoOthersDetailsFileMessageDocumentWhatsApp {
    color: #8696a0;
    font-size: 11px;
    line-height: 15px;
    display: inline-block;
    text-wrap: nowrap;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .detailsFileMessageDocumentWhatsApp .othersDetailsFileMessageDocumentWhatsApp .pointOthersDetailsFileMessageDocumentWhatsApp {
    line-height: 15px;
    color: #8696a0;
    font-weight: 400;
    margin: 0;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 11px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .innerTextMessageDocumentWhatsApp .innerFileMessageDocumentWhatsApp .buttonDownloadFileMessageDocumentWhatsApp .iconButtonDownloadFileMessageDocumentWhatsApp {
    fill: rgba(84,101,111, .5);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    vertical-align: middle;
    z-index: 800;
    width: 42px;
    height: 27px;
    overflow: hidden;
} 

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp.emphasis .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp {
    display: none!important;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.left {
    background: radial-gradient(at top right,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 80%);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.right {
    background: radial-gradient(at top right,rgba(217,253,211,1) 60%,rgba(217,253,211,0) 80%);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.noAnswer, 
.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.answer {
    background: transparent!important;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #fff;
    margin-right: -8px;
    opacity: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp.answer {
    background-color: transparent;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp.left.noAnswer {
    background-color: #f5f6f6;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp.right.noAnswer {
    background-color: #d1f4cc;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.right.noAnswer {
    background: linear-gradient(90deg,rgba(209,244,204,0) 0,rgba(209,244,204,1) 50%);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp.left.noAnswer {
    background: linear-gradient(90deg,rgba(245,246,246,0) 0,rgba(245,246,246,1) 50%);
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp:hover .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp {
    margin-right: 2px;
    transition: margin 0.08s;
    opacity: 1;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #8696a0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .moreActionsMessageDocumentWhatsApp .buttonDropMoreActionsMessageDocumentWhatsApp.answer .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #fff;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp {
    height: 15px;
    min-width: 42px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp.noMessage {
    position: relative;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp {
    position: absolute;
    height: 100%;
    right: 0;
    bottom: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    /* border: 1px solid green; */
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .timeMessageDocumentWhatsApp {
    color: #667781;
    font-size: 11px;
    font-weight: 400;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .iconStatusMessageDocumentWhatsApp {
    margin-left: 3px;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .iconStatusMessageDocumentWhatsApp.sent {
    fill: #8696a0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .iconStatusMessageDocumentWhatsApp.delivered {
    fill: #8696a0;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .iconStatusMessageDocumentWhatsApp.read {
    fill: #53bdeb;
}

.messageDocumentWhatsApp .areaMessageDocumentWhatsApp .innerMessageDocumentWhatsApp .detailsTimeAndStatusMessageDocumentWhatsApp .innerDetailsTimeAndStatusMessageDocumentWhatsApp .iconStatusMessageDocumentWhatsApp.deleted {
    fill: #8696a0;
}
@font-face {
    font-family: SF_Pro_Text_Light;
    src: url(/static/media/SF-Pro-Text-Light.b411f9bf.otf);
}

.messageImageWhatsApp {
    width: 100%;
    background-color: transparent!important;
    border-radius: 5px;
    margin-bottom: 2px;
    padding-right: 63px;
    padding-left: 63px;
    display: flex;
}

.messageImageWhatsApp .areaLoadingMessageImageWhatsApp {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.messageImageWhatsApp .areaLoadingMessageImageWhatsApp .loadingWhatsApp {
    width: 74px;
    height: 74px;
    position: relative;
}

.messageImageWhatsApp .buttonDefaultMessageImageWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.messageImageWhatsApp.right {
    justify-content: flex-end;
}

.messageImageWhatsApp.left {
    justify-content: flex-start;
}

.messageImageWhatsApp .areaMessageImageWhatsApp {
    position: relative;
    color: #111b21;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 7.5px;
    max-width: 65%;
    padding: 6px 7px 8px 9px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    z-index: 200;
    position: relative;
}

.messageImageWhatsApp.right .areaMessageImageWhatsApp {
    background-color: #d9fdd3;
}

.messageImageWhatsApp.left .areaMessageImageWhatsApp {
    background-color: #fff;
}

.messageImageWhatsApp.left .areaMessageImageWhatsApp.initial {
    border-top-left-radius: 0px;
}

.messageImageWhatsApp.right .areaMessageImageWhatsApp.initial {
    border-top-right-radius: 0px;
}

.messageImageWhatsApp.right .areaMessageImageWhatsApp.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageImageWhatsApp.left .areaMessageImageWhatsApp.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp {
    position: relative;
    vertical-align: baseline;
    display: flex;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .iconDeletedMessageImageWhatsApp {
    /* fill: rgba(17,27,33,.35); */
    fill: #fff;
    margin-right: 7px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp {
    position: absolute;
    top: 0;
    font-size: 14px;
    z-index: 100;
    display: none;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp.initial {
    display: block;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp.left {
    position: absolute;
    left: -8px;
    color: #fff;
    fill: #fff;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp.right {
    right: -8px;
    color: #d9fdd3;
    fill: #d9fdd3;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp.left.emphasis {
    fill: #cfcdcdd0;
    transition: fill .5s;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .iconStartMessageImageWhatsApp.right.emphasis {
    fill: #b7d5b2d6;
    transition: fill .5s;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp {
    flex-direction: row;
    min-width: 20px;
    white-space: pre-wrap;
    overflow: visible;
    word-wrap: break-word;
    max-width: 250px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .textMessageImageWhatsApp {
    color: rgb(17, 27, 33);
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .textMessageImageWhatsApp.deleted {
    color: #8696a0;
    font-style: italic;
    text-decoration: line-through;
    font-family: SF_Pro_Text_Light;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .textSpaceMessageImageWhatsApp {
    width: 59px;
    height: 17px;
    content: " ";
    display: inline-block;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp {
    padding: 5px 12px 7px 12px;
    min-height: 42px;
    max-height: 92px;
    overflow: hidden;
    position: relative;
    border-radius: 7.5px;
    margin: -3px -4px 6px -6px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp.right {
    background-color: rgb(209, 244, 204);
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp.left {
    background-color: #f5f6f6;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp.right.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp.left.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .shadow {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 800;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    background: linear-gradient(15deg,rgba(11,20,26,0),rgba(11,20,26,0) 45%,rgba(11,20,26,.12) 70%,rgba(11,20,26,.33));
    opacity: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .shadow {
    opacity: 1;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .detailsAnswerMessageImageWhatsApp {
    width: calc(100% - 58px);
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .detailsAnswerMessageImageWhatsApp .textAnswerMessageImageWhatsApp {
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: #667781;
    font-size: 12.6px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .detailsAnswerMessageImageWhatsApp .textAnswerMessageImageWhatsApp .iconAnswerMessageImageWhatsApp {
    fill: rgb(134, 150, 160);
    margin-right: 5px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .detailsAnswerMessageImageWhatsApp .nameAnswerMessageImageWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .innerImageAnswerMessageImageWhatsApp {
    width: 58px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp .innerImageAnswerMessageImageWhatsApp .imageInnerImageAnswerMessageImageWhatsApp {
    background-size: cover;
    height: 100%;
    background-position: center;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerAnswerTextMessageImageWhatsApp::before {
    background-color: rgb(6, 207, 156);
    width: 4px;
    height: 100%;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp {
    margin-top: -3px;
    margin-right: -4px;
    margin-bottom: 5px;
    margin-left: -6px;
    overflow: hidden;
    position: relative;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp .filterEmphasisImageAnswerMessageImageWhatsApp {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp .filterEmphasisImageAnswerMessageImageWhatsApp.emphasis.right {
    background: #0000003f;
    transition: opacity .5s;
    opacity: 1;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp .filterEmphasisImageAnswerMessageImageWhatsApp.emphasis.left {
    background: #0000003f;
    transition: opacity .5s;
    opacity: 1;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp.noMessage {
    margin-bottom: -5px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .innerTextMessageImageWhatsApp .innerImageMessageImageWhatsApp .imageMessageImageWhatsApp {
    border-radius: 7.5px;
    cursor: pointer;
    width: 100%;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    vertical-align: middle;
    z-index: 800;
    width: 42px;
    height: 27px;
    overflow: hidden;
} 

.messageImageWhatsApp .areaMessageImageWhatsApp.emphasis .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp {
    display: none!important;
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp {
    background: radial-gradient(at top right,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 80%);
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp.sentForMe {
    background: radial-gradient(at top right,rgba(217,253,211,1) 60%,rgba(217,253,211,0) 80%);
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp.answer {
    background: transparent;
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp.noAnswer {
    background: transparent;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #fff;
    margin-right: -8px;
    opacity: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp.sentFromMe {
    background-color: #d9fdd3;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp.answer {
    /* nothing */
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp.noAnswer {
    background: transparent;
}

.messageImageWhatsApp .areaMessageImageWhatsApp:hover .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp {
    margin-right: 2px;
    transition: margin 0.08s;
    opacity: 1;
    background-color: transparent;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #8696a0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp.noAnswer .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #fff;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .moreActionsMessageImageWhatsApp .buttonDropMoreActionsMessageImageWhatsApp.answer .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #fff;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp {
    height: 15px;
    min-width: 42px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp.noMessage {
    height: 20px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp {
    position: absolute;
    height: 100%;
    right: 0;
    bottom: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .timeMessageImageWhatsApp {
    color: #667781;
    font-size: 11px;
    font-weight: 400;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp.noMessage .innerDetailsTimeAndStatusMessageImageWhatsApp .timeMessageImageWhatsApp {
    color: #fff;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp {
    margin-left: 3px;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp.sent {
    fill: #8696a0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp.delivered {
    fill: #8696a0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp.read {
    fill: #53bdeb;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp.deleted {
    fill: #8696a0;
}

.messageImageWhatsApp .areaMessageImageWhatsApp .innerMessageImageWhatsApp .detailsTimeAndStatusMessageImageWhatsApp .innerDetailsTimeAndStatusMessageImageWhatsApp .iconStatusMessageImageWhatsApp.deleted.noMessage {
    fill: #fff;
}
@font-face {
    font-family: SF_Pro_Text_Light;
    src: url(/static/media/SF-Pro-Text-Light.b411f9bf.otf);
}

.messageAudioWhatsApp {
    width: 100%;
    background-color: transparent!important;
    border-radius: 5px;
    margin-bottom: 2px;
    padding-right: 63px;
    padding-left: 63px;
    display: flex;
}

.messageAudioWhatsApp .buttonDefaultMessageAudioWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.messageAudioWhatsApp.right {
    justify-content: flex-end;
}

.messageAudioWhatsApp.left {
    justify-content: flex-start;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp {
    position: relative;
    color: #111b21;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 7.5px;
    max-width: 65%;
    padding: 6px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    z-index: 200;
    position: relative;
}

.messageAudioWhatsApp.right .areaMessageAudioWhatsApp {
    background-color: #d9fdd3;
}

.messageAudioWhatsApp.left .areaMessageAudioWhatsApp {
    background-color: #fff;
}

.messageAudioWhatsApp.left .areaMessageAudioWhatsApp.initial {
    border-top-left-radius: 0px;
}

.messageAudioWhatsApp.right .areaMessageAudioWhatsApp.initial {
    border-top-right-radius: 0px;
}

.messageAudioWhatsApp.right .areaMessageAudioWhatsApp.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageAudioWhatsApp.left .areaMessageAudioWhatsApp.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp {
    position: relative;
    vertical-align: baseline;
    display: flex;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .iconDeletedMessageAudioWhatsApp {
    fill: rgba(17,27,33,.35);
    margin-right: 7px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp {
    position: absolute;
    top: 0;
    font-size: 14px;
    z-index: 100;
    display: none;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp.initial {
    display: block;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp.left {
    position: absolute;
    left: -8px;
    color: #fff;
    fill: #fff;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp.right {
    right: -8px;
    color: #d9fdd3;
    fill: #d9fdd3;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp.left.emphasis {
    fill: #cfcdcdd0;
    transition: fill .5s;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .iconStartMessageAudioWhatsApp.right.emphasis {
    fill: #b7d5b2d6;
    transition: fill .5s;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp {
    flex-direction: row;
    min-width: 20px;
    white-space: pre-wrap;
    overflow: visible;
    word-wrap: break-word;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .textMessageAudioWhatsApp {
    color: rgb(17, 27, 33);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .textMessageAudioWhatsApp.deleted {
    color: #8696a0;
    font-style: italic;
    font-family: SF_Pro_Text_Light;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .textSpaceMessageAudioWhatsApp {
    width: 59px;
    height: 17px;
    content: " ";
    display: inline-block;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp {
    padding: 5px;
    padding: 5px 12px 7px 12px;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    min-height: 42px;
    max-height: 82px;
    overflow: hidden;
    position: relative;
    border-radius: 7.5px;
    margin: -3px -4px 6px -3px;
    flex-direction: column;
    cursor: pointer;
    position: relative;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp.right {
    background-color: rgb(209, 244, 204);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp.left {
    background-color: #f5f6f6;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp.right.emphasis {
    background-color: #b7d5b2d6;
    transition: background .5s;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp.left.emphasis {
    background-color: #cfcdcdd0;
    transition: background .5s;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .shadow {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 800;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    background: linear-gradient(15deg,rgba(11,20,26,0),rgba(11,20,26,0) 45%,rgba(11,20,26,.12) 70%,rgba(11,20,26,.33));
    opacity: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .shadow {
    opacity: 1;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .textAnswerMessageAudioWhatsApp {
    color: #667781;
    font-size: 12.6px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: pre-wrap;
    line-height: 19px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .textAnswerMessageAudioWhatsApp.hasImage {
    width: calc(100% - 58px); /* width of the answer image */
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .nameAnswerMessageAudioWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp::before {
    background-color: rgb(6, 207, 156);
    width: 4px;
    height: 100%;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .iconAnswerMessageAudioWhatsApp {
    fill: #8696a0;
    margin-right: 3px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .innerImageAnswerMessageAudioWhatsApp {
    width: 58px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAnswerTextMessageAudioWhatsApp .innerImageAnswerMessageAudioWhatsApp .imageInnerImageAnswerMessageAudioWhatsApp {
    background-size: cover;
    height: 100%;
    background-position: center;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio {
    min-width: 50px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioOneMessageAudioMessage {
    margin-left: 4px;
    margin-right: 12px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioOneMessageAudioMessage .buttonStatusRunningMessagewhatsApp .iconButtonStatusRunningMessagewhatsApp {
    fill: rgba(84, 101, 111, 0.5);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioOneMessageAudioMessage .buttonStatusRunningMessagewhatsApp .iconButtonStatusRunningMessagewhatsApp.right {
    fill: #6f8171;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioOneMessageAudioMessage .buttonStatusRunningMessagewhatsApp .iconButtonStatusRunningMessagewhatsApp.left {
    fill: #9c8d8d;    
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioOneMessageAudioMessage .imageLoadingMessageAudioWhatsApp {
    width: 50px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioTwoMessageAudioWhatsApp {
    flex-direction: column;
    position: relative;
    margin-right: 19px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlsAudio.controlAudioTwoMessageAudioWhatsApp .audioPlayerMessageAudioWhatsApp {
    /* nothing yet */
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp {
    width: 100%;
    position: absolute;
    bottom: -21px;
    height: 40px;
    margin-bottom: -2px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration {
    font-size: 11px;
    color: #8696a0;
    font-weight: 400;
    display: flex;
    align-items: baseline;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration .iconStatusMessageAudioWhatsApp {
    margin-left: 3px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration .iconStatusMessageAudioWhatsApp.sent {
    fill: #8696a0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration .iconStatusMessageAudioWhatsApp.delivered {
    fill: #8696a0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration .iconStatusMessageAudioWhatsApp.read {
    fill: #53bdeb;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .timeDuration .iconStatusMessageAudioWhatsApp.deleted {
    fill: #8696a0;
    height: 16px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .currentDurationAudioWhatsApp {
    float: left;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .maximumDurationAudioWhatsApp {
    float: right;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioTwoMessageAudioWhatsApp .innerTimeDurationAudioWhatsApp .maximumDurationAudioWhatsApp.right {
    margin-right: -16px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp {
    min-width: 55px; /* width of avatar component */
    position: relative;
    margin-left: 4px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .buttonSpeedMessageAudioWhatsApp {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    text-align: center;
    font-size: 13px;
    min-width: 48px;
    color: #fff;
    font-weight: 500;
    background-color: rgba(84,101,111,0.5);
    font-family: SF_Pro_Text_Light;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .areaAvatarMessageAudioWhatsApp {
    height: 55px;
    width: 55px;
    overflow: hidden;
    border-radius: 50%;
    border: none;
    margin-right: auto;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .areaAvatarMessageAudioWhatsApp .imageAvatarMessageAudioWhatsApp {
    height: 100%;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .areaAvatarMessageAudioWhatsApp .imageDefaultAvatarMessageAudioWhatsApp {
    height: 55px;
    width: 55px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .iconMicrophoneMessageAudioWhatsApp {
    position: absolute;
    bottom: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .iconMicrophoneMessageAudioWhatsApp.right {
    right: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .iconMicrophoneMessageAudioWhatsApp.left {
    left: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .iconMicrophoneMessageAudioWhatsApp.noListened {
    fill: rgba(17,27,33,0.7);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .innerTextMessageAudioWhatsApp .innerAudioMessageAudioWhatsApp .controlAudioThreeMessageAudioWhatsApp .iconMicrophoneMessageAudioWhatsApp.listened {
    fill: #29afdf;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    vertical-align: middle;
    z-index: 800;
    width: 42px;
    height: 27px;
    overflow: hidden;
} 

.messageAudioWhatsApp .areaMessageAudioWhatsApp.emphasis .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp {
    display: none!important;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp {
    background: radial-gradient(at top right,rgba(255,255,255,1) 60%,rgba(255,255,255,0) 80%);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp.right {
    background: radial-gradient(at top right,rgba(217,253,211,1) 60%,rgba(217,253,211,0) 80%);
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp.answer {
    background: transparent;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp.left.noAnswer {
    right: 68px;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #fff;
    margin-right: -8px;
    opacity: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp.sentFromMe {
    background-color: #d9fdd3;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp.answer {
    background-color: transparent;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp:hover .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp {
    margin-right: 2px;
    transition: margin 0.08s;
    opacity: 1;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #8696a0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .moreActionsMessageAudioWhatsApp .buttonDropMoreActionsMessageAudioWhatsApp.answer .iconButtonDropMoreActionsMessageWhatsApp {
    fill: #fff;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .detailsTimeAndStatusMessageAudioWhatsApp {
    height: 15px;
    min-width: 42px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .detailsTimeAndStatusMessageAudioWhatsApp .innerDetailsTimeAndStatusMessageAudioWhatsApp {
    position: absolute;
    height: 100%;
    right: 0;
    bottom: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.messageAudioWhatsApp .areaMessageAudioWhatsApp .innerMessageAudioWhatsApp .detailsTimeAndStatusMessageAudioWhatsApp .innerDetailsTimeAndStatusMessageAudioWhatsApp .timeMessageAudioWhatsApp {
    color: #667781;
    font-size: 11px;
    font-weight: 400;
}
.audioPlayer {
    height: 50px;
    min-width: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.audioPlayer .rangeAudioPlayer {
    width: 100%;
}

.audioPlayer .audioAudioPlayer {
    position: absolute;
    left: 0;
    top: 0;
}

/* https://www.smashingmagazine.com/2021/12/create-custom-range-input-consistent-browsers/ */
.audioPlayer input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    background-color: #e7e8e9;
    height: 3px;
    transition: background .5s;
    z-index: 200;
}

.audioPlayer .progressAudioPlayer {
    width: 0%;
    height: 5px;
    background-color: #30b0e8;
    content: " ";
    left: 0;
    position: absolute;
    margin-top: -1px;
    z-index: 100;
}

/***** Start Track Styles *****/
/***** Start Track Styles *****/
/***** Start Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.audioPlayer input[type="range"]::-webkit-slider-runnable-track {
    /* DANGER: Don't uncomment and remove. */
    /* background: linear-gradient(to right, #30b0e8 5%, grey 5%); WARNING: setting the progress here, on zero position */
    width: 100%;
    height: 3px;
}

.audioPlayer input[type="range"].safari::-webkit-slider-runnable-track {
    width: 50%!important;
    height: 5px;
    margin-top: -3px;
    background-color: none;
    box-shadow: none;
    border: none;
    outline: none;
}

/******** Firefox ********/
.audioPlayer input[type="range"]::-moz-range-track {
    /* DANGER: Don't uncomment and remove. */
    /* background: linear-gradient(to right, #30b0e8 5%, grey 5%); WARNING: setting the progress here, on zero position */
    width: 20%;
    height: 3px;
}
/***** Ends Track Styles *****/
/***** Ends Track Styles *****/
/***** Ends Track Styles *****/

/***** Start Thumb Styles *****/
/***** Start Thumb Styles *****/
/***** Start Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.audioPlayer input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #30b0e8;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    margin-top: -5px;
    z-index: 1;
}

.audioPlayer input[type="range"].noListened::-webkit-slider-thumb {
    background-color: #6f8171;
}

.audioPlayer input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: #30b0e8;
    height: 13px;
    width: 13px;
    border-radius: 50%;
}

.audioPlayer input[type="range"].noListened::-moz-range-thumb {
    background-color: #6f8171;
}
/***** Ends Thumb Styles *****/
/***** Ends Thumb Styles *****/
/***** Ends Thumb Styles *****/

@media (max-width: 1023px)
{
    .audioPlayer {
        min-width: 100px;
    }
}
.audioRecordingWhatsApp {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-left: 4px;
}

.audioRecordingWhatsApp .buttonDefaultAudioRecordingWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.audioRecordingWhatsApp .durationAudiotRecordingWhatsApp {
    color: rgb(59, 74, 84);
    font-size: 20px;
}

.audioRecordingWhatsApp .recordingAudioRecordingWhatsApp {
    max-width: 303px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
}

.audioRecordingWhatsApp .recordingAudioRecordingWhatsApp .innerPointsAudioRecordingWhatsApp {
    display: flex;
    border: 1px solid transparent;
    margin-left: calc(var(--whatsapp-width-point-audio-recording-triple) * -1);
}

.audioRecordingWhatsApp .recordingAudioRecordingWhatsApp .innerPointsAudioRecordingWhatsApp .pointRecordingAudioRecordingWhatsApp { /*width this poitn is: 12px */
    height: var(--whatsapp-width-point-audio-recording);
    width: var(--whatsapp-width-point-audio-recording);
    border-radius: 50%;
    background-color: rgb(132, 146, 155);
    content: " ";
    display: block;
    margin-left: var(--whatsapp-margin-point-audio-recording);
    margin-right: var(--whatsapp-margin-point-audio-recording);
    -webkit-animation: point 1s linear infinite;
            animation: point 1s linear infinite;
}

.audioRecordingWhatsApp .recordingAudioRecordingWhatsApp .lineAudioRecordingWhatsApp {
    display: block;
    content: " ";
    width: 100vw;
    height: 1px;
    background-color: rgb(132, 146, 155);
}

.audioRecordingWhatsApp .controlesAudioRecordingWhatsApp {
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    flex-direction: row;
    height: 46px;
    border-radius: 50px;
    background-color: #fff;
    margin: 0px 12px 0px 9px;
    padding: 0px 11px 0px 19px;
}

.audioRecordingWhatsApp .controlesAudioRecordingWhatsApp .durationAudioRecordingWhatsApp {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 20px;
    color: rgb(59, 74, 84);
    font-weight: 400;
}

.audioRecordingWhatsApp .controlesAudioRecordingWhatsApp .buttonDefaultAudioRecordingWhatsApp {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-right: 10px;
}

.audioRecordingWhatsApp .controlesAudioRecordingWhatsApp .buttonDefaultAudioRecordingWhatsApp .iconButtonDefaultAudioRecordingWhatsApp {
    fill: #667781;
}

@-webkit-keyframes point {
	from {
		-webkit-transform: translateX(calc(100% + (var(--whatsapp-width-point-audio-recording-triple))));
		        transform: translateX(calc(100% + (var(--whatsapp-width-point-audio-recording-triple))));
	}
	to {
		-webkit-transform: translateX(calc(var(--whatsapp-width-point-audio-recording-triple) * -1));
		        transform: translateX(calc(var(--whatsapp-width-point-audio-recording-triple) * -1));
	}
}

@keyframes point {
	from {
		-webkit-transform: translateX(calc(100% + (var(--whatsapp-width-point-audio-recording-triple))));
		        transform: translateX(calc(100% + (var(--whatsapp-width-point-audio-recording-triple))));
	}
	to {
		-webkit-transform: translateX(calc(var(--whatsapp-width-point-audio-recording-triple) * -1));
		        transform: translateX(calc(var(--whatsapp-width-point-audio-recording-triple) * -1));
	}
}
.buttonDetailsAndSelectPanelWhatsApp {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
}

.buttonDetailsAndSelectPanelWhatsApp .partOneButtonDetailsAndSelectPanelWhatsApp {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.buttonDetailsAndSelectPanelWhatsApp .partOneButtonDetailsAndSelectPanelWhatsApp .titleButtonDetailsAndSelectPanelWhatsApp {
    color: rgb(17, 27, 33);
    font-size: 16px;
    font-weight: 400;
}

.buttonDetailsAndSelectPanelWhatsApp .partOneButtonDetailsAndSelectPanelWhatsApp .iconButtonDetailsAndSelectPanelWhatsApp {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.buttonDetailsAndSelectPanelWhatsApp .partOneButtonDetailsAndSelectPanelWhatsApp .iconButtonDetailsAndSelectPanelWhatsApp svg {
    fill: rgb(102, 119, 129);
    margin-right: 8px;
}

.buttonDetailsAndSelectPanelWhatsApp .partTwoButtonDetailsAndSelectPanelWhatsApp {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.buttonDetailsAndSelectPanelWhatsApp .partTwoButtonDetailsAndSelectPanelWhatsApp .amountButtonDetailsAndSelectPanelWhatsApp {
    color: rgb(102, 119, 129);
    margin-right: 8px;
    font-size: 15px;
    font-weight: 400;
}

.buttonDetailsAndSelectPanelWhatsApp .partTwoButtonDetailsAndSelectPanelWhatsApp .iconButtonDetailsAndSelectPanelWhatsApp {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.buttonDetailsAndSelectPanelWhatsApp .partTwoButtonDetailsAndSelectPanelWhatsApp .iconButtonDetailsAndSelectPanelWhatsApp svg {
    fill: rgb(102, 119, 129);
}
.whatsAppInQueue {
    height: 100vh;
    position: relative;
    overflow: hidden;
}
.crm {
    background-color: #f2f2f2;
    position: relative;
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
}
.login {
    max-width: 1024px;
    padding: 0;
}

.login .navigation {
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.login .navigation .content {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.login .navigation .brand {
    font-size: 42px;
    text-decoration: none;
    color: #26292c;
    font-weight: 700;
}

.login .navigation .content .information {
    font-size: 15px;
    margin-right: 20px;
    color: rgba(38,41,44,0.88);
    font-weight: 300;
    font-family: "SourceSansPro", sans-serif;
}

.login .navigation .content .anchor {
    color: var(--primary-color);
    box-shadow: inset 0px 0px 0px 3px var(--primary-color);
    box-shadow: inset 0px 0px 0px 3px var(--primary-color);
    background: transparent;
    padding: 6px 15px;
    font-size: 20px;
    line-height: 1.5em;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}

.login .form {
    background-color: var(--white-light);
    background-color: #fff;
    max-width: 500px;
    margin-top: 40px;
    padding: 40px 60px 52px;
}

.login .form .title {
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #26292c;
    font-size: 34px;
    text-align: center;
    line-height: 1.2em;
    margin: 0 0 40px 0;
    font-weight: 900;
}

.login .form .group { 
    min-height: 83px;
    margin-bottom: 25px;
}

.login .form .group .anchorForgotPassword{ 
    text-decoration: none;
    position: absolute;
    right: 0;
    color: #888e94;
    text-decoration: none;
    font-size: 12px;
    z-index: 600;
    cursor: pointer;
}

.login .form .anchorLogin{ 
    text-decoration: none;
    color: #888e94;
    text-decoration: none;
    font-size: 12px;
    z-index: 600;
    cursor: pointer;
}

.login .form .group .buttonLogin{ 
    margin-bottom: 25px;
}

.login .form .spanError {
    visibility: hidden;
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.5em;
    text-align: left;
    color: var(--color-error);
    min-height: 1.5em;
}

.login .footer {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.login .footer .linkSupport{
    color: #747678;
    text-decoration: none;
}

.login .footer .linkCRM{
    color: #26292c;
    text-decoration: none;
}

@media (max-width: 767px)
{
    .login .navigation {
        padding-left: 6px;        
    }

    .login .form {
        margin-top: 0px;
        padding: 40px 20px 20px 20px;
    }

    .login .form .title {
        font-size: 24px;
    }

    .login .navigation .content .anchor{
        padding: 5px 10px;
        font-size: 14px;
        line-height: 1.5em;
    }

}
.groupInput {
    position: relative;
}

.groupInput .input { 
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
}

.groupInput .label {
    position: absolute;
    z-index: 400;
    color: #b9babb;
    font-size: 20px;
    -webkit-transform: scale(1) translate(30px, 10px);
            transform: scale(1) translate(30px, 10px);
}

.groupInput .label.yesFocus {
    /* color: green; */
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
    -webkit-transform: scale(0.7) translate(-8px, -25px);
            transform: scale(0.7) translate(-8px, -25px);
}

.groupInput .label.noFocus {
    /* color: red; */
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
    -webkit-transform: scale(1) translate(30px, 10px);
            transform: scale(1) translate(30px, 10px);
}

.groupInput .input i.icon {
    left: 0;
    position: absolute;
    color: #b9babb;
    font-size: 20px;
}

.groupInput .input i.icon.yesFocus {
    /* color: green; */
    margin-left: -30px;
    transition: margin-left 0.3s;
}

.groupInput .input i.icon.noFocus {
    margin-left: 0px;
    transition: margin-left 0.3s;
    /* color: red; */
}

.groupInput .input .field{ 
    margin: 0;
    height: 51px;
    border: none;
    font-size: 20px;
    border: 0;
    color: var(--primary-color);
    outline: none;
    border-radius: 0;
    flex: 1 1;
    transition: padding 0.2s, background-position 0.2s ease;
    border-bottom: 2px solid #cccbdd;
    font-family: 'Source Sans Pro', sans-serif;
    padding-left: 10px;
    z-index: 500;
    background-color: transparent;
}

.groupInput .input .field.noFocus {
    /* border: 2px solid red; */
}

.groupInput .input .field.yesFocus {
    /* border: 2px solid green; */
    border-bottom: 2px solid #08a742;
}
