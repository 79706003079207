.whatsApp {
    background-color: rgb(240, 242, 245);
    height: 100vh;
    width: 100%;
    display: inline-flex;
    overflow: hidden;
}

.whatsApp .developmentWhatsApp {
    position: absolute;
    top: 4px;
    left: 60px;
    padding: 10px;
    font-weight: 400;
    background-color: var(--dark-dark);
    min-width: 400px;
    min-height: 100px;
    border-radius: 5px;
    z-index: 999;
    color: var(--white-dark);
}

.whatsApp .errorWhatsApp {
    background-color: var(--color-error);
    color: var(--white-light);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
    height: max-content;
    margin-left: auto;
    margin-right: auto;
}

.whatsApp .buttonDefaultWhatsApp {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.whatsApp .noSelectionWhatsApp {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.select.selectDepartamentsWhatsApp .optionsSelect { /* WARNING: Don't have reference with .whatsApp, because is modal*/
    position: relative;
}

.whatsApp .chatListWhatsApp {
    height: 100%;
    width: var(--whatsapp-width-chatlist);
    background-color: rgb(240, 242, 245);
    border-right: 1px solid #e9edef;
    background-color: #fff;
    position: relative;
    display: initial;
    /* REMOVE */
    /* display: none; */
}

.whatsApp .chatListWhatsApp .dropChatListWhatsApp {
    left: 240px;
    top: 0;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp {
    height: var(--whatsapp-height-chatlist-header);
    padding: 10px 16px;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .buttonAvatarChatListHeaderWhatsApp {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    border: none;
    margin-right: auto;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .avatarChatListHeaderWhatsApp {
    height: 100%;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .avatarDefaultChatListHeaderWhatsApp {
    width: 40px;
    height: 40px;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .anothersButtonsChatListHeaderWhatsApp {
    display: flex;
    flex-direction: row;
    position: relative;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .anothersButtonsChatListHeaderWhatsApp .buttonOthersChatListHeaderWhatsApp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    padding: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: background-color 0.28s;
    position: relative;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .anothersButtonsChatListHeaderWhatsApp .buttonOthersChatListHeaderWhatsApp.active {
    background-color: #d9dbdf;
    transition: background-color 0s;
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .anothersButtonsChatListHeaderWhatsApp .buttonOthersChatListHeaderWhatsApp .iconOthersChatListHeaderWhatsApp {
    fill: rgb(84, 101, 111);
}

.whatsApp .chatListWhatsApp .chatListHeaderWhatsApp .dropChatListHeaderWhatsApp {
    top: 45px;
    right: 4px;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp {
    background-color: #fff;
    position: relative;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 49px;
    transition: box-shadow .18s ease-out,background-color .25s ease-out;
    padding: 0 0 0 12px;
    border-bottom: 1px solid #e9edef;
    box-shadow: 0 2px 3px rgba(11,20,26,0.08);
    /* border-bottom: 1px solid #e9edef; */    
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .helpFilterSearchingWhatsApp {
    position: absolute;
    top: -42px;
    left: 80px;
    background-color: rgba(0, 0, 0, 0.791);
    padding: 4px;
    color: white;
    z-index: 100;
    font-size: 14px;
    font-weight: 400;
    opacity: 0;
    transition: opacity 0.5s;
    border-radius: 4px;
    cursor: pointer;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .helpFilterSearchingWhatsApp::before {
    display: block;
    content: " ";
    position: absolute;
    bottom: -10px;
    left: 4px;
    border-left: 0;
    width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 10px solid rgba(0, 0, 0, 0.791);
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .helpFilterSearchingWhatsApp.active {
    opacity: 1;
    transition: opacity 0.5s;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .helpFilterSearchingWhatsApp.inactive {
    opacity: 0;
    transition: opacity 0.5s;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp::after {
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    position: absolute;
    padding: 4px;
    background-color: #f0f2f5;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp .iconSearchWhatsApp.active {
    transform: rotate(0deg);
    transition: transform 0.28s;
    font-size: 12px;
    opacity: 1;
    left: 18px;
    position: absolute;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp .iconSearchWhatsApp.inactive {
    transform: rotate(90deg);
    opacity: 0;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp .iconLeftWhatsApp.active {
    transform: rotate(90deg);
    transition: transform 0.28s;
    color: #00a884;
    font-size: 16px;
    opacity: 1;
    left: 18px;
    position: absolute;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp .iconLeftWhatsApp.inactive {
    opacity: 0;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp.buttonClearChatListSearchWhatsApp {
    right: 61px;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonChatListSearchWhatsApp .iconButtonChatListSearchWhatsApp {
    color: #54656f;
    font-size: 8px;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonClearChatListSearchWhatsApp.inactive .iconButtonClearChatListSearchWhatsApp {
    opacity: 0;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonClearChatListSearchWhatsApp.active .iconButtonClearChatListSearchWhatsApp {
    transform: scale(2, 2);
    transition: transform 0.28s;
    opacity: 1;
    color: #8696a0;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonFilterChatListSearchWhatsApp.active {
    background-color: #00a884;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonFilterChatListSearchWhatsApp {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: relative;
    background-color: transparent;
    margin: 0;
    margin-left: 8px;
    margin-right: 8px;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonFilterChatListSearchWhatsApp .iconButtonFilterChatListSearchWhatsApp {
    fill: #8696a0;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .buttonFilterChatListSearchWhatsApp.active .iconButtonFilterChatListSearchWhatsApp {
    fill: #fff;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .chatListSearchInputWhatsApp {
    padding-right: 32px;
    padding-left: 65px;
    border-radius: 8px;
    background-color: #f0f2f5;
    box-shadow: none;
    outline: none;
    border: none;
    height: 35px;
    width: 100%;
    color: #3b4a54;
    font-size: 15px;
}

.whatsApp .chatListWhatsApp .chatListSearchWhatsApp .chatListSearchInputWhatsApp::placeholder {
    color: #667781; 
    font-weight: 400;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp {
    background-color: #fff;
    overflow: scroll;
    padding-right: 2px;
    height: calc(100% - var(--whatsapp-height-chatlist-header) - 49px); /* 59px by header component plus 49px by search component*/
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp.list {
    margin-right: 4px;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp.center {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .buttonChatListItemArchivedWhatsApp {
    height: 49px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .buttonChatListItemArchivedWhatsApp .areaIconChatListItemArchivedWhatsApp {
    height: 49px;
    width: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin: 0 15px 0 13px;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .buttonChatListItemArchivedWhatsApp .areaIconChatListItemArchivedWhatsApp .iconChatListItemArchivedWhatsApp {
    height: 18px;
    width: 18px;
    fill: #00a884;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .buttonChatListItemArchivedWhatsApp .areaIconChatListItemArchivedWhatsApp .nameChatListItemArchivedWhatsApp {
    
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .nothingChatsFilterWhatsApp {
    display: flex;
    flex-direction: column;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .nothingChatsFilterWhatsApp .messageNothingChatsFilterWhatsApp {
    margin-bottom: 18px;
    color: #8696a0;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.whatsApp .chatListWhatsApp .chatListItemsWhatsApp .nothingChatsFilterWhatsApp .clearNothingChatsFilterWhatsApp {
    color: #008069;
    line-height: 20px;
    font-size: 0.875rem;
    font-weight: 400;
}

.whatsApp .chatListWhatsApp .chatListLoadingWhatsApp {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.whatsApp .conversationPanelWrapperWhatsApp {
    width: calc(100% - var(--whatsapp-width-chatlist)); /* width of the chatList*/
    height: 100vh;
    overflow: hidden;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp {
    height: var(--whatsapp-height-chatlist-header);
    padding: 10px 16px;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    border-bottom: 1px solid #e9edef;
    cursor: pointer;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonAvatarConversationHeaderWhatsApp {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonAvatarConversationHeaderWhatsApp .avatarConversationHeaderWhatsApp {
    height: 40px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonAvatarConversationHeaderWhatsApp .avatarDefaultConversationHeaderWhatsApp {
    width: 40px;
    height: 40px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonOthersConversationHeaderWhatsApp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    padding: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: background-color 0.28s;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonOthersConversationHeaderWhatsApp:active {
    background-color: #d9dbdf;
    transition: background-color 0s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .buttonOthersConversationHeaderWhatsApp .iconOthersConversationHeaderWhatsApp {
    fill: rgb(84, 101, 111);
    color: rgb(84, 101, 111);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .conversationPanelContactInformationHeaderWhatsApp {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .conversationPanelContactInformationHeaderWhatsApp .nameContactHeaderWhatsApp {
    color: #111b21;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 21px;
    cursor: pointer;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .conversationPanelContactInformationHeaderWhatsApp .timeContactHeaderWhatsApp {
    color: #667781;
    font-size: 13px;
    cursor: pointer;
    font-weight: 400;
    line-height: 20.0005px;
    display: none;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelHeaderWhatsApp .conversationPanelContactInformationHeaderWhatsApp .timeContactHeaderWhatsApp.active {
    display: block;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp {
    height: calc(100% - var(--whatsapp-height-chatlist-header) - 64px);
    background-color: #efeae2;
    overflow: scroll;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .groupMessagesWhatsApp {
    /* nothing */
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .groupMessagesWhatsApp .areaDataWhatsApp {
    text-align: center;
    text-shadow: 0 1px 0 rgba(255, 255, 255,.4);
    margin: 5px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    user-select: none;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .groupMessagesWhatsApp .areaDataWhatsApp .dataMessageWhatsApp {
    padding: 5px 12px 6px;
    border-radius: 7.5px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    background-color: rgba(255,255,255, 0.95);
    min-width: 56px;
    font-size: 12.5px;
    line-height: 21px;
    font-weight: 400;
    color: #54656f;
    text-transform: uppercase;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .conversationInnerPanelMessagesWhatsApp {
    height: 100%;
    width: 100%;
    opacity: 0.4;
    background-image: url("../../../src/assets/images/whatsapp.png");
    background-repeat: repeat;
    z-index: 100;

}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .conversationMessagesPanelMessagesWhatsApp {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 1;
    z-index: 200;
    background-color: transparent!important;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    overflow: scroll;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .conversationMessagesPanelMessagesWhatsApp .dropConversationActionsWhatsApp {
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .conversationMessagesPanelMessagesWhatsApp .buttonLoadOldConversationPanelMessagesWhatsApp {
    padding: 5px 12px 6px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255, 255, 255,.4);
    border-radius: 7.5px;
    box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
    background-color: rgba(255,255,255, 0.95);
    font-size: 12.5px;
    line-height: 21px;
    font-weight: 400;
    color: #54656f;
    display: flex;
    margin: 5px auto;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .buttonScrollToBottomConversationPanelMessagesWhatsApp {
    width: 42px;
    height: 42px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(11,20,26,.06),0 2px 5px 0 rgba(11,20,26,.2);
    border-radius: 50%;
    position: absolute;
    bottom: 90px;
    right: 15px;
    z-index: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transform: scaleX(1) scaleY(1);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .buttonScrollToBottomConversationPanelMessagesWhatsApp.active {
    transform: scaleX(1) scaleY(1);
    transition: transform .2s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .buttonScrollToBottomConversationPanelMessagesWhatsApp.inactive {
    transform: scaleX(0) scaleY(0);
    transition: transform .2s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelMessagesWhatsApp .buttonScrollToBottomConversationPanelMessagesWhatsApp .iconButtonScrollToBottomConversationPanelMessagesWhatsApp {
    fill:  #888d90;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp {
    min-height: 63px;
    background-color: #f0f2f5;
    padding: 5px 16px;
    border-left: 1px solid #e9edef;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    vertical-align: middle;
    background-color: #f0f2f5;
    z-index: 300;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .inputConversationSendWhatsApp {
    box-shadow: none;
    outline: none;
    width: 100%;
    margin: 5px 8px;
    padding: 9px 12px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #fff;
    color: rgb(59, 74, 84);
    font-size: 15px;
    font-weight: 400;
    line-height: 22.05px;
    height: 42px;
    resize: none;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .inputConversationSendWhatsApp.inactive {
    opacity: 0;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .inputConversationSendWhatsApp.active {
    opacity: 1;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .inputConversationSendWhatsApp::placeholder {
    color: rgb(59, 74, 84);
    font-size: 15px;
    font-weight: 400;
    line-height: 22.05px;
    padding-left: 6px;
    padding-right: 6px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationSendWhatsApp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: background-color 0.28s;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationWithoutEffectSendWhatsApp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: background-color 0.28s;
    position: relative;
    transform: scale(0.5);
    position: absolute;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationWithoutEffectSendWhatsApp.active {
    transform: scale(1);
    transition: transform 0.3s;
    position: relative;
    opacity: 1;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationWithoutEffectSendWhatsApp.inactive {
    position: absolute;
    transform: scale(0.5);
    opacity: 0;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationSendWhatsApp:active {
    background-color: #d9dbdf;
    transition: background-color 0s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationSendWhatsApp .iconOthersConversationSendWhatsApp, 
.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationWithoutEffectSendWhatsApp .iconOthersConversationSendWhatsApp {
    fill: rgb(84, 101, 111);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationWithoutEffectSendWhatsApp .iconOthersConversationSendWhatsApp.activeRecording {
    fill: #ff3b30;
    color: #ff3b30;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .buttonOthersConversationSendWhatsApp .iconOthersConversationSendWhatsApp.delete {
    width: 16px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .audioRecordingConversationSendWhatsApp.inactive {
    opacity: 0;
    display: none;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaConversationSendWhatsApp .audioRecordingConversationSendWhatsApp.active {
    opacity: 1;
    display: flex;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp {
    width: 100%;
    position: absolute;
    /* bottom: 63px; default is bottom: 63px */
    min-height: 64px; /*defualt is min-height: 63px*/
    left: 0;
    padding: 5px 16px 0px 16px;
    z-index: 300;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    background-color: #f0f2f5;
    z-index: 200;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp.active {
    bottom: 63px; /*default is 63px*/
    transition: bottom .28s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp.inactive {
    bottom: -100px; /*default is -100px*/
    transition: bottom .28s;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .innerMessageAnswerConversationSendMessageWhatsApp {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 100%;
    margin-left: 48px;
    margin-right: 8px;
    position: relative;
    border-radius: 7.5px;
    overflow: hidden;
    padding: 7px 12px 10px 11px;
    background-color: rgba(11,20,26,0.05);
    max-height: 70px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .innerMessageAnswerConversationSendMessageWhatsApp::before {
    width: 4px;
    left: 0px;
    top: -15px;
    height: 90px;
    background-color: #06cf9c!important;
    display: block;
    content: " ";
    position: absolute;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .nameAnswerConversationSendMessageWhatsApp {
    color: #06cf9c!important;
    font-size: 12.8px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    line-height: 22px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .messageAnswerConversationSendMessageWhatsApp {
    color: #667781;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size: 12.6px;
    font-weight: 400;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .messageAnswerConversationSendMessageWhatsApp .iconTypeAnswerConversationSendMessageWhatsApp {
    color: #8696a0;
    fill: #8696a0;
    margin-right: 3px;
    margin-top: -2px;
}


.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .buttonCloseAreaAnswerConversationSendMessageWhatsApp {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: background-color 0.28s;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelSendMessageWhatsApp .areaAnswerConversationSendMessageWhatsApp .buttonCloseAreaAnswerConversationSendMessageWhatsApp .iconCloseAreaAnswerConversationSendMessageWhatsApp {
    fill: rgb(84, 101, 111);
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp {
    width: 0;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp {
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .headerMoreDetailsProfileGeneralWhatsApp {
    height: 59px;
    width: 100%;
    background-color: #f0f2f5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    padding: 0px 20px 0px 25px;
    border-left: 1px solid #e9edef;
    position: relative;
    z-index: 200;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .headerMoreDetailsProfileGeneralWhatsApp .buttonCloseMoreDetailsProfileGeneralWhatsApp {
    margin-right: 30px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .headerMoreDetailsProfileGeneralWhatsApp .buttonCloseMoreDetailsProfileGeneralWhatsApp .iconButtonCloseMoreDetailsProfileGeneralWhatsApp {
    fill: #54656f;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .headerMoreDetailsProfileGeneralWhatsApp .aboutMoreDetailsProfileGeneralWhatsApp {
    font-size: 16px;
    font-weight: 400;
    color: rgb(17, 27, 33);
    user-select: none;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp {
    transform: translateY(-50px); /*default is translateY(-50px)*/
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp.active {
    transform: translateY(0px);
    transition: transform .5s;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp.inactive {
    transform: translateY(-50px);
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 17px 30px 10px 30px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    box-shadow: 0 1px 3px rgba(11,20,26,0.08);
    position: relative;
    z-index: 100;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp.avatar {
    padding-top: 28px;
    padding-bottom: 19px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .buttonAvatarMoreDetailsProfileGeneralWhatsApp {
    height: 200px;
    width: 200px;
    overflow: hidden;
    border-radius: 50%;
    border: none;  
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .buttonAvatarMoreDetailsProfileGeneralWhatsApp.active {
    opacity: 1;
    transition: opacity 2s;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .buttonAvatarMoreDetailsProfileGeneralWhatsApp.inactive {
    opacity: 0;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .buttonAvatarMoreDetailsProfileGeneralWhatsApp .avatarMoreDetailsProfileGeneralWhatsApp {
    height: 100%;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .nameMoreDetailsProfileGeneralWhatsApp {
    color: rgb(59, 74, 84);
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 15px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .cellphoneMoreDetailsProfileGeneralWhatsApp {
    margin-top: 4px;
    font-weight: 400;
    line-height: 24px;
    color: rgb(59, 74, 84);
    margin-bottom: 12px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .buttonFilesWhatsApp .titleButtonDetailsAndSelectPanelWhatsApp {
    color: #667781;
    font-size: 15px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .infosCalledWhatsApp.title {
    color: rgb(17, 27, 33);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .infosCalledWhatsApp.subtitle {
    color: #667781;
    font-size: 15px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .bodyMoreDetailsProfileGeneralWhatsApp .boxMoreDetailsProfileGeneralWhatsApp .infosCalledWhatsApp.subtitle .iconInfosCalledWhatsApp {
    color: #667781;
    font-size: 15px;
    margin-right: 4px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 200;
    transition: width .1s;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp.active {
    background-color: #f0f2f5;
    width: 100%;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp.inactive {
    background-color: #f0f2f5;
    width: 0;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp .headerMoreDetailsProfileFilesWhatsApp {
    height: 59px;
    width: 100%;
    background-color: #008069;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    padding: 0px 20px 0px 25px;
    border-left: 1px solid #e9edef;
    position: relative;
    z-index: 200;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp .headerMoreDetailsProfileFilesWhatsApp .buttonCloseMoreDetailsProfileFilesWhatsApp {
    margin-right: 30px;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp .headerMoreDetailsProfileFilesWhatsApp .buttonCloseMoreDetailsProfileFilesWhatsApp .iconButtonCloseMoreDetailsProfileFilesWhatsApp {
    fill: #fff;
}

.whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileFilesWhatsApp .bodyMoreDetailsProfileProfileWhatsApp {
    height: 100%;
    padding: 20px;
    color: #aeaeae;
    font-size: 12px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp {
    height: calc(100% - var(--whatsapp-height-chatlist-header)); /* from height conversationPanelHeaderWhatsApp*/
    width: calc(100% - var(--whatsapp-width-chatlist) - 56px); /* width of the chatList*/
    background-color: rgb(240, 242, 245);
    position: absolute;
    top: var(--whatsapp-height-chatlist-header); /*from height conversationPanelHeaderWhatsApp*/
    z-index: 300;
    display: flex;
    flex-direction: column;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaHeaderWhatsApp {
    min-height: 60px;
    width: 100%;
    background-color: #e9edef;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaHeaderWhatsApp .nameConversationPanelUplodaHeaderWhatsApp{
    font-size: 0.875rem;
    color: #111b21;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaHeaderWhatsApp .buttonCloseConversationPanelUplodaHeaderWhatsApp {
    position: absolute;
    left: 16px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaHeaderWhatsApp .buttonCloseConversationPanelUplodaHeaderWhatsApp .iconButtonCloseConversationPanelUplodaHeaderWhatsApp {
    fill: rgb(102, 119, 129);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .iconConversationPanelUplodaBodyWhatsApp {
    margin-bottom: 20px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .titleConversationPanelUploadBodyWhatsApp {
    color: #aebac1;
    margin: 10px 0px;
    font-size: 24px;
    padding: 0;
    font-weight: 400;
    line-height: 24px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .imageConversationPanelUploadBodyWhatsApp {
    max-height: 220px;
    border-radius: 2px;
    box-shadow: 0px 0px 6px #a3a3a3;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .detailsConversationPanelUploadBodyWhatsApp {
    color: rgb(174, 186, 193);
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin: 0;
    padding: 0;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp {
    position: relative;
    width: calc(100% - 160px);
    margin-top: 50px;
    border-radius: 8px;
    overflow: hidden;
    height: 45px;
    padding: 4px 16px;
    padding-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: #fff;
    position: absolute;
    bottom: 12px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp .buttonClearConversationPanelUploadBodyWhatsApp {
    position: absolute;
    right: 16px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp .buttonClearConversationPanelUploadBodyWhatsApp .iconButtonClearConversationPanelUploadBodyWhatsApp {
    fill: rgb(84, 101, 111);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp .inputMessageConversationPanelUploadBodyWhatsApp {
    width: 100%;
    height: 100%;
    box-shadow: none;
    outline: none;
    border: none;
    background-color: #fff;
    color: rgb(59, 74, 84);
    font-size: 17px;
    line-height: 25px;
    font-weight: 400;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaBodyWhatsApp .areaInputMessageConversationPanelUploadBodyWhatsApp .inputMessageConversationPanelUploadBodyWhatsApp::placeholder {
    color: red;
    padding-left: 6px;
    line-height: 25px;
    color: rgb(102, 119, 129);
    font-size: 17px;
    font-weight: 400;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaFooterWhatsApp {
    border-top: 1px solid #d9d9d9;
    height: 99px;
    background-color: rgb(240, 242, 245);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    vertical-align: middle;
    margin: 0px 16px;
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaFooterWhatsApp .buttonSendMessageConversationPanelUploadFooterWhatsApp {
    height: 60px;
    width: 60px;
    background-color: #00a884;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    box-shadow: 0 1px 3px rgba(11,20,26,.4);
}

.whatsApp .conversationPanelWrapperWhatsApp .conversationPanelUplodaWhatsApp .conversationPanelUplodaFooterWhatsApp .buttonSendMessageConversationPanelUploadFooterWhatsApp .iconButtonSendMessageConversationPanelUploadFooterWhatsApp {
    fill: #fff;
    font-size: 14px;
    font-weight: 500;
}

.loadingWhatsApp {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3.8px solid #00a884;
    animation: spinner-bulqg1 0.96s infinite linear alternate, spinner-oaa3wk 1.92s infinite linear;
    position: absolute;
    bottom: 0;
}

@keyframes spinner-bulqg1 {
   0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }

   12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }

   25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }

   50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }

   100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
}

@keyframes spinner-oaa3wk {
   0% {
      transform: scaleY(1) rotate(0deg);
   }

   49.99% {
      transform: scaleY(1) rotate(135deg);
   }

   50% {
      transform: scaleY(-1) rotate(0deg);
   }

   100% {
      transform: scaleY(-1) rotate(-135deg);
   }
}

@media (max-width: 1023px)
{
    .whatsApp .chatListWhatsApp,
    .whatsApp .conversationPanelWrapperWhatsApp {
        display: none;
    }

    .whatsApp .messageAudioWhatsApp {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media (max-width: 1439px)
{
    .whatsApp .conversationPanelMoreDetailsProfileWhatsApp .moreDetailsProfileGeneralWhatsApp .headerMoreDetailsProfileGeneralWhatsApp {
        border: none;
    }
}