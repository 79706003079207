.activityForm { 
    padding: 0;
}
.activityForm .button { 
    border-radius: 2px;
}

.activityForm .activityFormLoading {
    transition: all ease-in .5s;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.activityForm .bodyActivityForm { 
    padding: 16px 36px;
}

.activityForm .bodyActivityForm .labelSuccessServiceActivityForm {
    color: var(--color-success);
    font-weight: 400;
    display: inline-block;
    width: 100%;
    font-size: 20px;
}

.activityForm .bodyActivityForm .mandatoryInformationActivityForm {
    font-size: 12px;
    color: #747678;
}

.activityForm .bodyActivityForm .labelErrorServiceActivityForm {
    color: var(--color-error);
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    display: inline-block;
}

.activityForm .bodyActivityForm .labeDetailsServiceActivityForm {
    color: var(--color-warning);
    font-weight: 400;
    font-size: 14px;
    
}

.activityForm .bodyActivityForm .valueAuthorizationSuccessAcitivityForm input {
    color: #000;
}

.activityForm .bodyActivityForm .valueAuthorizationWarningAcitivityForm input {
    background-color: #ffc1075e;
}

.activityForm .bodyActivityForm .valueAuthorizationErrorAcitivityForm input {
    background-color: #dc35465b;
}

.activityForm .bodyActivityForm .subtitleGeneralActivityForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.activityForm .bodyActivityForm .subtitleSuccessActivityForm,
.activityForm .bodyActivityForm .subtitleWarningActivityForm,
.activityForm .bodyActivityForm .subtitleErrorActivityForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    width: 100%;
}

.activityForm .bodyActivityForm .subtitleSuccessActivityForm:before,
.activityForm .bodyActivityForm .subtitleWarningActivityForm:before,
.activityForm .bodyActivityForm .subtitleErrorActivityForm:before {
    display: block;
    content: " ";
    width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 1px;
    margin-right: 5px;
}

.activityForm .bodyActivityForm .subtitleSuccessActivityForm:before {
    background-color: #28a74678;
}

.activityForm .bodyActivityForm .subtitleWarningActivityForm:before {
    background-color: #ffc1075e;
}

.activityForm .bodyActivityForm .subtitleErrorActivityForm:before {
    background-color: #dc35465b;
}

.activityForm .bodyActivityForm .lineActivityForm {
    margin-top: 10px;
    margin-bottom: 10px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .table td{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: fit-content;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .containerServiceInfoActivityForm .labelServiceInfoActivityForm {
    color: #747678;
    font: 400 15px / 20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .containerServiceInfoActivityForm .serviceInfoActivityForm {
    color: var(--primary-color);
    padding-left: 6px;
    font-size: 16px;
    height: 32px;
    display: flex;
    border-radius: 2px;
    border: 1px solid #cbcccd;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .containerServiceInfoActivityForm .ownersInfoActivityForm {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonAnalysisActivityForm {
    height: 32px;
    font-weight: 400;
    font-size: 14px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonUrlLinkActivityForm {
    background: var(--color-focus);
    color: var(--white-light);
    padding: 5px;
    width: 35px;
    height: 30px;
    border: 0;
    display: flex;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonUrlLinkWarningActivityForm {
    background: var(--color-warning);
    color: var(--white-light);
    padding: 5px;
    width: 35px;
    height: 30px;
    border: 0;
    display: flex;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .nameActivityForm input {
    height: 40px!important;
    margin: 0;
    font-size: 22px!important;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm {
    border: none;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button {
    height: 32px;
    font-size: 14px;
    border-left: 1px solid var(--white-dark-two);
    border-top: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    color: var(--primary-color);    
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button:last-of-type {
    border-right: 1px solid var(--white-dark-two);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button.active {
    border: 1px solid var(--color-focus);
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm {
    position: relative;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .dropdownTimeActivityForm {
    position: absolute;
    top: 0;
    left: 0;
    top: inherit;
    flex-direction: column;
    width: 100%;
    z-index: 900;
    max-height: 200px;
    overflow: scroll;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .timeActivityForm {
    padding: 5px 15px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonClearTimeActivityForm {
    position: absolute;
    top: 0;
    width: 32px;
    height: 32px;
    right: 10px;
    background-color: transparent;
    color: var(--primary-color);
    font-size: 10px;

}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .timeActivityForm:hover,
.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .timeActivityForm.active {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .annotationsActivityForm textarea{
    height: 150px!important;
    background: rgb(255, 252, 220)!important;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonFileActivityForm {
    background-color: transparent;
    color: var(--dark-dark-three);    
    margin-right: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm.file {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonFileActivityForm::after {
    content: "Arquivo";
    display: block;
    position: absolute;
    right: -70px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonFileActivityForm:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-light);
    border-radius: 50%;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .nameFileActivityForm {
    color: var(--dark-dark-three);    
    font-weight: 300;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonDeleteDiscountActivityForm {
    background-color: var(--color-error);
    /* width: 30px; */
    height: 32px;
    font-weight: 400;
    font-size: 14px;
}

.activityForm .bodyActivityForm .lineActivityForm .installmentDetailsActivityForm {
    border-radius: 5px;
    font-size: 24px;
}

.activityForm .bodyActivityForm .lineActivityForm .installmentDetailsActivityForm .activityFormInstallmentInputActivityForm input {
    text-align: right;
    padding-right: 10px;
    height: 50px;
    width: 150px;
}

.activityForm .bodyActivityForm .lineActivityForm .activityFormSelect .fieldSelect,
.activityForm .bodyActivityForm .lineActivityForm .valueAuthorizationSuccessAcitivityForm .fieldInputGeneral {
    height: 50px;
    font-size: 20px;
}

.activityForm .bodyActivityForm .lineActivityForm .investimentActivityForm .labelInputGeneral {
    font-size: 17px;
    font-weight: 600;
}

.activityForm .bodyActivityForm .lineActivityForm .activityFormTextResultAnalysis {
    font-size: 24px;
}

.activityForm .footerActivityForm .lineActivityForm {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 36px;
    padding-right: 36px;
    background: rgb(247, 247, 247);
    border-top: 1px solid rgb(203, 204, 205);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.activityForm .footerActivityForm .lineActivityForm .buttonEditServiceActivityForm {
    background: var(--color-warning);
    width: fit-content;
    height: 32px; 
    font-weight: 400;
    font-size: 14px;
}

.activityForm .footerActivityForm .lineActivityForm .buttonAddServiceActivityForm {
    background: var(--color-warning);
    height: 50px;
    font-weight: 400; 
    font-size: 18px;
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm {
    width: initial;
    margin-right: 15px;
    border-radius: 2px!important;
    color: var(--primary-color);
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm .squareCheck {
    border-radius: 4px;
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm.active .squareCheck {
    background-color: var(--color-focus);
    border-color: var(--color-focus);
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm:hover .squareCheck {
    border-color: var(--color-focus);
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.activityForm .lineActivityForm .buttonShortcutActivityForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 2px 12px;
    width: auto;
    font-weight: 600;
    font-size: 14px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    display: inline-block;
    text-align: left;
}

.activityForm .lineActivityForm .buttonShortcutActivityForm i {
    font-size: 16px;
    margin-right: 10px;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.activityForm .bodyActivityForm .lineActivityForm .buttonActivityForm {
    background-color: var(--color-focus);
}

.activityForm .bodyActivityForm .lineActivityForm .buttonIconSpacingActivityForm {
    margin-right: .5rem !important;
}
.activityForm .bodyActivityForm .lineActivityForm .buttonRefreshAnalysisActivityForm {
    height: 32px;
    width: 20px;
    font-weight: 400;
    font-size: 14px;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
    margin-left: 10px;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}